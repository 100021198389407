.footer.content {
    background-color: $c-darkblue-bg;
    border-top: none;
    margin-top: 0;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;

    .footer {

        &__contact {
            background-color: $c-white;

            &__container {
                display: flex;
                flex-wrap: wrap;
                max-width: 1130px;
                padding-left: 15px;
                padding-right: 15px;
                margin: 0 auto;
                justify-content: space-between;
                padding-top: 60px;
                padding-bottom: 60px;
            }

            &__column {
                width: calc(50% - 30px);
                display: flex;
                padding: 42px 52px;
                border: 1px solid $c_text-lighter;
                border-radius: 4px;
                justify-content: space-between;
                align-items: center;
                background-color: $c_white;

                .title {
                    text-transform: uppercase;
                    margin-bottom: 14px;
                    font-size: 18px;
                    font-weight: 700;
                }

                .subtitle {
                    color: $c_grey;
                    font-size: 16px;
                    margin-bottom: 0;
                }

                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin-bottom: 20px;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;

                    .subtitle {
                        margin-bottom: 16px;
                    }
                }
            }
        }

        &__main {
            display: flex;
            padding-top: 43px;
            padding-bottom: 56px;
            font-size: 16px;
            flex-wrap: wrap;
            justify-content: space-between;

            &__container {
                position: relative;
                max-width: 1130px;
                padding-left: 15px;
                padding-right: 15px;
                margin: 0 auto;
            }

            .block.newsletter {
                float: none;
                color: $c-white;
                width: 30%;
                order: 3;

                .title {
                    display: block;
                    font-size: 18px;
                    line-height: 1;
                    margin-bottom: 26px;

                    @media screen and (max-width: 640px) {
                        font-size: 16px;
                        margin-bottom: 15px;
                    }
                }

                p:not(.title) {
                    font-size: 14px;
                    line-height: 1.38;
                    margin-bottom: 30px;
                }

                .form.subscribe {
                    display: flex;
                    flex-direction: row-reverse;
                }

                .actions {
                    width: 30%;
                    margin-left: 20px;

                    .action.primary {
                        background-color: $c_primary;
                        font-size: 13px;
                        border-radius: 4px;
                        border: 1px solid $c_primary;
                        margin-left: 0;
                        height: 50px;

                        &:hover {
                            background-color: $c_primary-darker;
                            border: 1px solid $c_primary-darker;
                        }
                    }
                }

                .field.newsletter {
                    width: 100%;

                    .control {
                        flex-grow: 1;

                        input {
                            background-color: transparent;
                            border: 1px solid rgba(140, 150, 169, .6);
                            border-radius: 4px;
                            color: $c-white;
                            height: 50px;
                            font-size: 14px;
                            padding-left: 22px;
                        }

                        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            font-size: 14px;
                            color: rgba(140, 150, 169, 0.6);
                        }
                        ::-moz-placeholder { /* Firefox 19+ */
                            font-size: 14px;
                            color: rgba(140, 150, 169, 0.6);
                        }
                        :-ms-input-placeholder { /* IE 10+ */
                            font-size: 14px;
                            color: rgba(140, 150, 169, 0.6);
                        }
                        :-moz-placeholder { /* Firefox 18- */
                            font-size: 14px;
                            color: rgba(140, 150, 169, 0.6);
                        }

                        &:before {
                            content: none;
                        }
                    }
                }

                @media screen and (max-width: 640px) {
                    width: 100%;
                    margin-top: 15px;
                }
            }

            .footer-top {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 30px;
                margin-bottom: 30px;
                border-bottom: 1px solid rgba(140, 150, 169, .4);

                &__socials {
                    display: flex;
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        margin-bottom: 0;
                        list-style-type: none;
                    }

                    li + li {
                        margin-left: 20px;
                    }
                }

                @media screen and (max-width: 640px) {
                    justify-content: center;
                    display: none;

                    &__socials {

                        @media screen and (max-width: 640px) {
                            display: none;
                        }

                    }
                }
            }

            &__links {
                order: 2;
                display: flex;
                width: 55%;
                justify-content: space-between;

                ul {
                    padding-right: 16px;
                    margin-bottom: 0;
                    padding-left: 0;

                    li {
                        color: $c-white;
                        font-size: 14px;
                        list-style-type: none;
                        margin-bottom: 0;

                        &.section-link {
                            position: relative;
                            text-transform: uppercase;
                            margin-bottom: 24px;

                            &:after {
                                content: '';
                                display: none;
                                position: absolute;
                                right: 0;
                                top: 6px;
                                transform: rotate(0deg) translateY(-50%);
                                background-image: url('../images/icons/open.svg');
                                background-repeat: no-repeat;
                                width: 24px;
                                height: 24px;
                            }
                        }

                        a {
                            display: block;
                            padding-bottom: 8px;
                            color: $c-white;
                            font-size: 14px;

                            &:hover {
                                @media screen and (min-width: $screen--s) {
                                    text-decoration: underline;
                                    text-underline-offset: 0.18rem;
                                }
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 640px) {
                flex-direction: column;
                padding-top: 50px;
                padding-bottom: 50px;

                &__links {
                    width: 100%;
                    flex-direction: column;
                    flex-wrap: wrap;

                    ul {
                        width: 100%;
                        margin-top: 10px;
                        padding-right: 0;
                        padding-left: 0;

                        li {

                            &.section-link {

                                &:after {
                                    display: block;
                                }
                            }

                            &:not(.section-link) {
                                height: 0;
                                overflow: hidden;
                            }
                        }

                        &.opened {
                            margin-bottom: 50px;

                            li {

                                &.section-link {
                                    margin-bottom: 24px;

                                    &:after {
                                        background-image: url('../images/icons/close.svg');
                                        top: 6px;
                                        right: 4px;
                                        width: 16px;
                                        height: 16px;
                                    }
                                }

                                &:not(.section-link) {
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__small-links {

            .footer-top__socials {
                display: none;
                padding-bottom: 60px;
                padding-left: 0;

                li {
                    list-style-type: none;

                    a > svg {
                        @media screen and (max-width: 640px) {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }

                li + li {
                    margin-left: 30px;
                }

                @media screen and (max-width: 640px) {
                    display: flex;
                    justify-content: center;
                }
            }

            &__container {
                position: relative;
                display: flex;
                align-items: center;
                max-width: 1100px;
                margin: 0 auto;
                border-top: 1px solid rgba(140, 150, 169, .4);
                padding-top: 16px;
                padding-bottom: 20px;
                justify-content: space-between;
            }

            .copyright {
                font-size: 14px;
                margin-top: 0;
                margin-right: 14px;
                color: rgba(140, 150, 169, .6);
            }

            &__misc {
                display: flex;

                a {
                    color: rgba(140, 150, 169, .6);
                    display: block;
                    font-size: 14px;

                    &:hover {
                        text-decoration: none;
                    }
                }

                a + a {
                    margin-left: 30px;
                }
            }

            @media screen and (max-width: 768px) {

                &__container {
                    flex-direction: column;
                    align-items: center;
                }

                .copyright {
                    margin-bottom: 12px;
                    font-size: 13px;
                    margin-right: 0;
                }

                &__misc {
                    padding-right: 0;
                    margin-bottom: 28px;

                    a {
                        font-size: 13px;
                    }
                }
            }
        }

        &__recaptcha_policy {
            font-size: 14px;
            color: rgba(140, 150, 169, 0.6);

            p {
                margin: 0;
            }

            @media screen and (max-width: 768px) {
                font-size: 13px;
                margin-bottom: 1rem;
            }
        }
    }
}
.grecaptcha-badge {
    visibility: hidden;
}