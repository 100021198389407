.product-stock-notification {
    margin: 30px 0;

    .product.alert {
        color: $c_black;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
    }

    .action.secondary {
        margin-top: 10px;
        border-radius: 4px;
        background-color: $c_black;
        display: block;
        color: $c_white;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: center;
        padding: 17px;
        box-sizing: border-box;
        transition: background-color 0.24s ease, color 0.24s ease;

        &:hover {
            background-color: #111;
        }

        &.disabled {
            pointer-events: none;
            opacity: .6;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
}
