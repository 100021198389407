/**
 * Copyright © 2015 Magento. All rights reserved.
 * See COPYING.txt for license details.
 */

@import "css/animate";
@import "css/timelify";
@import "css/jquery.simplyscroll";

@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900);

/* VARIABLES */
$main_color: #df5d24;
$line_color: #e8e8e8;
$text_color: #000;
$text_light: #FFF;
$text_night: #202020;
$text_hover: #999;
$text_women: #844a9e;
$text_font: 'Lato', sans-serif;
$background_color_black: #111;

$font_18px: 1.00rem;
$font_20px: 1.12rem;
$font_24px: 1.33rem;
$font_28px: 1.56rem;
$font_32px: 1.77rem;
$font_36px: 2.00rem;
$font_48px: 2.60rem;
$font_64px: 3.43rem;
$font_72px: 4.00rem;

/* MAIN */
html, body, * {
    box-sizing: border-box;
    font-family: $text_font;
}

body {
    font-size: 14px;
    color: $text_color;
    overflow-x: hidden;
    transition: padding-top 0.3s;

    &.sticky-nav {
        padding-top: 60px;
    }
}

h1, h2, h3 {
    text-transform: uppercase;
}

a, a:link, a:active, a:visited {
    color: $main_color;
    text-decoration: none;

    @extend .transition-class;

    &:hover, &:focus {
        color: $text_hover;
        text-decoration: none;
    }
}

*:focus {
    box-shadow: none !important;
    outline: none;
}

.ellip {
    display: block;
    height: 100%;
}

.ellip-line {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
}

.ellip,
.ellip-line {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

.message {
    font-size: 14px !important;
}

.modal-slide._show, .modal-popup._show {
    z-index: 999 !important;
}

input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"], select, textarea {
    border: 2px solid $line_color;
    border-radius: 0;
    color: $text_color;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
}

select {
    padding-right: 32px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: none;
    background: url(../images/sort-down.png) no-repeat $text_light;
    background-position: -webkit-calc(100% - 12px) 50%;
    background-position: calc(100% - 12px) 50%;
    background-size: 12px 12px;
}

.fillwidth {
    width: -webkit-calc(50vw - 50%);
    width: calc(50vw - 50%);
}

.font-size-16px {
    font-size: 16px;
}

.icon-set {
    background-repeat: no-repeat;
    background-size: 576px 576px;
    background-image: url(../images/icon-set.png);
}

.clear-float {
    content: '';
    clear: both;
    display: block;
    width: 100%;
    height: 0;
}

.transition-class {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.main-btn {
    display: inline-block;
    height: auto;
    padding: 12px 36px;
    background: $main_color;
    border: 2px solid $main_color;
    border-radius: 0;
    color: $text_light;
    font-weight: 700;
    text-transform: uppercase;
    @extend .font-size-16px;

    &:hover,
    &:active {
        background-color: $text_light;
        border-color: $text_light;
        color: $main_color;
    }

    &.outline-btn {
        background-color: transparent;
        color: $main_color;

        &:hover,
        &:active {
            background-color: $main_color;
            border-color: $main_color;
            color: $text_light;
        }
    }
}

.block-static-block,
.cms-page-view .column.main,
.contact-index-index .column.main,
.catalog-product-view .column.main {
    margin: 0 !important;
    padding: 0;

    section {
        padding: 60px 20px;

        &.no-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        &.adding-bg {
            background-color: #f8f7f8;
        }

        .row {
            @extend .content-wrapper;
            @extend .font-size-16px;

            &.fullwidth {
                max-width: none;
                margin-left: -20px;
                margin-right: -20px;
            }

            h1, h2 {
                display: block;
                width: 100%;
                margin: 0 0 40px !important;
                font-size: $font_28px;
                font-weight: 400 !important;
                text-align: center;

                strong {
                    font-weight: 900;
                }
            }
        }
    }
}

body[class^="customer-account-"], body.account, body.catalogsearch-advanced-index {
    #maincontent {
        @extend .content-wrapper;
        @extend .font-size-16px;

        .page-title-wrapper, .column main {
            padding: 60px 20px !important;
        }

        .page-title-wrapper {
            h1.page-title {
                display: block;
                width: 100%;
                margin: 0 !important;
                font-size: $font_28px;
                font-weight: 400 !important;
                text-align: center;

                strong {
                    font-weight: 900;
                }
            }
        }

        .columns {
            padding-bottom: 60px;
        }

        .column main {
            padding-top: 0;
        }
    }
}

ul[class$="-list"], ul[class$="-list"] li {
    list-style: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
}

.page-main {
    .column.main {
        padding-bottom: 0 !important;
    }
}

div.mage-error[generated], .aw-gc-product-form-options .mage-error[generated] {
    font-size: 12px;
}

@import "catalog";

/* 404 */
.cms-noroute-index {
    .page-404-content {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 80px;

        h1 {
            color: $main_color;
            font-weight: 900;
        }

        p:first-of-type {
            margin-bottom: 48px;
            font-size: $font_20px;
        }

        img {
            float: right;
            max-width: 240px;
        }
    }
}

/* LOGIN */
.customer-account-login {
    .login-container {
        padding-bottom: 50px;

        .block-title {
            margin: 0 0 40px !important;
        }

        .primary {
            float: none;
        }

        .secondary {
            float:  none;
            vertical-align: middle;
        }

        .fieldset>.field>.control {
            width: 55%;
        }

        .fieldset:after {
            content: none;
        }

        .block-new-customer {
            .block-content {
                text-align: center;
            }
        }

        .note {
            text-align: center;
        }

        div.mage-error[generated] {
            font-size: 14px;
        }

        .invalid-label {
            display: none;
        }

        .field:not(.choice) > .label {
            padding-top: 0;
            line-height: 1;
        }
    }

    .action.primary {
        @extend .main-btn;
    }
}

/* FORGOT PASSWORD */
.customer-account-forgotpassword,
.customer-account-createpassword {
    .form.password.forget {
        padding-bottom: 80px;
    }

    .action.primary {
        @extend .main-btn;
    }
}

.customer-account-createpassword {
    .fieldset {
        span {
            line-height: 1;
            display: inline-block;
            max-width: 130px;
        }

        .label:after {
            vertical-align: top;
        }
    }
}

/* REGISTER */
.customer-account-create {
    .legend {
        margin-bottom: 10px;

        span {
            font-size: $font_18px;
        }
    }

    .form.create.account {
        padding-bottom: 80px;
    }

    div.mage-error[generated] {
        font-size: 14px;
    }

    .field:not(.choice) > .label {
        padding-top: 0;
        line-height: 1;
    }

    .confirmation {
        label:after {
            display: inline-block;
            vertical-align: top;
        }

        span {
            display: inline-block;
            max-width: 120px;
        }
    }

    .page-main {
        .actions-toolbar {
            .action.primary {
                @extend .main-btn;
            }
        }
    }
}

/* ACCOUNT */
body.account, body[class^="customer-account"], body.sendfriend-product-send, body.catalogsearch-advanced-index {
    #maincontent {
        .block-title strong, .block-title > a, form legend span {
            font-size: $font_20px !important;
            text-transform: uppercase;

            span {
                font-weight: 400;
            }
        }

        .column.main {
            .block-title {
                margin-top: 0 !important;
            }

            .block-content {
                .box-title span {
                    font-size: $font_20px;
                    font-weight: 400;
                }
            }

            .actions-toolbar, .actions-primary {
                .action.primary {
                    @extend .main-btn;
                    line-height: 1;
                }
            }

            .secondary a {
                color: $text_color;
                font-weight: 700;

                &:hover {
                    color: $main_color;
                }
            }
        }

        .form, [class^="form"] {
            .field {
                .label {
                    position: relative;
                    padding: 6px 25px 0 0;

                    &:after {
                        position: absolute;
                        top: 0;
                        margin-left: 0;
                        font-weight: 400;
                    }

                    span {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .account-nav {
        .item {
            margin-top: 5px;

            > strong {
                color: $main_color;
            }

            > a {
                color: $text_color;

                &:hover {
                    font-weight: 700;
                }
            }

            > strong, > a {
                padding-top: 7px;
                padding-bottom: 7px;
                text-transform: uppercase;
            }
        }
    }

    .box-actions, .items.addresses .item.actions, .product-item-actions {
        a.action {
            margin-right: 15px;
        }

        a + a:before {
            content: '|';
            display: inline-block;
            margin-right: 15px;
            color: $text_night;
        }
    }

    .customer-review {
        .product-details {
            text-align: center;

            .product-media, .product-info {
                float: none;
                width: 100%;
            }

            .product-media {
                margin: 0 auto;
            }

            .product-info {
                h2.product-name {
                    font-size: $font_20px;
                    font-weight: 400;
                }
            }
        }

        .review-details {
            .review-title {
                font-size: $font_20px;
            }
        }
    }

    .products-grid.wishlist {
        .product-item {
            padding-top: 0;
        }

        .product-item-info {
            .product-item-photo {
                float: none;
                margin-right: 0;
                margin-bottom: 0;

                .product-image-container {
                    max-width: 100%;
                }
            }

            .price-box {
                .price-as-configured {
                    margin-bottom: 0;
                }
            }
        }

        .product-item-details {
            margin-top: 10px;

            .product-item-name {
                margin-left: 0;
            }

            .product-item-actions {
                clear: both;
                padding-top: 7px;

                a.action {
                    float: none;
                    display: block !important;
                    margin-left: 0;
                    margin-right: 0;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        + .actions-toolbar {
            .primary button.action {
                float: left;
                margin-bottom: 10px;
                margin-right: 10px;
                @extend .main-btn;
                line-height: 1;
            }
        }
    }
}


/* COMPARE */
.comparison {
    .table-comparison .cell.attribute {
        font-size: 14px;
    }

    .action.primary {
        @extend .main-btn;
        line-height: 1;
        float: none;
        height: auto;
        padding: 12px 20px;

        &:hover {
            background: transparent;
        }
    }

    .remove.product {
        padding-bottom: 5px;

        .action.delete{
            background-color: $main_color;
            padding: 0;
            border-radius: 50%;

            &:before {
                -webkit-font-smoothing: antialiased;
                font-size: $font_24px;
                line-height: 1;
                color: #fff;
                content: '\e616';
                font-family: 'icons-blank-theme';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }

            &:hover {
                &:before {
                    color: #fff;
                }
            }
        }
    }

    .price-container {
        .price {
            font-size: $font_18px;
        }
    }

    .cell {
        padding: 10px;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.form-newsletter-manage {
    .fieldset .legend {
        margin-left: 0;
    }

    .fieldset > .field.choice:before {
        display: none;
    }
}

.column:not(.sidebar-main) form.form-newsletter-manage .actions-toolbar,
.column:not(.sidebar-additional) form.form-newsletter-manage .actions-toolbar {
    margin-left: 0;
}

.social-btn-actions-toolbar {
    width: 260px;
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    .primary {
        display: block;
    }

    .action {
        display: block;
    }
}

.sitemap-index-index {

    .page-title-wrapper {
        display: none !important;
    }

    .column.main {
        padding: 30px;

        .row {
            margin-left: 0;
            margin-right: 0;

            ul {
                padding-left: 0;
            }
        }
    }
}

/* Hide header in checkout page */

.checkout-index-index .page-header {
    display: none;
}

@import "cms";
@import "blog";
@import "responsive";