.mfp-wrap.mfp-move-from-top .mfp-content {
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
}

.mfp-content #social-login-popup {
    overflow: unset;
    max-width: 480px;

    .mfp-close {
        background: $c_primary;
        font-size: 22px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 100%;
        top: -21px;
        right: -21px;
        opacity: 1;
    }

    .block-container .block {
        padding: 50px;

        @media screen and (max-width: $screen__m) {
            padding: 50px 20px;
        }

        &.social-login-customer-authentication {
            padding: 50px 50px 0 50px;

            @media screen and (max-width: $screen__m) {
                padding: 50px 20px 0 20px;
            }
        }

        &.social-login-authentication-channel {
            padding: 20px 50px 50px 50px;
            text-align: center;

            @media screen and (max-width: $screen__m) {
                padding: 20px 20px 50px 20px;
            }
        }

        > .block-title {
            color: #1C2028;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 24px;
            margin-bottom: 18px;
            padding: 0;
            border: none;

            @media screen and (max-width: $screen__m) {
                text-align: center;
            }

            .logo {
                text-align: center;
                margin: 25px 0 40px 0;
                display: none;

                @media screen and (max-width: $screen__m) {
                    display: block;
                }
            }
        }

        &.social-login-authentication-channel {
            .block-title {
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                margin: 0 auto 18px auto;
                padding: 0;
                border: none;
                position: relative;
                text-align: center;
                color: rgba(140,150,169,0.6);

                &:before {
                    content: "";
                    position: absolute;
                    height: 1px;
                    background: #E9E9EB;
                    width: 100%;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    z-index: -1;
                }

                span {
                    color: #8C96A9;
                    background-color: $c_white;
                    display: inline-block;
                    padding: 0 15px;
                    font-weight: normal;
                }
            }
        }
    }

    .form-customer-login,
    .form-customer-create,
    .form-password-forget {
        > .fieldset {
            &.create.info {
                margin-bottom: 0;
            }

            &.login {
                .actions-toolbar:last-child {
                    .primary,
                    .secondary {
                        display: inline-block;
                        width: 49%;
                    }

                    .primary {
                        text-align: right;
                    }

                    .secondary {
                        text-align: left;
                    }
                }
            }

            input[name="recaptcha-validate-"] {
                visibility: hidden;
            }

            > .field {
                margin-bottom: 18px;

                > .label {
                    display: none;
                }

                &.newsletter.choice {
                    text-align: left;

                    &:before {
                        display: none;
                    }

                    > .label,
                    > .checkbox {
                        display: inline-block;
                        vertical-align: center;
                    }

                    > .label {
                        padding-left: 5px;
                        color: $c_primary;
                    }
                }

                &.password,
                &.confirmation {
                    position: relative;

                    .toggle-password {
                        position: absolute;
                        right: 20px;
                        top: 17px;
                        z-index: 999;
                        width: 25px;
                        height: 18px;

                        &.fa-eye {
                            &:before {
                                content: "";
                                background-image: url('../images/icons/eye.svg');
                                width: 25px;
                                height: 18px;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }

                        &.fa-eye-slash {
                            &:before {
                                content: "";
                                background-image: url('../images/icons/eye.svg');
                                width: 25px;
                                height: 18px;
                                display: inline-block;
                                vertical-align: middle;
                            }

                            &:after {
                                content: '';
                                display: inline-block;
                                width: 22px;
                                height: 2px;
                                transform: rotate(115deg);
                                background: #000;
                                position: absolute;
                                top: 7px;
                                right: 1px;
                                border-top: solid 2px $c_white;
                            }
                        }

                        &:before {
                            font-size: 24px;
                        }
                    }
                }

                > .control {
                    width: 100%;
                }

                > .control > input {
                    box-sizing: border-box;
                    height: 50px;
                    line-height: 50px;
                    border: 1px solid #E9E9EB;
                    border-radius: 4px;
                    color: #8C96A9;
                    font-size: 14px;
                    letter-spacing: 0;
                    padding: 0 15px;
                }

                input::-webkit-input-placeholder {
                    color: #8C96A9;
                }

                ::-moz-placeholder {
                    color: #8C96A9;
                }

                :-ms-input-placeholder {
                    /** notice that ie has only a single colon) */
                    color: #8C96A9;
                }

                ::-webkit-input-placeholder {
                    color: #8C96A9;
                }

                ::placeholder {
                    color: #8C96A9;
                }
            }

            .control {
                .mage-error {
                    padding-left: 15px;

                    .rules-password {
                        list-style-position: inside;
                        margin: 18px 0 0 0;
                        padding: 0;

                        .rule {
                            color: #8C96A9;
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 15px;
                            margin-bottom: 12px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.success {
                                color: #22BA86;
                                list-style: none;

                                &:before {
                                    content: "";
                                    background-image: url('../images/icons/check.svg');
                                    width: 16px;
                                    height: 16px;
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-left: -6px;
                                    margin-right: 7px;
                                }
                            }
                        }
                    }
                }
            }

        }

        .actions-toolbar {
            &:before {
                display: none;
            }

            > .primary {
                width: 100%;
                float: none;

                button {
                    margin: 0;

                    &.action.primary {
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        height: 50px;
                        text-align: center;
                        text-transform: uppercase;
                        border-radius: 4px;
                        color: $c_white;
                        text-decoration: none;

                        &:hover {
                            background-color: #b54a1b !important;
                            color: $c_white;
                        }
                    }
                }

                > .secondary {
                    width: 100%;
                }
            }

            .action.create,
            .action.remind {
                color: #8C96A9;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                text-decoration: underline;
                text-underline-offset: 0.18rem;
                display: inline-block;
            }

            .action.back {
                color: #8C96A9;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                margin-top: 18px;
                display: inline-block;
                text-decoration: underline;
            }
        }
    }

    .form-password-forget {
        > .fieldset > .field {
            &.note {
                color: #8C96A9;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
            }
        }
    }

    .social-login-authentication-channel {
        .actions-toolbar {
            &:not(:last-child) {
                margin-top: 0;
            }

            &.social-btn {
                .btn-social {
                    text-align: center;
                    padding: 0;
                    line-height: 50px;
                    border-radius: 4px;
                    color: #F9F9FB;
                    font-size: 14px;
                    letter-spacing: 0;

                    .fa {
                        display: none;
                    }
                }
            }
        }
    }


}
// MOBILE

@media screen and (max-width: $screen__m) {
    .mfp-wrap {
        overflow: hidden;
        -webkit-transform: translateZ(0);
    }
    .mfp-wrap .mfp-container {
        padding: 0;
        margin: 0;
        width: 100vw;
        height: 100vh;
        position: absolute;

        .mfp-content {
            margin: 0;

            #social-login-popup {
                overflow: auto;
                width: 100vw;
                height: 100vh;

                .mfp-close {
                    background: $c_white;
                    color: #1C2028;
                    font-size: 28px;
                    width: 54px;
                    height: 54px;
                    line-height: 44px;
                    border-radius: 0;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}

// DESKTOP

@media screen and (min-width: $screen__m) {
    .actions-toolbar {
        .secondary {
            float: left;

            .action.remind {
                margin-top: 0;
            }
        }
        .primary {
            float: right;
        }
    }
}

