.modal-slide.minicart-sidebar {
    .modal-inner-wrap {
        overflow-x: hidden;

        .modal-header {
            .modal-title {
                display: none;
            }

            .action-close {
                top: 30px;
                left: 50px;
                width: min-content;
                transform: initial;
                padding: 0;

                @media screen and (max-width: $screen--s) {
                    top: 20px;
                    left: 37px;
                    z-index: 100;
                }

                &:before {
                    background-image: url('../images/icons/arrow-left.svg');
                    height: 24px;
                    width: 13px;
                    content: '';
                }
            }
        }

        .modal-content {
            .block-minicart {
                height: 100%;
                margin-bottom: 0;

                #minicart-content-wrapper {
                    height: 100%;

                    @media screen and (max-width: $screen--s) {
                        position: relative;
                        .loading-mask {
                            position: absolute;
                        }
                    }

                    .block-title {
                        margin-top: 1.5rem;
                        margin-bottom: 1rem;
                        text-align: center;

                        .text {
                            line-height: 1.1;
                            color: #1c2028;
                            text-transform: uppercase;
                            font-size: 24px;
                            font-weight: bold;
                            margin-bottom: 5px;
                            letter-spacing: 1px;
                        }

                        .qty {
                            line-height: 1.1;
                            color: #1c2028;
                            text-transform: uppercase;
                            font-size: 24px;
                            font-weight: bold;
                            margin-bottom: 5px;
                            letter-spacing: 1px;

                            &:before {
                                content: '(';
                            }

                            &:after {
                                content: ')';
                            }
                        }
                    }

                    .block-content {
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: space-between;

                        .action.close, .items-total, .subtitle {
                            display: none;
                        }

                        .minicart-items-wrapper {
                            min-height: 235px;
                            order: 1;
                            background-color: $c-bg-grey;
                            margin-top: 15px;
                            margin-bottom: 20px;
                            padding: 20px;
                            border: none;
                            overflow-y: scroll;
                            display: flex;
                            flex-direction: column;
                            flex: auto;

                            .minicart-items {
                                background-color: $c-bg-grey;
                                margin: 0;
                                padding: 0;
                                list-style: none none;
                            }

                            .item.product {
                                border: none;
                                position: relative;

                                .product {
                                    display: flex;
                                    margin-top: 8px;

                                    .product-item-details {
                                        margin-left: 10px;

                                        .product-item-name {
                                            margin-bottom: 6px;

                                            a {
                                                font-size: 14px;
                                                font-weight: 500;
                                                text-decoration: underline;
                                                text-underline-offset: 0.18rem;
                                                text-transform: uppercase;
                                                color: $c-black;
                                            }
                                        }

                                        .product.options {

                                            span.toggle {
                                                display: none;
                                            }

                                            div.content {
                                                display: block !important;
                                            }
                                        }

                                        .product.options.list {
                                            display: flex;
                                            flex-wrap: wrap;
                                            list-style: none;
                                            padding: 0;
                                            flex-direction: column;

                                            li {
                                                display: flex;

                                                .label, .values {
                                                    color: $c-text-light;
                                                    font-size: 14px;
                                                    line-height: 1.14;
                                                    letter-spacing: .5px;
                                                    margin-bottom: 0;
                                                    font-weight: 400;
                                                }

                                                .label {

                                                    &:after {
                                                        content: ': ';
                                                    }
                                                }

                                                .values {
                                                    flex-grow: 1;
                                                    width: 70%;
                                                    margin-left: 5px;
                                                    text-transform: lowercase;

                                                    &:first-letter {
                                                        text-transform: capitalize;
                                                    }
                                                }
                                            }
                                        }

                                        .product-item-pricing {

                                            .price-excluding-tax {
                                                font-size: 14px;
                                                font-weight: 700;
                                                margin-bottom: 12px;
                                            }

                                            .details-qty {
                                                margin-top: 16px;

                                                .label {
                                                    display: none;
                                                }

                                                .update-cart-item {
                                                    padding: 0;
                                                    margin-top: 4px;
                                                    font-size: 12px;
                                                    text-decoration: underline;
                                                    color: $c-text-light;
                                                    border: none;
                                                    background-color: transparent;
                                                }

                                                input {
                                                    border-width: 1px;
                                                    border-radius: none;
                                                    border-left: none;
                                                    border-right: none;
                                                    font-size: 14px;
                                                    margin-right: 0;
                                                    text-align: center;
                                                    width: 40px;
                                                }

                                                .less, .more {
                                                    cursor: pointer;
                                                    border: 2px solid #e8e8e8;
                                                    border-width: 1px;
                                                    width: 32px;
                                                    height: 32px;
                                                    background: #fff;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                }

                                                .update-qty {
                                                    display: flex;
                                                }
                                            }
                                        }

                                        .product.actions {
                                            float: right;
                                            margin-top: -57px;
                                            padding-right: 0;

                                            .primary {
                                                display: none;
                                            }

                                            > .secondary {
                                                width: 100%;
                                                margin: 30px 0;

                                                .action.delete {
                                                    display: inline-block;
                                                    text-decoration: none;
                                                    position: absolute;
                                                    top: 0;
                                                    right: 5px;

                                                    &:before {
                                                        content: '';
                                                        background-image: url('../images/icons/close.svg');
                                                        width: 16px;
                                                        height: 16px;
                                                        background-size: 16px;
                                                        color: #303030;
                                                        font-family: "icons-blank-theme";
                                                        vertical-align: middle;
                                                        display: inline-block;
                                                        font-weight: normal;
                                                        overflow: hidden;
                                                        speak: none;
                                                        text-align: center;
                                                    }

                                                    > span {
                                                        border: 0;
                                                        clip: rect(0, 0, 0, 0);
                                                        height: 1px;
                                                        margin: -1px;
                                                        overflow: hidden;
                                                        padding: 0;
                                                        position: absolute;
                                                        width: 1px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .actions-summary {
                            display: flex;
                            flex-direction: column;
                        }

                        .subtotal {
                            order: 2;
                        }

                        .tax {
                            order: 5;
                            border-bottom: 1px solid #ccc;
                            padding-bottom: 20px;
                            margin-bottom: 20px;
                        }

                        .discount-total {
                            order: 3;
                        }

                        .totals.shipping.incl {
                            order: 4;
                        }

                        .grand-total {
                            order: 6;
                            font-size: 20px;
                            margin-bottom: 5px;
                        }

                        .promo-container {
                            order: 1;
                        }

                        .actions {
                            order: 7;
                            margin-top: 15px;
                            text-align: center;

                            .primary {
                                margin: 0;
                                text-align: center;

                                .action.primary {
                                    width: 100%;
                                    height: 46px;
                                    margin-bottom: 15px;
                                    padding: 14px 17px;
                                    line-height: 1.2;
                                    font-size: 13px;
                                    text-transform: uppercase;
                                    color: #fff;
                                    background: $c_primary;
                                    border: none;

                                    &:hover {
                                        text-decoration: none;
                                        background-color: #b54a1b;
                                        color: #fff;
                                        border-color: #b54a1b;
                                        border-width: 2px;
                                    }
                                }
                            }

                            .secondary {
                                .action.viewcart {
                                    display: none;
                                }
                            }
                        }

                        .subtotal, .tax, .grand-total, .totals.shipping.incl, .discount-total {
                            display: flex;
                            justify-content: space-between;

                            .price-wrapper {
                                font-weight: 700;
                            }
                        }

                        .tax {
                            display: block;
                            .tax-head {
                                display: flex;
                                justify-content: space-between;

                                &.head-toggle {
                                    position: relative;
                                    cursor: pointer;

                                    .label {
                                        &:after {
                                            position: absolute;
                                            bottom: 4px;
                                            left: 37px;
                                            content: "";
                                            display: inline-block;
                                            background-image: url('../images/icons/arrow-down.svg');
                                            -webkit-background-size: 16px;
                                            background-size: 10px;
                                            width: 10px;
                                            height: 10px;
                                            background-repeat: no-repeat;
                                            transition: transform .2s ease-in;
                                            transform: rotate(180deg);
                                        }
                                    }

                                    &.expanded {
                                        label {
                                            transform: rotate(0deg);
                                        }
                                    }
                                }
                            }
                            .totals-tax-details-minicart {
                                display: none;
                                &.shown {
                                    display: flex;
                                    justify-content: space-between;
                                }
                            }
                        }
                    }
                }

                .estimation_and_promo {
                    text-align: left;
                    padding-top: 20px;

                    #block-discount-heading, #block-shipping-heading {
                        font-size: 14px;
                        color: #434c5e;
                        text-decoration: underline;
                        text-underline-offset: 0.18rem;
                        cursor: pointer;
                    }

                    input, select, button.action {
                        border-radius: 3px;
                    }

                    .block.shipping {
                        margin-bottom: 10px;

                        #shipping-zip-form {
                            margin-top: 10px;

                            .fieldset > .legend {
                                display: none;
                            }

                            .field.question {
                                margin-bottom: 10px;

                                p {
                                    font-size: 16px;
                                }
                            }

                            .field[name="shippingAddress.region_id"], .field[name="shippingAddress.postcode"] {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                margin: 0;

                                .label {
                                    width: 100%;
                                    padding: 0;
                                    text-align: left;
                                    margin: 10px 0;
                                }

                                .control {
                                    width: 100%;

                                    .input-text, .select {
                                        height: 46px;
                                    }

                                    .field-error {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .field[name="shippingAddress.city"] {
                                display: none;
                            }

                            .field[name="shippingAddress.region"] {
                                display: none !important;
                            }

                            .field[name="shippingAddress.country_id"] {
                                visibility: hidden;
                                overflow: hidden;
                                height: 0;
                                margin: 0;
                            }
                        }

                        #co-shipping-method-form {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin: 15px 0;

                            .items.methods {
                                display: flex;
                                flex-direction: column;
                                align-items: baseline;
                                gap: 10px;
                            }
                        }
                    }

                    .block.discount {
                        margin-bottom: 20px;
                        display: block;

                        .content {
                            margin: 25px 0;
                        }

                        .fieldset.coupon {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .field, .control {
                                width: 100%;
                            }

                            .field {
                                margin: 0 0 10px;

                                .label {
                                    margin-bottom: 10px;
                                }

                                input {
                                    height: 46px;
                                }
                            }

                            .actions-toolbar {
                                .primary {
                                    width: 100%;
                                }
                            }

                            .promo-messages {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
