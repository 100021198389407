/**
 * Copyright © 2015 Magento. All rights reserved.
 * See COPYING.txt for license details.
 */

/* VARIABLES */
$main_color: #df5d24;
$line_color: #e8e8e8;
$text_color: #000;
$text_light: #FFF;
$text_night: #202020;
$text_hover: #999;
$text_women: #844a9e;
$text_grey: #565D67;
$text_font: 'Lato', sans-serif;

$font_18px: 1.00rem;
$font_20px: 1.12rem;
$font_24px: 1.33rem;
$font_28px: 1.56rem;
$font_32px: 1.77rem;
$font_36px: 2.00rem;
$font_48px: 2.60rem;
$font_64px: 3.43rem;
$font_72px: 4.00rem;

/* MAIN */
.catalog-product-view, .blog-post-view {
    .related-post {
        padding-left: 0 !important;
        padding-right: 0 !important;

        ol {
            list-style: none;
            margin: 0;
            padding: 0;

            &:after {
                @extend .clear-float;
            }
        }

        li {
            display: block;
            margin: 0 0 45px;
            padding: 0;
            text-align: center;
            clear: both;
            overflow: hidden;

            &:last-child {
                margin-bottom: 0;
            }

            .post-image {
                display: block;
                width: 33%;
                float: left;
                margin-right: 3%;
                background-position: center;
                background-size: cover;
                overflow: hidden;

                img {
                    visibility: hidden;
                }
            }

            .post-detail {
                width: 64%;
                float: left;
                text-align: left;
                margin-bottom: 5px;
                .mp-post-info {
                    text-transform: uppercase;
                    font-weight: 800;
                }
            }

            .mp-post-title{
                margin-bottom: 25px;
                .post-item-link {
                    color: #000;
                    font-weight: 800;
                    font-size: $font_20px;
                }
            }
            
            .mp-post-short-description {
                font-size: $font_18px;

                .mp-post-date {
                    font-size: 14px;
                    color: #9c9c9c;
                }
            }
        }
    }
}

a.post-read-more {
    font-weight: 700;
}

/* blog-index-index + blog-category-view + blog-post-view + blog-search-index */

.blog-index-index, .blog-category-view, .blog-post-view, .blog-search-index {

    .breadcrumbs {
        justify-content: flex-start;
        margin-top: 144px !important;

        @media only screen and (max-width: 800px) {
            margin-top: 50px !important;
        }

        > .items {
            width: 100%;
            max-width: 1100px;
            margin: auto;

            .item:not(:last-child):after {
                content: '\203A';
                position: relative;
                bottom: 3px;
                font-size: 20px;
            }
        }
    }

    .filter-collapse {
        display: none;
    }

    .search-collapse {
        display: none;
    }

    h1 {
        width: 100%;
        margin: 50px auto;
        text-align: center;
        text-transform: inherit;
        letter-spacing: 0.3px;
        font-weight: 700;
        font-size: $font_36px;
    }

    .home__slider-banner {
        .title {
            font-weight: 500;
            line-height: 22px;
            
            &:after {
                content: " ";
                display: block;
                width: 3%;
                margin: auto;
                border-bottom: 1px solid $text_light;
            }
        }

        .subtitle {
            text-transform: inherit;
            max-width: 530px;
            font-weight: 500;
            font-size: $font_36px;
            line-height: 32px;
        }

        .slide {
            max-height: 500px;
            min-height: initial;
        }
    }

    .toolbar.toolbar-blog-posts {
        .pages {
            float: none;
            display: block;
            padding-top: 50px;
            text-align: center;
        }

        .pages-items {
            li {
                margin-right: 14px;

                strong,
                a {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    line-height: 35px;
                    border: 1px solid #cacaca;
                    padding: 0;
                    text-align: center;
                    color: #text_color;
                    font-size: 14px;
                }

                &.current {
                    strong {
                        background-color: $main_color;
                        border-color: $main_color;
                        color: #fff;
                    }
                }

                &.pages-item-previous,
                &.pages-item-next {
                    margin-right: 0;

                    a {
                        margin-left: 0;
                        border-color: transparent;
                    }
                }

                &:not(.pages-item-next):not(.pages-item-previous):hover {
                    a {
                        border-color: $main_color;
                        color: $main_color;
                    }
                }
            }
        }
    }

    .mp-sidebar.mp-sidebar-category {
        margin-bottom: 40px;
        padding: 40px 0 25px 0;
        border-bottom: 3px solid $main_color;

        .row > div {
            padding: 0;
        }

        nav.category-top-nav {
            display: flex;
            align-items: center;
            justify-content: center;

            .search-container {
                display: flex;

                > form {
                    display: flex;

                    > button {
                        border: unset;
                        background: unset;
                        padding: 5px 0 0px 0px;

                        > svg {
                            width: 15px;
                        }
                    }

                    > input[type="text"], input[type="text"]:focus {
                        border: unset;
                        width: 180px;
                        color: $text_grey;
                        font-weight: 400;
                        font-size: 16px;
                        font-family: $text_font;

                    }
                
                    ::placeholder {
                        color: $text_grey;
                        opacity: 1;
                    }
                }
            }

            nav {
                display: none;
            }

            a {
                padding: 0 15px;
                color: $text_grey;
                @extend .font-size-16px;
                font-weight: 400;
                text-transform: inherit;

                &:hover {
                    color: $main_color;
                }
            }
        }

        .filter-container-mobile {
            display: none;
        }
    }

    .post-list-container {
        margin-top: 50px;

        .row {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .post-list-item {
        position: relative;
        width: 49%;
        margin: 0 2px 20px;

        .mp-post-info {
            position: relative;
            display: block;
            padding: 20px 0;
            z-index: 1;

            a {
                display: inline-block;
                margin-right: 1px;
                margin-bottom: 3px;
                padding: 2px 5px;
                color: $main-color;
                font-weight: bold;
                background-color: $text_light;
                text-decoration: underline;

                &:hover {
                    color: $main_color;
                }
            }

            a:not(:last-child):after {
                content: '|';
                position: relative;
                bottom: 0px;
                left: 5px;
                font-size: 15px;
            }
        }
        .post-info-wrapper {
                height: 165px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

            .mp-post-title {
                margin: 0 0 20px 0;
                font-weight: bold;
                font-size: 30px;
                line-height: 35px;
                color: $text_color;
            }

            .post-short-description {
                color: $text_color;
            }
        }

        .post-actions {
            display: flex;
            justify-content: center;
            margin: 2rem auto;
        }
    }

    .post-item-wrapper {
        position: inherit;
        width: 530px;
        height: 260px;
        background: $line_color no-repeat center;
        background-size: cover;
        overflow: hidden;

        .post-image {
            display: none;
        }

        .hover-overflow {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 0;
            @extend .transition-class;
        }

        .post-info-wrapper {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            background-color: rgba(0, 0, 0, 0);
            @extend .transition-class;
            z-index: 1;

            h2.mp-post-title {
                margin: 0;
                font-size: $font_18px;
                line-height: 1.86;

                span {
                    padding: 4px 20px;
                    display: inline;
                    background-color: rgba(0, 0, 0, 0.7);
                    color: $text_light;
                    text-transform: none;
                    -webkit-box-decoration-break: clone;
                    -ms-box-decoration-break: clone;
                    box-decoration-break: clone;
                }
            }

            .post-short-description {
                display: block;
                padding: 30px 20px;
                color: $text_light;
                @extend .font-size-16px;
                visibility: hidden;
            }
        }

        &:hover {
            .hover-overflow {
                background-color: rgba(0, 0, 0, 0.7);
            }

            .post-info-wrapper {
                h2 span {
                    background-color: $main_color;
                }

                .post-short-description {
                    visibility: visible;
                }
            }
        }
    }

    .toolbar.toolbar-blog-posts .pages-items li a, .toolbar.toolbar-blog-posts li.current strong {
        border-radius: 25px;
        color: #000;
    }
}

/* blog-index-index + blog-category-view + blog-post-view + blog-search-index screen min-width: 800 */

@media only screen and (max-width: 800px) {
    .blog-index-index, .blog-category-view, .blog-post-view, .blog-search-index {
        .mp-sidebar.mp-sidebar-category {
            padding: 10px 0 10px 0;
            border-bottom: unset;
            margin-bottom: 0;

            nav.category-top-nav {
                justify-content: space-between;
                margin: 0 13px;
                position: relative;

                .filter-collapse {
                    display: block;
                    cursor: pointer;

                    > span {
                        margin-left: 1rem;
                    }
                }

                .filter-container {
                    display: none;
                }

                .search-container {
                    display: none;
                }

                .search-collapse {
                    display: block;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    svg {
                        margin: 4px 0 0 10px;
                        width: 18px;
                    }
                }
            }

            .filter-container-mobile {
                display: none;
                padding: 15px;
                width: 90%;
                margin: auto;
                height: auto;
                box-shadow: 0px 0.5px 10px rgba(227, 227, 227, 0.93);

                .modal-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2rem;

                    h3, a {
                        margin: 0;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 14px;
                    }

                    svg {
                        width: 10px;
                        cursor: pointer;
                    }
                }

                .modal-content {
                    display: flex;
                    flex-wrap: wrap;

                    a {
                        border: 1px solid #FCEFE9;
                        border-radius: 27px;
                        padding: 3px 10px;
                        margin: 5px;
                        color: $text_color;

                        &:before {
                            content: '+';
                            color: $main_color;
                            margin-right: .5rem;
                        }
                    }

                    form {
                        width: 100%;
                        border: 1px solid grey;
                        border-radius: 27px;
                        display: flex;

                        input {
                            border: unset;
                            width: 80%;
                            color: #565D67;
                            font-weight: 400;
                            font-size: 16px;
                            font-family: "Lato", sans-serif;
                        }

                        button {
                            border: unset;
                            background: unset;
                            padding: 5px 0 0 10px;
                        }
                    }
                }
            }
        }

        .post-list-container {
            margin-top: 0;

            .post-list-item {
                width: 100% !important;
                margin: 0 13px 50px 13px;
                padding: 1rem;
                background: $text_light;
                box-shadow: 0px 0.5px 10px rgba(227, 227, 227, 0.93);

                .mp-post-info {
                    padding: 20px 0;
                }

                .post-info-wrapper {
                    height: auto;
                }
            }

            .post-item-wrapper {
                width: 100%;
            }
        }
    }
}

#mostview, #mostrecent, #mostcate {
    h3 {
        margin: 0;
        padding-bottom: 15px;
        border-bottom: 1px solid $text_grey;
        font-size: $font_20px;
        font-weight: 700;
        text-transform: none;
    }
}

.sidebar.sidebar-additional {
    #mostview, #mostrecent {
        margin-bottom: 30px;

        .list-post-tabs {
            padding-left: 0;
            padding-right: 0;

            .post-right {
                span:first-child {
                    color: $main_color;
                }

                span:last-child {
                    color: $text_color;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }
}

/* blog-index-index */
.blog-index-index {
    #maincontent {
        max-width: 1083px;
        margin: 0 auto;
        padding: 0 !important;

        h1.page-title {
            margin: 0px auto;
        }

        .blog-main-container {
            max-width: 1100px;
            margin: 0 auto;
        }
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .blog-banner-mobile {
        display: none;
    }

    .mp-sidebar.mp-sidebar-category {
        margin-bottom: 10px;
    }

    .mp-sidebar-most-view-recent {
        clear: both;
        max-width: 1100px;
        margin: 0 auto;
        padding-top: 40px;

        .tab-content {
            padding-top: 40px;

            &:after {
                @extend .clear-float;
            }
        }

        #mostview, #mostrecent {
            display: block;
            width: 100%;

            .list-post-tabs {
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 15px 0;
                color: inherit;
                width: 351px;
                height: 480px;
                background: $text_light;
                box-shadow: 0px 0.5px 10px rgba(227, 227, 227, 0.93);


                &:after {
                    @extend .clear-float;
                }

                > div {
                    float: left;
                }

                .post-left {
                    width: 328px;
                    height: 219px;
                    background: no-repeat center;
                    background-size: cover;
                    overflow: hidden;

                    img {
                        width: 100%;
                        visibility: hidden;
                    }
                }

                .post-right {
                    width: 328px;
                    height: 200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .mp-post-info {
                        a {
                            text-decoration: underline;
                            margin: 5px;
                        }

                        a:not(:last-child):after {
                            content: '|';
                            position: relative;
                            bottom: 0px;
                            left: 5px;
                            font-size: 15px;
                        }
                    }

                    .post-recent-title {
                        text-align: center;
                        font-size: $font_24px;
                        font-weight: bold;
                    }
                }
            }
        }

        #mostrecent {
            float: left;
            padding-right: 15px;

            .most-recent-articles {
                margin-top: 25px;
                display: flex;
                justify-content: space-between;
            }
        }

        #mostview {
            float: right;
            padding-left: 15px;
        }
    }
}

/* blog-index-index screen min-width: 800 */

@media only screen and (max-width: 800px) {
    .blog-index-index {
        width: 100%;
        padding: 0;

        .home__slider-banner {
            display: none;
        }

        .blog-title-wrapper {
            display: none;
        }

        .blog-banner-mobile {
            display: block;

            .blog-image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 500px;
                background-position: center center;
                background-size: cover;
            }
            
            .blog-title {
                color: $text_light;
                text-transform: uppercase;
            }
        }
    }

    .mp-sidebar-most-view-recent {
        display: none;
    }

    .toolbar.toolbar-blog-posts .pages {
        padding: 50px 0;
    }
}

/* .blog-category-view + .blog-tag-view  */
.blog-category-view, .blog-tag-view {
    #maincontent {
        width: 100%;
        padding: 0 !important;
        max-width: 100%;

        .page-title-wrapper {
            background: url(../images/blog-banner.png);
            height: 328px;
            background-position: center center;
            background-size: cover;
            display: flex;
            align-items: center;
            color: $text_light;
        }

        .mp-sidebar.mp-sidebar-category {
            max-width: 1100px;
            margin: 0 auto;
        }

        .columns .column.main {
            max-width: 1100px;
            margin: 0 auto;

            .post-list-container .row {
                margin: 0 auto;
            }
        }
    }

    .columns {
        padding-bottom: 50px;
    }

    .post-list-item {
        margin-bottom: 30px;

        .post-image {
            padding-left: 0;
            padding-right: 0;
        }

        h2.mp-post-title {
            margin-top: 10px;
            text-transform: none;
            font-size: $font_20px;
            line-height: 1;

            a {
                font-size: inherit;
            }
        }
    }

    .mp-sidebar-most-view-recent {
        #mostview, #mostrecent {
            .list-post-tabs {
                display: inline-flex;
                width: 100%;
                padding: 20px 0;
                border-bottom: 1px solid #ddd;
                color: inherit;

                > div {
                    float: left;
                }

                .post-left {
                    width: 25%;
                    margin-right: 4px;
                    background-position: center;
                    background-size: cover;

                    .img-responsive {
                        visibility: hidden;
                    }
                }

                .post-right {
                    width: 73%;
                    
                    span {
                        display: block;
                        font-size: 14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;   
                    }

                    span:first-child {
                        color: $main_color;
                        white-space: normal;
                        line-height: 1;
                    }

                    span:last-child {
                        color: $text_color;
                        font-size: 12px;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}


/* blog-category-view screen min-width: 800 */

@media only screen and (max-width: 800px) {
    .blog-category-view #maincontent {
        .page-title-wrapper {
            display: none;
        }

        .mp-sidebar.mp-sidebar-category {
            margin: 6rem 0 0 0;
        }
    }
}

/* blog-search-index */

.blog-search-index {
    #maincontent {
        padding: 0 !important;
        max-width: 1100px;

        .columns {
            display: flex;

            .column.main {
                max-width: 1100px;
                margin: 0 auto;

                .post-list-container .row {
                    margin: 0 auto;
                }
            }
        } 
    }
}

/* blog-search-index screen min-width: 800 */

@media only screen and (max-width: 800px) {
    .blog-search-index {
        .breadcrumbs {
            display: block;
            margin-top: 85px !important;
        }
    }
}

/* POST */
.blog-post-view {
    #maincontent {
        padding: 0 !important;
        max-width: 1100px;
    }

    .post-view .post-category-name {
        display: none;
    }

    .mp-sidebar.mp-sidebar-category {
        max-width: 1100px;
        margin: 0 auto;
    }

    .post-post_meta > div {
        display: block;
        margin-bottom: 30px;
        text-align: center;
        @extend .font-size-16px;
    }

    .post-image {
        width: 100%;
        max-height: 600px;
        background-color: transparent !important;
        overflow: hidden;
    }

    .post-post_content {
        margin-top: 0;

        div:not(:first-child) {
            margin: 0 2rem;
        }

        > p {
            margin: 0 2rem;

            a {
                width: fit-content !important;
            }
        }

        .blog-post-view-banner-mobile {
            display: none;
        }

        .blog-post-view-banner {
            height: 500px;
            background-position: center center;
            background-size: cover;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            color: $text_light;
            margin-top: 20px;

            .blog-post-view-info {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .blog-post-view-title {
                    max-width: 530px;
                    margin: 50px auto;
                    text-align: center;
                    text-transform: inherit;
                    letter-spacing: 0.3px;
                    font-weight: 500;
                    font-size: $font_36px;
                    font-weight: 500;
                    line-height: 45px;
                }

                .blog-post-view-description {
                    max-width: 765px;
                    text-align: center;
                    font-size: 22px;
                    line-height: 25px;
                }
            }
        }

        .blog-post-view-content {
            max-width: 1130px;

            .text-box {
                max-width: 765px;
                margin: 4rem auto 0;
            }

            .img-box {
                width: 100%;
                height: 500px;
                background-position: center center;
                background-size: cover;
                display: -webkit-box;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                color: #FFF;
                margin: 50px 0 0 0 !important;
            }

            hr {
                width: 400px;
            }
            
            .box-left, .box-right {
                display: flex;
                justify-content: space-between;
                margin: 50px 0;
            }

            .box-right {
                flex-direction: row-reverse;
            }

            .box-content, .box-img {
                width: 367px;
                min-height: 217px;
            }

            .box-content {
                > h2 {
                    margin-top: 0;
                }
            }

            .box-img {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 238px;
                    height: auto;
                }
            }            
        }

        > h2 {
            margin: 2rem;
        }

        h2 {
            clear: both;
            font-size: $font_20px;
            text-transform: none;
            font-weight: bold;
        }

        blockquote {
            clear: both;
            position: relative;
            float: left;
            display: inline-block;
            width: 50%;
            margin: 0;
            padding: 32px;
            border-left: 0;
            font-size: $font_24px;
            font-weight: 700;
            font-style: normal;

            &:before, &:after {
                content: '';
                position: absolute;
                display: block;
                width: 32px;
                height: 32px;
                color: $line_color;
                font-size: 84px;
                line-height: 1;
            }

            &:before {
                content: '“';
                top: 0;
                left: 0;
            }

            &:after {
                content: '”';
                bottom: 32px;
                right: 32px;
            }
        }

        .ves-container.ves-parallax {
            padding-top: 100px;
            padding-bottom: 100px;
            background-size: cover;

            div {
                background-color: transparent;
            }
        }
    }

    .post-post_tags {
        margin-top: 60px;
        padding-bottom: 60px;

        h2 {
            margin-top: 0;
            margin-bottom: 1.5rem;
            font-size: $font_20px;
        }
        
        a.mp-info {
            display: inline-block;
            margin-right: 8px;
            margin-bottom: 8px;
            padding: 6px 16px 8px;
            border: 2px solid $line_color;
            border-radius: 20px;
            color: #c4c4c4;

            &:hover {
                border-color: $main_color;
                color: $main_color;
            }
        }
    }

    .post-same-topics {
        margin-bottom: 30px;

        h2 {
            @extend .block-title;
        }
    }

    .related-post-wrapper {
        margin-left: -15px;
        margin-right: -15px;

        .relate-content.item {
            h3 {
                color: $text_color;
                font-size: $font_18px;
                text-transform: none;
            }

            .post-image {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 70%;
                overflow: hidden;

                img {
                    position: absolute;
                    display: block;
                    width: auto;
                    min-height: 100%;
                }
            }

            a:hover {
                h3 {
                    color: $main_color;
                }
            }
        }
    }

    .share-box {
        margin-top: 48px;

        h4 {
            width: 100%;
            line-height: 1;
        }

        .post-sharing-button {
            div {
                display: inline-block;
                width: 48px;
                height: 48px;
                margin-right: 12px;
                background-color: $line_color;
                @extend .transition-class;

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                display: block;
                width: 48px;
                height: 48px;
                overflow: hidden;
                @extend .icon-set;
                text-indent: -999px;
            }

            div.icon-fb {
                &:hover {
                    background-color: #3b5998;
                }

                a {
                    background-position: 0 -336px;
                }
            }

            div.icon-tw {
                &:hover {
                    background-color: #4099ff;
                }

                a {
                    background-position: -144px -336px;
                }
            }
        }
    }

    .more-articles {
        max-width: 400px;
        text-align: center;
        margin: 0 auto;
        text-decoration: underline;
        font-weight: 600;
        position: relative;
    }

    .post-view .post-bottom {
        display: none;
    }

    .mp-sidebar-most-view-recent {
        clear: both;
        margin-bottom: 50px;
        padding-top: 40px;

        .tab-content {
            padding-top: 40px;

            &:after {
                @extend .clear-float;
            }
        }

        #mostview, #mostrecent {
            display: block;
            width: 100%;

            .list-post-tabs {
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 15px 0;
                color: inherit;
                width: 351px;
                height: 480px;
                background: $text_light;
                box-shadow: 0px 0.5px 10px rgba(227, 227, 227, 0.93);


                &:after {
                    @extend .clear-float;
                }

                > div {
                    float: left;
                }

                .post-left {
                    width: 328px;
                    height: 219px;
                    background: no-repeat center;
                    background-size: cover;
                    overflow: hidden;

                    img {
                        width: 100%;
                        visibility: hidden;
                    }
                }

                .post-right {
                    width: 328px;
                    height: 200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .mp-post-info {
                        a {
                            text-decoration: underline;
                            margin: 5px;
                        }

                        a:not(:last-child):after {
                            content: '|';
                            position: relative;
                            bottom: 0px;
                            left: 5px;
                            font-size: 15px;
                        }
                    }

                    .post-recent-title {
                        text-align: center;
                        font-size: $font_24px;
                        font-weight: bold;
                    }
                }
            }
        }

        #mostrecent {
            float: left;
            padding-right: 15px;

            .most-recent-articles {
                margin-top: 25px;
                display: flex;
                justify-content: space-between;
            }
        }

        #mostview {
            float: right;
            padding-left: 15px;
        }
    }
}

/* blog-post-view screen min-width: 800 */

@media only screen and (max-width: 800px) {
    .blog-post-view {
        .more-articles {
            display: none;
        }

        .mp-sidebar.mp-sidebar-category {
            margin: 6rem 0 0 0;
        }

        .post-post_content {
            
            .blog-post-view-banner {
                display: none;
            }

            .blog-post-view-banner-mobile {
                display: block;
                width: 100%;
                margin: 5rem auto 0 !important;

                .blog-post-view-title, .blog-post-view-description, .blog-post-date {
                    width: 85%;
                    margin: 1rem auto;
                    text-align: left;
                }

                .blog-post-view-title {
                    text-transform: uppercase;
                }

                .blog-post-view-description {
                    margin: 1rem auto;
                    text-align: center;
                    font-style: italic;
                }

                .blog-post-date {
                    color: #8C96A9;
                }
            }

            .blog-post-view-content {
                margin: 35px !important;

                .box-right, .box-left {
                    flex-direction: column;
                    margin: 40px 0 0 0;
                }

                .box-content, .box-img {
                    width: 100%;
                    min-height: auto;
                    margin: 0 0 2rem 0 !important;
                }

                hr {
                    display: none;
                }

                .box-img, .box-img-tall {
                    min-height: auto; 
                    margin-bottom: 2rem;
                }
            }
        }

        .post-view {
            .post-category-name {
                display: block;
                text-decoration: underline;
                width: 85%;
                margin: 1rem auto;
            }

            .post-bottom {
                display: block;

                .related {
                    padding: 14px;
                }
            }

            .post-nextprev-hld {
                margin-bottom: 30px;
                display: flex;
                flex-direction: column-reverse;
                align-items: center;

                .nextprev-link {
                    background-position: center;
                    background-size: cover;
                    width: 320px;
                    height: 246px;
                    margin: 10px auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    text-align: center;
                    padding: 25px;
                    color: $text_light;

                    .next-info, .prev-info {
                        display: flex;
                        flex-direction: column;
                    }

                    .next-action, .prev-action {
                        display: flex;
                        align-items: center;
                        width: 175px;
                        justify-content: space-between;
                        text-transform: uppercase;
                    }

                    .tag {
                        margin-bottom: 20px;
                        font-size: 14px;
                        line-height: 22px;

                        &:after {
                            content: " ";
                            display: block;
                            width: 3%;
                            margin: auto;
                            border-bottom: 1px solid $text_light;
                        }
                    }

                    .title {
                        font-size: $font_24px;
                        line-height: 32px;
                    }
                }
            }
        } 
    }
}