
// Complete progress bar override.

.opc-progress-bar {
    display: flex;
    margin-bottom: 40px;
    padding-left: 0;
    counter-reset: i;
    width: calc(66% - 120px);

    &-item {
        flex-grow: 1;
        list-style: none;
        margin: 0 5px;
        border-bottom: 1px solid $c_border-grey;
        text-align: center;
        color: $c_text-light;
        text-transform: uppercase;
        white-space: nowrap;
        font-weight: bold;

        &._active {
            border-bottom: 3px solid $c_primary;
            color: $c_text-dark;
        }

        a {
            color: $c_text-light !important;

            &:hover {
                color: $c_text-light !important;
            }
        }

        &._complete {
            cursor: pointer;
        }

        &.cart a,
        &:not(.cart) span {
            padding: 20px 10px;
            letter-spacing: 1px;
            display: block;

            &:before {
                content: "0" counter(i) " - ";
                counter-increment: i;
            }
        }
    }
}

@media screen and (max-width: $screen__l) {

    .opc-progress-bar {
        width: calc(66% - 60px);

        &-item {
            white-space: normal;

            &.cart a,
            &:not(.cart) span {
                padding: 10px;

                &:before {
                    content: "0" counter(i);
                    display: block;
                }
            }
        }
    }
}

@media screen and (max-width: $screen__m) {

    .opc-progress-bar {
        width: 100%;
        margin-bottom: 30px;

        &-item {

            &.cart a,
            &:not(.cart) span {
                padding: 20px 10px;
                font-size: 1.2rem;

                &:before {
                    content: none;
                }
            }
        }
    }
}