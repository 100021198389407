body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .page-main {
        padding: 120px 0 0 !important;
    }

    @media screen and (max-width: $screen--l) {

        .page-main {
            padding: 0 !important;
        }
    }

    @media screen and (max-width: 845px) {
        padding-top: 0 !important;
    }

    &.top-header-message-opened {
        .page-main {
            padding: 144px 0 0 !important;
        }

        @media screen and (max-width: $screen--l) {

            .page-main {
                padding: 86px 0 0 !important;
            }
        }

        @media screen and (max-width: 730px) {
            .page-main {
                padding: 106px 0 0 !important;
            }
        }

        @media screen and (max-width: 395px) {
            .page-main {
                padding: 125px 0 0 !important;
            }
        }
    }
}

html.adjusted-font-size {
    font-size: 10px;
}

body.modal-opened {
    overflow: hidden;
}

.page-wrapper {
    overflow: hidden;
}

// Links
// -------------------------------------------------------
.a_underline {
    color: $c_text-light;
    text-decoration: underline;
    border: none;
    background: transparent;
    padding: 0;

    &:focus,
    &:active,
    &:hover {
        color: $c_text-dark;
        border: none;
        background: transparent;
    }
}

.a_arrow {
    color: $c_primary;
    text-transform: uppercase;
    border: none;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;

    &--spaced {
        @extend .a_arrow;
        display: flex;
        justify-content: space-between;
    }

    &:after {
        content: url('../images/icons/arrow.svg');
        position: relative;
        top: 3px;
        left: 0;
        margin-left: 10px;
        transition: left 0.24s ease;
    }

    &:focus,
    &:active,
    &:hover {
        color: $c_primary !important;

        &:after {
            left: 5px;
        }
    }

    @media screen and (max-width: $screen__m) {

        &--spaced {
            justify-content: flex-start;
        }
    }
}

// Buttons
// -------------------------------------------------------

// Temporary parent class to scope styles
.checkout-index-index,
.checkout-cart-index,
.checkout-onepage-success {

    .actions-toolbar button {
        border-radius: 4px !important;
    }
}
.block.block-minicart, .checkout-index-index,
.checkout-cart-index,
.checkout-onepage-success {
    .loading-mask {
        background-color: rgba($c_white, 0.5);

        .loader {
            $loader-size: 38px;

            img {
                box-sizing: border-box;
                width: $loader-size;
                height: $loader-size;
                padding-left: $loader-size;
                background-image: url('../images/loader.svg');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}

.q_b-primary,
a.q_b-primary {
    display: inline-block;
    padding: 12px 20px;
    font-size: 13px;
    color: $c_white;
    //font-family: $f-montserrat-medium;
    background-color: $c_primary;
    border: 1.5px solid $c_primary;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.24s ease, color 0.24s ease, border-color 0.24s ease;
    border-width: 2px;

    &:visited {
        color: $c_white;
        background-color: $c_primary;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        background-color: darken($c_primary, 10%);
        color: $c_white;
        border-color: darken($c_primary, 10%);
        border-width: 2px;
    }
}

.q_b-primary--border,
a.q_b-primary--border {
    @extend .q_b-primary;
    background-color: transparent;
    color: $c_primary;

    &:visited {
        background-color: transparent;
        border-color: $c_primary;
        color: $c_primary;
    }

    &:focus,
    &:hover {
        color: $c_white;
    }
}

.q_b-secondary,
a.q_b-secondary {
    @extend .q_b-primary;
    background-color: $c_secondary;
    border-color: $c_secondary;

    &:visited {
        background-color: $c_secondary;
    }

    &:focus,
    &:hover {
        background-color: darken($c_secondary, 10%);
        border-color: darken($c_secondary, 10%);
        color: $c_white !important;
    }
}

.q_b-primary--grey,
a.q_b-primary--grey {
    @extend .q_b-primary;
    background-color: $c_grey--60;
    border-color: $c_grey--60;

    &:visited {
        background-color: $c_grey--60;
    }

    &:focus,
    &:hover {
        background-color: darken($c_grey--60, 10%);
        border-color: darken($c_grey--60, 10%);
        color: $c_white !important;
    }
}

// Custom modal
.modal {
    position: fixed;
    z-index: 10000;
    background: rgba($c_black, 0.8);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.48s ease-in-out;
    overflow-y: auto;

    &.opened {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
    }

    &.closing {
        opacity: 0;
    }

    .modal__close {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 42px;
        height: 42px;
        z-index: 100;
        border-radius: 100%;
        cursor: pointer;
        background: $c-primary;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        svg #close-fill {
            fill: $c-white;
        }
    }

    &__wrapper {
        position: absolute;
        width: 100%;
        max-width: 960px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        background-color: $c_white;
    }

    &__header {
        padding: 30px;
        text-align: center;
    }

    &__content {
        padding: 40px;
        position: relative;
    }

    &__footer {
        padding: 30px 40px 40px;
    }

    @media screen and (max-width: 768px) {
        overflow-y: auto;

        &__wrapper {
            max-width: none;
            max-height: none;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translate(0, 0);
        }
    }

    @media screen and (max-width: 640px) {

        &__content {
            padding: 15px;
        }
    }
}

// Products zoom
.chocolat-wrapper {
    z-index: 1000;
}

/* TODO put following in customer module once redone */
@media screen and (max-width: $screen__m) {
    .customer-account-login {
        .page-main .column.main {
            margin: 0 20px;
        }
    }
}

#pickup-message {
    margin-top: 10px;
}
