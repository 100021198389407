/**
 * Copyright © 2015 Magento. All rights reserved.
 * See COPYING.txt for license details.
 */

/* VARIABLES */
$main_color: #df5d24;
$line_color: #e8e8e8;
$text_color: #000;
$text_light: #FFF;
$text_night: #202020;
$text_hover: #999;
$text_women: #844a9e;
$text_font: 'Lato', sans-serif;

$font_18px: 1.00rem;
$font_20px: 1.12rem;
$font_24px: 1.33rem;
$font_28px: 1.56rem;
$font_32px: 1.77rem;
$font_36px: 2.00rem;
$font_48px: 2.60rem;
$font_64px: 3.43rem;
$font_72px: 4.00rem;

/* MAIN */
.cms-page-view, .contact-index-index {
    .column.main {
        margin-bottom: 50px;

        #authenticationPopup + * {
            margin-top: 0;
        }
    }

    #map-canvas {
        height: 100%;
        min-height: 480px;
    }

    .validation-advice {
        margin-top: 5px;
        font-size: 11px;
        text-align: left;
    }

    .section-fullwidthvideo {
        position: relative;
        padding: 25px 0 56.25% 0 !important;
        height: 0 !important;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }
}

/* CMS */
.cms-page-view {
    /* PRIORITY */
    .column.main {
        section {
            .row h1 {
                margin-bottom: 0 !important;
            }
        }

        .store-top {
            padding-bottom: 0;
        }

        .store-location .row {
            h2 {
                margin: 80px 0 30px !important;
                text-align: left;

                &:first-child {
                    margin-top: 0 !important;
                }
            }
        }

        .service-form {
            .if-main-frame {
                padding-bottom: 0;

                .container {
                    max-width: 1100px;
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .col-lg-10.col-lg-offset-1 {
                    width: 100%;
                    margin-left: 0;
                    padding: 0;
                }

                .col-md-10.col-lg-offset-2 {
                    width: 100%;
                    margin-left: 0;
                    padding: 0;
                }
            }
        }

        .delivery-content {
            .row {
                h2 {
                    text-align: left;
                }

                > div > img {
                    margin-bottom: 30px;
                }
            }
        }

        .gift-card-content {
            .row h2 {
                margin-bottom: 15px !important;
                text-align: left;
                font-weight: 900 !important;
            }
        }

        .events {
            padding-top: 0;
        }
    }

    /* STORE */
    .store-top {
        .store-menu {
            margin: 0 0 10px 0;
            background-color: $main_color;
            text-align: center;
        }

        .store-list {
            @extend .content-wrapper;

            li {
                display: inline-block;
                width: auto;
                margin: 0;

                > * {
                    position: relative;
                    display: block;
                    padding: 15px 20px;
                    color: $text_color;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1;
                }

                a:hover {
                    color: $text_light;
                }

                span.current-store {
                    color: $text_light;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        display: block;
                        width: 0;
                        height: 0;
                        margin-left: -8px;
                        border-left: 12px solid transparent;
                        border-right: 12px solid transparent;
                        border-bottom: 10px solid $text_light;
                    }
                }
            }
        }

        .store-street-view {
            width: 100vw;
            height: 50vh;
            padding: 0;
            @extend .fullwidth;

            #street-view {
                display: block;
                height: 100%;
            }
        }
    }

    .store-service, .store-content {
        position: relative;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 80px;
        background-color: $line_color;
    }

    .store-service {
        .row.service-list {
            padding-top: 20px;
            text-align: center;

            > div {
                float: none;
                display: inline-block;
                margin-left: -2px;
                margin-right: -2px;
                border: 0;
                text-align: center;

                &.disabled {
                    display: none;
                }

                &:hover {
                    .service-title div:first-child {
                        background-position: 0 0;
                    }
                }
            }

            .service-title {
                display: block;
                margin-bottom: 24px;
                font-weight: 900;
                text-transform: uppercase;
                color: $text_color;
                line-height: 1.2;
                letter-spacing: 1px;

                img {
                    display: none;
                }

                div:first-child {
                    display: block;
                    width: 96px;
                    height: 96px;
                    margin: 0 auto 32px;
                    background: no-repeat 0 -96px;
                }
            }
        }
    }

    .store-content {
        text-align: center;
    }

    .store-location {
        .hour-list {
            .hour-item {
                span {
                    display: inline-block;
                    width: 48%;

                    &:last-child {
                        font-weight: 900;
                    }
                }
            }
        }
    }

    /* DELIVERY */
    &.cms-livraisons .column.main section {
        &:nth-of-type(odd) {
            .row > div {
                float: right;
            }
        }

        &:nth-of-type(even) {
            background-color: #f8f7f8;
        }

        &:first-of-type, &:last-of-type {
            .row > div {
                float: left;
                text-align: center;
            }
        }

        h2 {            
            strong, b {
                display: block;
            }
        }
    }

    /* GIFT CARD */
    .gift-card-top + .gift-card-content {
        padding-bottom: 0 !important;

        .row {
            padding-bottom: 60px;
            border-bottom: 2px solid $line_color;
        }
    }

    .gift-card-content {
        .gift-title-content {
            float: left;
            width: calc(100% - 370px);
        }

        .gift-ex-card {
            float: right;
            width: 350px;
            border: 2px solid $main_color;
            background: url(../images/gift-card-icon.png) 15px 21px no-repeat;
            padding: 15px 5px 15px 82px;

            .sub-title {
                font-size: $font_18px;
                font-weight: 900;
                color: $main_color;
                text-transform: uppercase;
                margin-bottom: 0;
            }
        }

        .gift-list-block {
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
            }

            li {
                padding: 0;
                margin: 0 0 40px;
                list-style: none;

                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }

                h3 {
                    font-weight: 900;
                }
            }

            .img-block {
                float: left;
                width: 445px;

                img {
                    width: 100%;
                }
            }

            .text-block {
                float: right;
                width: calc(100% - 500px);
            }

            h4 {
                font-size: 1.33rem;
                font-weight: bold;
                text-transform: uppercase;
                margin: 30px 0 20px;
            }

            .main-btn {
                display: inline-block;
                margin-top: 20px;
            }
        }
    }

    /* CLUBS */
    .club-content {
        .club-logo-wrapper {
            text-align: center;
            margin-bottom: 20px;

            .club-logo {
                height: 160px;
                margin-bottom: 40px;
                position: relative;

                img {
                    max-height: 160px;
                    width: auto;
                    display: inline-block;
                    vertical-align: bottom;
                }
            }

            p {
                margin-bottom: 45px;
            }

            .club-button {
                list-style: none;
                margin: 0;
                padding: 0;
                font-size: 0;
                display: block;

                li {
                    margin: 0 0 0 10px;
                    padding: 0;
                    font-size: 16px;
                    display: inline-block;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .club-featured {
        height: 540px;
        background-repeat:  no-repeat;
        background-size: cover;
        background-position: center 30%;
    }

    /* IMPLICATION */
    .implication-featured {
        padding: 0 !important;

        > div {
            &:first-of-type {
                margin-bottom: 20px;
                padding-left: 0;
                padding-right: 0;
            }

            &:nth-of-type(2) {
                padding-left: 0;
                padding-right: 10px;
            }

            &:nth-of-type(3) {
                padding-left: 10px;
                padding-right: 0;
            }

            img {
                width: 100%;
            }
        }
    }

    /* EVENTS */
    .events {
        ul {
            width: auto;
            margin: 0 -10px;

            &:after {
                @extend .clear-float;
            }

            li {
                position: relative;
                float: left;
                width: -webkit-calc(33.33% - 20px);
                width: calc(33.33% - 20px);
                height: 0;
                margin: 10px;
                padding-bottom: -webkit-calc(33.33% - 20px);
                padding-bottom: calc(33.33% - 20px);
                overflow: hidden;

                .event-item-wrapper {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-size: cover;

                    .event-box {
                        display: table;
                        width: inherit;
                        height: inherit;

                        .event-image {
                            position: absolute;
                            visibility: hidden;
                        }

                        .event-content {
                            display: table-cell;
                            width: inherit;
                            height: inherit;
                            text-align: center;
                            vertical-align: middle;
                            padding: 15px;
                            @extend .transition-class;

                            span {
                                display: inline-block;
                                color: $text_light;
                                font-size: $font_18px;
                                font-weight: 700;
                                text-transform: uppercase;
                                line-height: 1;

                                &:first-child {
                                    line-height: 1.2;
                                }

                                &:last-child {
                                    display: none;
                                    padding: 7px 20px;
                                    border: 2px solid $text_light;

                                    &:before {
                                        content: '+';
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        .event-content {
                            background-color: rgba(0, 0, 0, 0.7);
                        }
                    }

                    &[href]:hover {
                        .event-content {
                            span:first-child {
                                display: none;
                            }

                            span:last-child {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    /* SERVICES */
    .service-title {
        background: no-repeat center;
        background-size: cover;

        h1 {
            color: $text_light;
        }
    }

    .service-content {
        background-color: #f8f8f8;

        .service-icon {
            img {
                max-width: 160px;
                height: auto;
            }
        }

        h2 {
            text-align: left !important;
        }

        p, ul {
            margin-bottom: 2rem;
        }

        ul {
            padding-left: 1rem;

            > li {
                margin-bottom: 0;
            }

            &.highlight {
                list-style: none;
                margin-top: 32px;
                margin-left: 0;
                padding-left: 32px;

                > li {
                    position: relative;
                    margin-bottom: 1rem;
                    padding-left: 24px;
                    counter-increment: custom;

                    &:first-child {
                        counter-reset: custom;
                    }

                    &:before {
                        content: counter(custom) ' ';
                        position: absolute;
                        right: 100%;
                        top: -2px;
                        display: block;
                        width: 32px;
                        height: 32px;
                        border: 3px solid $main_color;
                        border-radius: 50%;
                        font-size: $font_18px;
                        font-weight: 700;
                        text-align: center;
                        line-height: 1.5;
                    }
                }
            }
        }

        .highlight {
            font-size: $font_20px;
            color: $main_color;
        }
    }

    .service-form {
        background-color: $text_light;
    }

    /* COMPANY */
    .company-slider-section {
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;

        .timeline-wrap {
            height: 470px;
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;
            padding-right: 40px;
            margin-right: -40px;
        }

        .page-main:first-child {
            position: relative;
            background-color: rgba(0,0,0,0.75);

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 100%;
                display: block;
                background-color: rgba(0,0,0,0.75);
                height: 100%;
                width: 99999px;
            }

             &:before {
                left: auto;
                right: 100%;
             }
        }

        .timeline{
            padding-bottom: 100px;

            h2 {
                text-transform: inherit;
                letter-spacing: 1px;
                font-weight: 500;
                font-size: $font_24px;
            }

            h2:first-child {
                width : 100%;
                text-align: center;
                background: none;

                strong {
                    display: inline-block;
                    border: 6px solid #fff;
                    padding: 12px 17px;
                    margin: 0 15px;
                }
            }

            &:before {
                top: 100px;
                bottom: 0;
                height: auto;
                background-color: #fff;
                width: 6px;
            }

            .timeline-item {
                margin-bottom: 0;
                background: none;
                border: 2px solid #fff;
                width: 30%;
                padding: 20px 28px 30px;
                left: 14%;

                &:after {
                    left: auto;
                    right: -99px;
                    background-color: $main_color;
                    top: 50%;
                    margin-top: -15px;
                }

                &:not(.inverted) {
                    float: right;
                    right: 50%;
                    margin-right: 79px;
                    left: auto;
                }

                &.inverted {
                    left: 50%;
                    margin-left: 84px;

                    &:after {
                        right: auto;
                        left: -99px;
                    }
                }

                h3 {
                    margin: 0 0 10px;
                    color : $main_color;
                }
                h4{
                    color: #fff;
                    line-height: 1;
                }

                p {
                    color: #fff;
                    font-weight: 300;
                    line-height: 1.6;
                }
            }
        }

        .timeline-nav {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;

            div {
                position: absolute;
                right: 20px;
                top: 0;
                bottom: 0;
                z-index: 99;
                height: 470px;
                display: table;
                z-index: 1;

                ul {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 0;
                    list-style: none;
                }

                li {
                    display: block;
                    margin-bottom: 7px;

                    a {
                        width: 12px;
                        height: 12px;
                        display: block;
                        border-radius: 50%;
                        border: 2px solid $main_color;
                    }

                    &.active {
                        a {
                            background-color: $main_color;
                        }
                    }
                }
            }
        }
    }

    .company-nav {
        text-align: center;
        border-bottom: 2px solid $line_color;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                padding: 0;
                margin-bottom: 0;

                a {
                    font-size: $font_20px;
                    color: #000;
                    position: relative;
                    display: inline-block;
                    padding: 15px 32px;

                    &:hover {
                        color: #000;

                        &:after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -1px;
                            height: 3px;
                            background-color: $main_color;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .company-about {
        .company-content {
            margin-bottom: 30px;

            h2 {
                text-align: left !important;
            }
        }

        .company-comment {
            text-align: center;

            > div {
                background-color: $text_night;

                &:first-child {
                    padding-top: 30px;
                }

                &:last-child {
                    padding-bottom: 30px;
                }
            }

            .image-wrapper {
                margin-bottom: 15px;
                
                img {
                    border-radius: 50%;
                    width: 105px;
                }
            }

            .user-name {
                color: $main_color;
                font-weight: 900;
                margin-bottom: 3px;
            }

            .user-title {
                color: $text_light;
                font-size: 12px;
                font-weight: 300;
            }

            .comment-text {
                padding: 0 15px;

                h3 {
                    color: $text_light;
                    font-size: $font_20px;
                    font-weight: 700;
                    text-transform: inherit;
                    letter-spacing: 1px;
                    margin: 0;
                    padding: 28px 0 5px;
                }

                p {
                    color: $text_light;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.5;
                }
            }
        }
    }

    .company-ambassador {
        ul.ambassador-list {
            &:after {
                @extend .clear-float;
            }

            li.ambassador-item {
                float: left;
                display: block;
                width: 25%;
                margin-bottom: 15px;
                padding: 15px;
                text-align: center;

                .user-image {
                    max-width: 100%;
                    margin-bottom: 15px;
                }

                .user-title {
                    color: $main_color;
                    font-weight: 700;
                    line-height: 1;
                }

                .user-text {
                    font-size: 14px;
                }
            }
        }
    }

    /* FAQ */
    .cms-faq {
        .faq-list {
            li.faq-item:last-child {
                border-bottom: none;
            }
        }
    }

    /* CARRIER */
    .section-title {
        h1 + .subtitle {
            display: block;
            width: 100%;
            margin-top: 20px;
            color: $text_hover;
            text-align: center;
        }
    }

    .career-content, .career-form {
        h2 {
            text-align: left !important;
        }

        h3, h4 {
            color: $main_color;
            font-weight: 700;
        }

        h3 {
            margin-top: 0;
        }

        h4 {
            margin-top: 30px;
        }
    }

    .career-content {
        /* LISTING */
        .career-list {
            li.career-item {
                a {
                    font-size: $font_20px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1.8;
                }
            }
        }
    }

    .career-form {
        legend.legend, legend.legend + br, .messages {
            display: none;
        }

        form {
            margin-left: -15px;
            margin-right: -15px;

            .fieldset > .field {
                margin-bottom: 20px;

                > label, > .control {
                    float: none;
                    display: block;
                    width: 100%;
                }

                > label {
                    padding: 0 0 6px 0;
                    text-align: left;
                }
            }

            .actions-toolbar {
                margin-left: 15px;

                .primary {
                    button {
                        @extend .main-btn;

                        &:hover {
                            border-color: $main_color;
                        }
                    }
                }
            }
        }
    }
}

/* SERVICE-POSITION EDIT 02/10/2017*/
.service-content {

    ul.list-detail {

       li {
        font-weight: bold;

        }
    }
    .list-positionimage {
        width: 100%;
        margin-top: 30px;
        font-size: 0;

        img {
            width: 100%;
        }

        .positionmain {
            width: 46.2%;
            display: inline-block;
        }

        .sub-positionmain {
            width: 25%;
            display: inline-block;
            margin-left: 15px;

            img {
                width: 100%;
                max-width: 265px;
            }
        }
    }
}

.for-position {
    background: #FFF !important;
}

.sub-for-position{
    padding: 0;
}

.detail-position {
    background: #f8f7f8;

    h2 {
        text-align: left !important;
    }

    img {
        width: 100%;
    }

    .list-positiondetail {
        width: 100%;
        counter-reset: list-positiondetail-counter;

        dt {
            color: #de5a01;
            font-size: 20px;
            text-transform: capitalize;
            font-weight: normal;
            position: relative;
            padding-left: 60px;

            &:before {
                content: counter(list-positiondetail-counter);
                counter-increment: list-positiondetail-counter;
                position: absolute;
                left: 0;
                top: -7px;
                border: #de5a01 4px solid;
                border-radius: 20px;
                padding: 2px 10px;
            }
        }

        dd {
            margin-bottom: 30px;
            padding-left: 60px;
        }

    }

    p, ul {
        margin-bottom: 2rem;
    }

    .row-image {
        width: 100%;
        margin-top: 30px;

        img {
            vertical-align: bottom;;
        }

        .service-image-first {
            width: 47%;
            display: inline-block;
            margin-top: 110px;
            margin-right: 25px;
            margin-bottom: 30px;
        }

        .service-image-second {
            width: 47%;
            display: inline-block;
        }
    }
}

/* SERVICE-ENTREPOSAGE */
.for-entreposage {
    background: #FFF !important;

    .text-special{
        color: #de5a01;
    }

    .list-entreposage{
        width: 100%;
        counter-reset: list-entreposage-counter;
        margin-top: 70px;

        dt {
            color: #de5a01;
            font-size: 20px;
            text-transform: capitalize;
            font-weight: normal;
            position: relative;
            padding-left: 60px;

            &:before {
                content: counter(list-entreposage-counter);
                counter-increment: list-entreposage-counter;
                position: absolute;
                left: 0;
                top: -7px;
                border: #de5a01 4px solid;
                border-radius: 20px;
                padding: 2px 10px;
            }
        }

        dd {
            margin-bottom: 30px;
            padding-left: 60px;
            font-size: 36px;
            font-weight: 800;
            color: #de5a01;

            span {
                color: #000;
                font-size: 18px;
                font-weight: normal;
            }
        }
    }

    .sub-for-entreposag{
        padding: 0;
    }
}

.detail-entreposage {
    background: #f8f7f8;

    th {
        vertical-align: middle !important;
        padding: 10px 15px;
    }

    td {
        vertical-align: middle !important;
        padding: 15px !important;
    }

    .main-top {
        border-bottom: none !important;

        th {
            border-bottom: none !important;
            vertical-align: middle;
            text-transform: capitalize;
        }
    }

    tr {
        border-bottom: 2px solid #d1d1d1;
    }

    .for-top {
        text-align: center;

        .sub-topic {
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            text-transform: lowercase;
        }
    }

    .main-width {
        width: 40%;
        padding: 0;
        vertical-align: top;

        .main-topic {
            font-size: 34px;
            text-transform: uppercase;
            font-weight: 800;
        }
    }

    .for-price {
        width: 15%;
        background: #de5a01;
        text-align: center;
        font-size: 36px;
        font-weight: bold;
        color: #FFF;
    }

    .main-detail{
        background: #FFF;
    }

    .circle {
        background: #de5a01;
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        text-align: center;
        margin: 3px 45%;
    }

    .for-extra {
        background: #000;

        th {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            color: #FFF;
            padding: 15px;
        }
    }

    .sero-main{
        height: 50px;
    }

    .sum-price {
        text-align: center;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 20px;
    }

    p {
        margin: 0 !important;
    }
}