//
//  Lib -> Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal__background-color                         : $color-white !default;
$modal__box-shadow                               : 0 0 12px 2px rgba(0, 0, 0, 0.35) !default;

$modal-popup__indent-vertical                    : 5rem !default;
$modal-popup__padding                            : 3rem !default;
$modal-popup__width                              : 75% !default;
$modal-popup__z-index                            : $modal__z-index !default;

$modal-slide__first__indent-left                 : 14.8rem !default;
$modal-slide__indent-left                        : 4.5rem !default;
$modal-slide__padding                            : 2.6rem !default;
$modal-slide__z-index                            : $modal__z-index !default;

$modal-slide-header__padding-vertical            : 2.1rem !default;

$modal-popup-confirm__width                      : 50rem !default;

$modal-popup-image-box__border-color             : $color-gray80 !default;
$modal-popup-image-box__max-width                : 78rem !default;

$modal-popup-image-box-preview-image__max-height : 54rem !default;
$modal-popup-image-box-preview__max-width        : calc(#{$modal-popup-image-box-preview-image__max-height} + #{(2 * $indent__base)}) !default;

$color_main: #de5a01;
$color_light_grey: #d1d1d1;
$color_bg_td_grey: rgb(248, 247, 248);
$color_white: #fff;

$font_weight_bold: 600;

//
//  Utilities
//  ---------------------------------------------

@mixin lib-modal() {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease;

    &._show {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease;

        .modal-inner-wrap {
            transform: translate(0, 0);
        }
    }

    .modal-inner-wrap {
        @include lib-css(background-color, $modal__background-color);
        @include lib-css(box-shadow, $modal__box-shadow);
        opacity: 1;
        pointer-events: auto;
    }
}

@mixin lib-modal-slide() {
    @include lib-css(left, $modal-slide__first__indent-left);
    @include lib-css(z-index, $modal-slide__z-index);

    &._show {
        .modal-inner-wrap {
            transform: translateX(0);
        }
    }

    .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        width: auto;
    }
}

@mixin lib-modal-popup() {
    @include lib-css(z-index, $modal-popup__z-index);
    left: 0;
    overflow-y: auto;

    &._show {
        .modal-inner-wrap {
            transform: translate(-50%, 0);
        }
    }

    .modal-inner-wrap {
        @include lib-css(margin, $modal-popup__indent-vertical auto);
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        width: 100%;
        max-width: 620px;
        box-sizing: border-box;
        height: auto;
        left: 50%;
        position: absolute;
        right: 0;
        transform: translate(-50%, -200%);
        transition: transform 0.2s ease;

    }
}

body {
    &._has-modal {
        height: 100%;
        overflow: hidden;
        width: 100%;
    }
}

//  Modals overlay
.modals-overlay {
    @include lib-css(z-index, $overlay__z-index);
}

.modal-slide,
.modal-popup {
    @include lib-modal();
}

.modal-slide {
    @include lib-modal-slide();
    left: 62%;

    @media screen and (max-width: 1000px) {
        left: 0 !important;
    }

    &._inner-scroll {
        .modal-inner-wrap {
            overflow-y: visible;
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .modal-header,
        .modal-footer {
            @include lib-vendor-prefix-flex-grow(0);
            @include lib-vendor-prefix-flex-shrink(0);
        }

        .modal-content {
            overflow-y: auto;
        }

        .modal-footer {
            margin-top: auto;
        }
    }

    .modal-header,
    .modal-content,
    .modal-footer {
        @include lib-css(padding, 0 $modal-slide__padding $modal-slide__padding);
    }

    .modal-header {
        padding-bottom: 0;
        padding-top: 0;
    }
}

.modal-popup {
    @include lib-modal-popup();

    //  If applied, switching outer popup scroll to inner
    &._inner-scroll {
        overflow-y: visible;

        .ie11 &,
        .ie10 &,
        .ie9 & {
            overflow-y: auto;
        }

        .modal-inner-wrap {
            max-height: 90%;

            .ie11 &,
            .ie10 &,
            .ie9 & {
                max-height: none;
            }
        }

        .modal-content {
            overflow-y: auto;
        }
    }

    .modal-header,
    .modal-content,
    .modal-footer {
        @include lib-css(padding-left, $modal-popup__padding);
        @include lib-css(padding-right, $modal-popup__padding);
    }

    .modal-header,
    .modal-footer {
        @include lib-vendor-prefix-flex-grow(0);
        @include lib-vendor-prefix-flex-shrink(0);
    }

    .modal-header {
        @include lib-css(padding-bottom, $modal-popup__padding / 2.5);
        @include lib-css(padding-top, $modal-popup__padding);
    }

    .modal-footer {
        margin-top: auto;
        @include lib-css(padding-bottom, $modal-popup__padding);
        @include lib-css(padding-top, $modal-popup__padding);
    }

    .modal-footer-actions {
        text-align: left;
    }
}

//
//  Mobile
//  _____________________________________________

//  Mobile transform to modal-slide
@include max-screen($screen__m) {
    .modal-popup {
        &.modal-slide {
            @include lib-modal-slide();

            .modal-inner-wrap {
                margin: 0;
                max-height: none;
            }
        }
    }
}

//
//  Modal Sizechart
//  _____________________________________________

.modal-popup.modalSizeChart {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(28, 32, 40, 0.8);
    
    .modal-inner-wrap .modal-header button.action-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        z-index: 100;
        border-radius: 100%;
        cursor: pointer;
        background: #df5d24;

        @media screen and (max-width: $screen--m) {
            top: 40px;
            right: 40px;
        }

        &:before {
            color: #fff;
            font-size: 28px;
            font-weight: bold;
        }
    }

    .modal-inner-wrap {
        border-radius: 3px;

        -webkit-transform: translate(-50%);
        transform: translate(-50%);
        transition-duration: .2s;
        -webkit-transition-property: -webkit-transform, visibility;
        transition-property:         transform, visibility;
        transition-timing-function: ease;
    }

    @media screen and (max-width: $screen--m) {
        left: 0 !important;
    }

    .modal-inner-wrap {
        max-width: 900px;
        height: 650px;
        margin: 0;
        overflow: initial;

        @media screen and (max-width: $screen--m) {
            height: 100% !important;
        }

        .modal-header {
            .action-close {
                right: 25px;
                top: 25px;
                padding: 0;
            }
        }

        .modal-content {
            overflow-y: scroll;
            .description {
                text-align: center;
                margin-bottom: 25px;

                .title {
                    font-weight: $font_weight_bold;
                    margin-bottom: 30px;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: 700;
                }
            }

            .sizechart-tables {
                display: flex;
                flex-direction: column;
            }

            .main-fortable {
                overflow-x: scroll;

                .table {
                    margin-bottom: 45px;

                    tr {
                        border-bottom: 2px solid $color_light_grey;
                    }

                    .size-name {
                        background: $color_main;
                        text-align: center;
                        font-weight: $font_weight_bold;
                        color: $color_white;
                    }

                    tbody {
                        thead {
                            .main-width {
                                .main-topic {
                                    font-size: 34px;
                                    text-transform: uppercase;
                                    font-weight: $font_weight_bold;
                                }
                            }
                        }

                        tr {
                            td {
                                border: none;
                                vertical-align: middle;
                                padding: 10px;
                            }

                            .main-detail {
                                background-color: $color_bg_td_grey;
                            }
                        }
                    }
                }
            }
        }

        .modal-footer {
            display: none;
        }
    }
}

//
//  Modal Confirm
//  _____________________________________________

.modal-popup.confirm {
    @media screen and (max-width: $screen--m) {
        margin-left: 0 !important;
    }
    
    .modal-inner-wrap {
        max-width: 450px;
        left: 25%;

        @media screen and (max-width: $screen--m) {
            max-width: 85%;
            left: 50%;
        }

        .modal-header {
            padding-top: 2rem;
            padding-bottom: 0.5rem;
        }

        .modal-footer {
            margin-top: auto;
            padding-bottom: 2rem;
            padding-top: 1.5rem;

            button.action-primary.action-accept {
                padding: 9px 17px;
                font-size: 13px;
                text-transform: uppercase;
                color: #fff;
                background: #df5d24;
                border: none;

                &:hover {
                    text-decoration: none;
                    background-color: #b54a1b;
                    color: #fff;
                    border-color: #b54a1b;
                    border-width: 2px;
                }
            }

            button.action-secondary.action-dismiss {
                background-color: transparent;
                color: #df5d24;
                border: 1.5px solid #df5d24;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                    background-color: #b54a1b;
                    color: #fff;
                    border-color: #b54a1b;
                    border-width: 2px;
                }
            }
        }
    }
}