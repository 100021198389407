/* BRANDS */
.cms-page-view.cms-nos-marques,
.cms-page-view.cms-our-brands {
    .column.main section .row h1 {
        color: $c_black;
    }
}

.brands-content {

    .brands-filters {
        display: none;
    }

    .logos {

        .block-title {
            display: none;
        }

        .footer-logo-list ul li {
            width: calc(20% - 30px);
        }

        @media screen and (max-width: 480px) {

            .footer-logo-list {
                padding: 0;
                margin-top: -60px;

                ul {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        width: 50%;
                    }
                }
            }
        }
    }

    .brands-letters-list {
        display: flex;

        > li {
            flex-basis: 100%;
            text-align: center;
            text-transform: uppercase;

            &.selected {

                a {
                    font-weight: bold;
                }
            }

            a {
                color: $c_black;
                font-weight: 300;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .brand-item {

        &.hidden {
            display: none !important;
        }

        .brand-item-title {
            border-bottom: 1px solid $c_black;
        }

        .brand-item-list {
            column-count: 4;
            column-gap: 20px;
            padding: 20px 0;
        }

        .brand-item-content {
            margin-bottom: 20px;
        }

        .item {
            display: flex;
            justify-content: flex-start;

            &:hover {
                text-decoration: underline;
            }

            .count {
                color: $c_black;
                font-weight: 300;
            }

            .label {
                color: $c_black;
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: 960px) {

        .brands-letters {
            display: none;
        }

        .brand-item {
            margin-bottom: 20px;

            &.shown {

                .brand-item-title {

                    &:after {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }

                .brand-item-list {
                    display: block;
                }
            }

            .brand-item-title {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    width: 24px;
                    height: 24px;
                    top: 50%;
                    transform: translateY(-50%) rotate(0);
                    background-image: url('../images/sort-down.png');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 12px 12px;
                }
            }

            .brand-item-list {
                display: none;
            }
        }
    }

    @media screen and (max-width: 680px) {

        .brand-item {

            .brand-item-list {
                column-count: 3;
            }
        }
    }

    @media screen and (max-width: 480px) {

        .brand-item {

            .brand-item-list {
                column-count: 2;
            }
        }
    }

    @media screen and (max-width: 480px) {

        .brand-item {

            .brand-item-list {
                column-count: 1;
            }
        }
    }
}