.blog-index-index {
  nav {
    &.category-top-nav {
      .filter-container, .filter-container-mobile {
        .category-link {
          &.selected {
            position: relative;
            font-weight: 700;
            color: #df5d24;

            &::after {
              content: '';
              width: 70%;
              height: 7px;
              background: #df5d24;
              position: absolute;
              bottom: -35px;
              left: 15px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .blog-index-index {
    .filter-container-mobile {
      .category-link {
        &.selected {
          background-color: #FCEFE9;
          border: unset;

          &::before {
            display: none;
          }

          &::after {
            content: '\00d7';
            color: #df5d24;
            margin-left: .5rem;
          }
        }
      }
    }
  } 
}
