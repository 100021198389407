$home-container: 1320px;
$section-spacing: 80px;
$section-spacing--mobile: 80px;

@mixin flex-banner-positions {
    // Default to center center
    align-items: center;
    justify-content: center;
    text-align: center;

    &--top-left {
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
    }

    &--center-left {
        align-items: center;
        justify-content: flex-start;
        text-align: left;
    }

    &--bottom-left {
        align-items: flex-end;
        justify-content: flex-start;
        text-align: left;
    }

    &--top-center {
        align-items: flex-start;
        justify-content: center;
        text-align: center;
    }

    &--bottom-center {
        align-items: flex-end;
        justify-content: center;
        text-align: center;
    }

    &--top-right {
        align-items: flex-start;
        justify-content: flex-end;
        text-align: right;
    }

    &--center-right {
        align-items: center;
        justify-content: flex-end;
        text-align: right;
    }

    &--bottom-right {
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;
    }

    @media screen and (max-width: $screen__m) {

        &--top-left {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--center-left {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--bottom-left {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--top-center {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--bottom-center {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--top-right {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--center-right {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }

        &--bottom-right {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
        }
    }
}

@mixin mobile-banner-image {

    img.mobile {
        display: none;
    }

    @media screen and (max-width: $screen__m) {
        position: relative;
        background-image: none !important;
        min-height: inherit;
        align-items: flex-start;

        img.mobile {
            display: block;
            width: 100%;
            height: auto;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($c-black, 0.5);
        }

        .content {
            z-index: 1;
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 30px;

            .text {
                span{
                    color: $c-white !important;
                } 
            }
        }
    }
}

.cms-home {

    // Temporary adjustment
    .navigation.ms-megamenu .ms-topmenu .ms-label {
        font-size: 18px;
    }

    .text {
        display: inline-block;

        .subtitle {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 24px;
            text-transform: none;
            font-weight: 500;
        }

        .title {
            margin: 10px 0 30px 0;
            font-size: 38px;
            font-weight: bold;
            text-transform: none;
            line-height: 1;
        }

        a {
            font-size: 13px;
        }
    }

    .home {

        > div:last-of-type {
            margin-bottom: 0 !important;
        }
    }

    @media screen and (max-width: $screen__m) {

        .text {

            .subtitle {

                br {
                    content: ' ';
                }

                br:after {
                    content: ' ';
                }
            }

            .title {
                margin: 10px 0 20px 0;
                font-size: 22px;

                br {
                    content: ' ';
                }

                br:after {
                    content: ' ';
                }
            }
        }
    }
}

.home__actions {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__slider-banner {

    .slide {
        padding: 30px;
        height: 420px;
        min-height: 420px;
        background-position: center center;
        background-size: cover;
        display: flex;
        align-items: stretch;
        @include mobile-banner-image();

        @media only screen and (min-width: 768px) {
            height: auto;
            min-height: 480px;
        }

        @media only screen and (min-width: 1400px) {
            height: auto;
            min-height: 540px;
        }
    }

    .content {
        width: 100%;
        max-width: 984px;
        margin: 0 auto;
        padding: 75px 0;
        display: flex;
        @include flex-banner-positions();
    }

    &:not(.owl-carousel) {

        .slide {
            display: none;

            &:first-of-type {
                display: flex;
            }
        }
    }

    &.owl-theme {

        .owl-dots {
            width: 100%;
            position: absolute;
            bottom: 0;
            padding: 25px 0;

            .owl-dot {

                span {
                    width: 150px;
                    height: 4px;
                    border-radius: 0;
                    background-color: $c_white;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        background-color: $c_primary;
                        animation-duration: 5s;
                        animation-timing-function: linear;
                        animation-iteration-count: 1;
                    }
                }

                &.active {

                    span {
                        position: relative;

                        &:after {
                            animation-name: sliderDotAnimation;
                        }
                    }
                }
            }
        }

        &:hover {

            .owl-dots {

                .owl-dot {

                    span {

                        &:after {
                            animation-play-state: paused;
                        }
                    }
                }
            }
        }
    }

    @keyframes sliderDotAnimation {

        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    @media screen and (max-width: $screen__m) {

        .slide {
            padding: 0;
        }

        &.owl-theme {

            .owl-dots {

                .owl-dot {

                    span {
                        width: 50px;
                        height: 4px;
                    }
                }
            }
        }
    }
}

.home__icon-text {
    max-width: $home-container;
    margin: $section-spacing auto;
    padding: 0 30px;
    display: flex;

    .block {
        flex-grow: 1;
        flex-basis: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        color: $c-text-dark;

        .icon {
            margin-right: 20px;
            flex-shrink: 0;
        }

        p {
            font-weight: bold;
            font-size: 15px;
            letter-spacing: 1px;
            text-transform: none;
        }
    }

    @media screen and (max-width: $screen__m) {
        padding: 0 15px;
        overflow-x: scroll;

        &:after {
            content: "abc";
            opacity: 0;
            display: block;
            width: 30px;
            height: 30px;
            flex-basis: 30px;
        }

        .block {
            min-width: 240px;
            margin: 15px;
            padding: 15px;
            border-radius: 4px;
            border: 1px solid $c_border-grey;

            &:last-of-type {
                margin-bottom: 15px !important;
            }
        }
    }
}

.home__multiple-banner {
    max-width: $home-container;
    display: flex;
    padding: 0 15px;
    justify-content: space-between;
    margin: $section-spacing auto;

    .banner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 100%;
        margin: 0 15px;
        background-position: center center;
        background-size: 100%;
        padding-top: 33%;
    }

    .text {
        text-align: center;
        padding: 45px;
    }

    @media screen and (max-width: $screen__m) {
        flex-direction: column;

        .banner {
            padding-top: 65%;
        }

        .banner + .banner {
            margin-top: 60px;
        }
    }
}

.home__single-banner {
    max-width: $home-container;
    margin: $section-spacing auto $section-spacing/4;
    padding: 0 30px;

    .banner {
        min-height: 630px;
        background-position: center center;
        background-size: cover;
        display: flex;
        align-items: stretch;
        @include mobile-banner-image();
    }

    .content {
        width: 100%;
        padding: 110px;
        display: flex;
        @include flex-banner-positions();
    }

    .text {
        color: $c_white;
    }

    @media screen and (max-width: $screen__m) {
        .banner {
            min-height: inherit;
        }
        
        img {
            display: block;
            width: 100%;
        }

        .content {
            z-index: 1;
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 30px;
        }
    }
}

.home__brands {
    max-width: $home-container;
    margin: $section-spacing/4 auto $section-spacing;
    padding: 0 30px;
    text-align: center;

    .brand {
        padding: 15px;
    }

    .owl-stage {
        display: flex;
        align-items: center;
    }

    &-slider {

        &:not(.owl-carousel) {
            display: flex;
            flex-wrap: wrap;
        }

        .brand {
            margin: 0 auto;
            max-width: 136px;
        }
    }

    .owl-dots {

        .owl-dot {

            span {
                width: 20px;
                height: 4px;
                border-radius: 0;
                background-color: $c_text-lighter;
                position: relative;
            }

            &:hover {

                span {
                    background-color: $c_text-light;
                }
            }

            &.active {

                span {
                    background-color: $c_black;
                }
            }
        }
    }
}

.home__blog {
    background-color: $c_bg-grey;
    padding: 60px 0 80px 0;
    margin: $section-spacing auto;

    &--container {
        max-width: $home-container;
        margin: 0 auto;
    }

    .title {
        font-weight: bold;
        font-size: 38px;
        margin-bottom: 40px;
    }
}

.home__services {
    max-width: $home-container;
    margin: $section-spacing auto;
    padding: 0 30px;
    display: flex;
    text-align: center;

    &--service {
        flex-basis: 100%;
        padding: 0 15px;
    }

    h3 {
        margin: 30px 0 15px 0;
        font-weight: bold;
        font-size: 24px;
    }

    p {
        margin-bottom: 40px;
    }

    @media screen and (max-width: $screen__m) {
        flex-direction: column;

        &--service {
            padding: 0;

            + .home__services--service {
                margin-top: 60px;
            }
        }

        p {
            margin-bottom: 20px;
        }
    }
}

.home__side-by-side {
    max-width: $home-container;
    margin: $section-spacing auto;
    padding: 0 30px;
    display: flex;
    align-items: stretch;

    .side {
        flex-basis: 100%;
    }

    .side--image {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        img {
            opacity: 0;
        }
    }

    .side--text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        background-color: $c_secondary-dark;
        text-align: center;
        color: $c_white;
    }

    @media screen and (max-width: $screen__m) {
        flex-direction: column;

        .side--text {
            order: -1;
            padding: 60px 15px;
        }
    }
}

.home__events {
    background-color: $c_bg-grey;
    padding: 60px 0 80px 0;
    margin: $section-spacing auto 0;

    &_container {
        max-width: $home-container;
        padding: 0 30px;
        margin: 0 auto;
    }

    .heading {
        text-align: center;

        h3 {
            margin-top: 0;
            font-weight: bold;
            font-size: 38px;
        }

        p {
            font-size: 18px;
        }
    }

    .events {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 50px;
        background-color: $c_white;
    }

    .event {
        display: flex;
        align-items: stretch;

        &--text,
        &--img {
            flex-basis: 50%;
        }
    }

    .event--img {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        img {
            opacity: 0;
        }
    }

    .event--text {
        padding: 40px;
        display: flex;
        flex-direction: column;

        .date {
            color: $c_text-light;
            position: relative;
            padding-left: 35px;

            &:before {
                content: url('../images/icons/calendar.svg');
                position: absolute;
                top: -3px;
                margin-right: 10px;
                left: 0;
            }
        }

        h4 {
            margin: 20px 0 10px 0;
            font-weight: bold;
            font-size: 18px;
            text-transform: none;
            line-height: 1.3;
        }

        a {
            margin-top: auto;
        }
    }

    @media screen and (max-width: $screen__l) {

        .events {
            grid-template-columns: 1fr;
            background-color: transparent;
        }

        .event {
            background-color: $c_white;
        }

        .event + .event {
            margin-top: 30px;
        }

        .event--img {
            order: -1;
            flex-basis: 25%;
        }

        .event--text {
            flex-basis: 75%;
        }
    }

    @media screen and (max-width: $screen__m) {

        .heading {

            h3 {
                font-size: 28px;
            }

            p {
                font-size: 16px;
            }
        }

        .event {
            flex-direction: column;
            text-align: center;
        }

        .event--text {
            padding: 20px;

            .date {
                padding-left: 0;

                &:before {
                    position: relative;
                    top: 6px;
                    margin-right: 10px;
                }
            }
        }
    }
}
