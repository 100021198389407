.content-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

.page-wrapper > * > .block-static-block.widget, .block-cms-link.widget {
    margin-top: 0;
    margin-bottom: 0;
}

.page-layout-1column.cms-index-index,
.page-layout-1column.cms-page-view,
.catalogsearch-advanced-index,
.page-layout-2columns-left {
    #maincontent {
        max-width: 100%;
        padding: 0;
    }
}

.page-layout-2columns-left, .amshopby-index-index {
    .columns {
        @extend .content-wrapper;
        padding: 0 20px;

        .column.main {
            width: 75%;
        }

        .sidebar-additional {
            width: 25%;
        }
    }
}

.catalogsearch-advanced-index {
    .columns {
        @extend .content-wrapper;
        padding: 0 20px;
    }
}

.catalog-category-view, .ambrand-index-index {
    @media screen and (max-width: $screen--s) {
        display: block;

        .page-main {
            display: flex;
            flex-direction: column;

            > .page.messages {
                order: 1;
            }

            > .breadcrumbs {
                order: 2;
            }

            > .category-view {
                order: 3;
            }

            > .columns {
                order: 4;
            }

            > .category-description {
                order: 5;
            }
        }
    }
}

.catalog-category-view.page-layout-2columns-left,
.ambrand-index-index.page-layout-2columns-left,
.catalogsearch-result-index {

    .footer.content .footer__contact {
        background-color: $c_bg-grey;

        .footer__contact__container {
            padding-top: 100px;
        }
    }

    .sorter-label {
        min-width: 100px;
        text-align: right;
        margin-right: 4px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;

        &:after {
            content: ':';
        }
    }

    .sorter-mobile,
    .mobile-filter-trigger {
        display: none;
    }

    .am_shopby_apply_filters {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        max-width: 320px;
        transition: opacity 0.48s ease-in-out;
        pointer-events: none;
        opacity: 0;

        .am-items {
            display: none;
        }

        .am-show-button {
            background-color: $c_white;
            padding: 25px 20px;
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;

            a {
                @extend .q_b-primary--border;
                margin-right: 20px;
            }

            button {
                @extend .q_b-primary;
            }
        }
    }

    .page-main {
        background-color: $c_bg-grey;
    }

    .category-image {
        display: flex;
        align-items: center;
        background-position: center center;
        background-size: cover;

        &.non-background {
            height: 200px;

            @media screen and (max-width: $screen--s) {
                height: 0;
            }
        }

        .category-title {
            position: absolute;
            left: 10%;
            margin: 0 auto;
            max-width: 1320px;
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;

            h1 {
                font-size: 38px;
                line-height: 1.21;
                letter-spacing: 1px;
                font-weight: 700;
            }
        }

        @media screen and (max-width: $screen--s) {
            text-align: left;
            height: 0;

            img {
                display: none;
            }

            .category-title {
                max-width: 100%;
                text-align: right;
                left: 0;
                margin-top: 30px;
                padding: 0 10px;
                pointer-events: none;

                h1 {
                    font-size: 18px;
                }
            }
        }

        @media not all and (min-resolution:.001dpcm) {
             @supports (-webkit-appearance:none) {
                  .category-title {
                    h1 {
                        margin-top: 40px;
                    }
                  }
             }
        }

        @media screen and (max-width: $screen--s) and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
            .category-title {
                margin-top: 60px;
            }
        }
    }

    .sidebar-main {
        flex-grow: 0;
        flex-basis: 0;

        .sorter-mobile {
            display: block;

            .sorter-label {
                display: block;
                text-align: left;
            }

            &__options {
                display: flex;
                flex-direction: column;
                margin-top: 18px;

                .option {
                    display: inline-block;
                    position: relative;
                    font-size: 14px;
                    padding-bottom: 8px;
                }

                span {
                    display: block;
                    width: 100%;
                    padding-left: 24px;
                }

                input {
                    width: 21px;
                    height: 18px;
                    position: absolute;
                    left: 0;
                    top: 1px;

                    &:before {
                        width: 21px;
                        height: 18px;
                        -webkit-background-size: 18px;
                        background-size: 18px;
                    }
                }
            }
        }

        @media screen and (max-width: $screen--l) {
            min-width: 0;
            flex-grow: 1;
            flex-basis: 100%;
        }

        .am-show-form {
            display: inline-block;
            padding: 0;
        }

        .filter-current {
            .filter-current-subtitle {
                padding-right: 20px;
                margin: 0 0 18px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.1;
            }

            .items {
                > .item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .filter-label {
                        margin-right: 6px;
                        text-transform: lowercase;

                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }

                    .filter-value-wrapper {
                        padding: 4px 24px 4px 9px;
                        color: $c_white;
                        background-color: $c_black;
                        border-radius: 4px;
                        display: flex;
                        font-size: 12px;
                        position: relative;

                        &.am-item-removed {
                            text-decoration: none;
                        }

                        .action.remove {
                            font-size: 0;

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                right: 5px;
                                top: 50%;
                                transform: translateY(-50%);
                                background-image: url('../images/icons/close.svg');
                                -webkit-background-size: 10px;
                                background-size: 10px;
                                background-repeat: no-repeat;
                                width: 10px;
                                height: 10px;
                            }
                        }
                    }
                }
            }
        }

        .filter-actions {

            > a.action.clear {
                display: block;
                color: #8c96a9;
                text-align: left;
                text-decoration: underline;
                margin-top: 10px;
            }
        }

        .filter-options {

            &-title {
                position: relative;
                padding-right: 20px;
                cursor: pointer;
                padding-top: 18px;
                margin: 0 0 18px;
                text-transform: uppercase;
                font-size: 14px;

                &:after {
                    content: '';
                    position: absolute;
                    right: 10px;
                    top: 18px;
                    background-image: url('../images/icons/arrow-down.svg');
                    -webkit-background-size: 16px;
                    background-size: 16px;
                    width: 16px;
                    height: 16px;
                    background-repeat: no-repeat;
                    transition: transform .24s ease-in-out;
                    transform: rotate(180deg);
                }

                &.opened {

                    &:after {
                        transform: rotate(0deg);
                    }
                }
            }

            &-content {
                height: 0;
                overflow: hidden;
                transition: height 0.24s ease-in-out;
                margin-bottom: 0;
                border-bottom: 1px solid $c_text-lighter;

                .am-show-more {
                    margin-top: 0;
                    margin-bottom: 30px;
                    border: none;
                    padding: 0;
                    background-color: transparent;
                    font-size: 14px;
                    color: $c_grey;
                    text-decoration: underline;
                    text-underline-offset: 0.18rem;
                    line-height: 50px;

                    &.-active {
                        &:before {
                            right: 16px;
                        }
                        &:after {
                            right: 10px;
                        }
                    }
                }

                > form {
                    margin-bottom: 8px;
                    display: inline-block;
                    width: 100%;

                    &[data-amshopby-filter="size"] {
                        width: auto;
                    }

                    > ol {
                        padding-top: 0;
                    }

                    @media screen and (max-width: $screen--l) {
                        width: 100%;

                        > ol {
                            width: 100%;

                            a {
                                display: block;
                                position: relative;
                                padding-right: 0;
                                font-weight: normal;

                                input {
                                    position: absolute;
                                    right: 0;
                                    top: 1px;
                                }
                            }
                        }
                    }
                }

                .swatch-attribute {
                    margin-bottom: 0;

                    &-options {
                        margin-top: 12px;
                    }
                }

                .swatch-attribute.size {


                    .swatch-option.text {

                        &:hover {
                            border: 1px solid $c_black;
                        }
                    }
                }

                .swatch-option.text {
                    background-color: #fff;
                    border-radius: 4px;
                    height: 40px;
                    line-height: 30px;
                    min-width: 67px;
                    max-width: 67px;
                }

                .swatch-option.image,
                .swatch-option.color {
                    width: 28px;
                    height: 28px;
                    min-width: 28px;
                    border-radius: 50%;
                    margin-right: 18px;
                    margin-bottom: 12px;

                    &:hover,
                    &.selected {
                        outline: none;
                        border: 2px solid $c_black;
                    }
                }

                .amshopby-input-wrapper .amshopby-currency {
                    display: none !important;
                }

                .items.am-filter-items-attr_price,
                .items.am-filter-items-price {
                    padding-top: 0;

                    .amshopby-slider-container {
                        margin-bottom: 26px;
                    }

                    .ui-slider,
                    .ui-slider-range {
                        height: 4px;
                    }

                    .ui-slider-range,
                    .ui-slider-handle {
                        background: $c_primary;
                    }

                    .ui-slider {
                        background-color: $c_text-lighter;
                    }

                    .ui-slider-handle {
                        width: 16px;
                        height: 16px;
                    }
                }

                [data-am-js="fromto-widget"] {
                    padding-bottom: 30px;

                    .range {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .am-filter-price {
                        font-size: 14px;
                        min-width: 66px;
                        padding: 12px;
                        text-align: center;
                    }

                    .am-filter-go {
                        @extend .q_b-primary--border;
                        padding: 9px 0 !important;
                    }
                }

                .items {
                    padding-top: 12px;

                    .item {
                        margin-bottom: 16px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: row-reverse;

                        a {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            padding-left: 5px;
                        }

                        .label {
                            color: $c-black;
                            max-width: calc(100% - 40px);
                            display: inline-block;
                        }

                        .count {
                            float: right;
                            background: #fff;
                            border-radius: 24px;
                            width: 34px;
                            line-height: 24px;
                            text-align: center;
                            font-size: 11px;
                            display: flex;
                            justify-content: center;

                            &:before,
                            &:after{
                                display: none;
                            }
                        }

                        &:last-child {
                            margin-bottom: 30px;
                        }

                        input[type="radio"],
                        input[type="checkbox"] {
                            width: 21px;
                            height: 18px;

                            &:before {
                                width: 21px;
                                height: 18px;
                                -webkit-background-size: 17px;
                                background-size: 17px;
                            }
                        }

                        a {

                            &:hover {
                                color: $c_black;
                                opacity: .65;
                            }
                        }
                    }

                    &.am-filter-items-attr_category_ids,
                    &.am-filter-items-category_ids {

                        .item {

                            > a {
                                padding-left: 0;
                            }

                            input[type="radio"] {
                                display: none;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: $screen--l) {

                &-content {

                    .items {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .columns {
        display: flex;
        flex-wrap: nowrap;
        max-width: 1320px;
        padding-top: 40px;
        padding-bottom: 100px;
        padding-left: 30px;
        padding-right: 30px;

        .sidebar-main {
            width: auto;
            min-width: 260px;

            .block.filter {
                position: relative;
                padding-top: 40px;
            }

            .filter-title {
                position: absolute;
                left: 0;
                top: 0;
                white-space: nowrap;
                min-width: 400px;
                max-width: 600px;

                h2 {
                    display: flex;
                    align-items: center;
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: 20px;
                    text-transform: uppercase;
                    font-weight: 700;
                }
            }

            .filter-content {

                > .filter-subtitle {
                    display: none;
                }
            }
        }

        .toolbar {

            // top toolbar
            &:first-of-type {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                @media screen and (max-width: $screen--s) {
                    width: 100%;
                    margin-bottom: 10px;
                    padding: 0 20px 0 10px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr min-content;
                    grid-template-areas:
                    "filter ."
                    "amount limiter";
                }

                .toolbar-amount {
                    margin-bottom: 0;
                    margin-right: auto;

                    @media screen and (max-width: $screen--s) {
                        grid-area: amount;
                    }
                }

                .field.limiter {
                    @media screen and (max-width: $screen--s) {
                        grid-area: limiter;
                        text-align: right;
                    }

                    .limiter-text {
                        display: none;
                    }
                    .label, .control {
                        display: inline-block;
                    }

                    .limiter-options {
                        margin-left: 12px;
                        padding: 10px 15px;
                        font-size: 14px;
                        border-radius: 3px;
                        background-position: calc(100% - 6px) 9px !important;
                    }
                }

                .pages {
                    display: none;
                }

                .control-select {
                    display: flex;
                    align-items: center;
                }

                .sorter {
                    display: flex;
                    align-items: center;

                    .control-select {
                        .sorter-label {
                            margin-right: 10px;
                            min-width: 75px;
                            width: -webkit-fill-available;
                            text-transform: inherit;
                            font-weight: inherit;
                            font-size: 14px;

                            &:after {
                                display: none;
                            }
                        }

                        .sorter-options {
                            height: 36px;
                            width: auto;
                            padding: 8px 25px 8px 12px;
                            font-size: 14px;
                            border-radius: 3px;
                            background-size: 16px 16px;
                            background-repeat: no-repeat;
                            background-position: calc(100% - 6px) 9px !important;
                            color: $c_text-medium;
                        }
                    }
                }
            }

            // bottom toolbar
            &:last-of-type {
                display: flex;
                justify-content: center;

                .toolbar-amount, .field.limiter {
                    display: none;
                }

                .sorter {
                    display: none;
                }

                .pages {
                    display: flex;
                    margin-top: 15px;

                    .items {
                        display: flex;
                        font-weight: 400;
                    }

                    .item.pages-item-previous,
                    .item.pages-item-next {
                        background-color: transparent;

                        .previous,
                        .next {
                            margin-left: 6px;
                            width: 16px;
                            height: 36px;
                            border: none;

                            &:before {
                                content: '';
                                background-repeat: no-repeat;
                                width: 16px;
                                height: 36px;
                                background-size: 16px 16px;
                                background-position: center center;
                            }
                        }

                        .previous {

                            &:before {
                                background-image: url('../images/next.svg');
                                transform: rotate(-180deg);
                            }
                        }

                        .next {

                            &:before {
                                background-image: url('../images/next.svg');
                            }
                        }
                    }

                    .item {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-color: $c_white;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .page {
                            color: $c_black;
                            font-size: 14px;
                        }

                        &.current {
                            background-color: $c_primary;

                            .page {
                                color: $c_white;
                            }
                        }
                    }

                    .item + .item {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .category-description {
        width: 100%;
        background-color: $c_white;
        margin: 0 auto;
        max-width: 1320px;
        padding: 50px 30px;
        text-align: center;

        p {
            max-width: 710px;
            margin: 0 auto 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @media screen and (max-width: $screen--s) {
            margin: 0 15px;
            width: auto;
        }
    }

    @media screen and (max-width: $screen--l) {

        .sorter-mobile,
        .toolbar-products:first-of-type .mobile-filter-trigger {
            display: block;
        }

        .mobile-filter-trigger {
            padding-top: 20px;
            padding-bottom: 12px;
            padding-right: 20px;

            a {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: $c_black;
                text-transform: uppercase;
                letter-spacing: 0.78px;
                font-weight: bold;

                svg {
                    margin-left: 16px;
                }
            }
        }

        .columns {
            flex-direction: column;
            padding: 0;
            width: 100%;

            .sidebar-main {
                display: none;
            }

            .column.main {
                width: 100%;
                flex-basis: 100%;
                padding-bottom: 60px !important;
            }
        }

        .toolbar-products {

            .sorter {
                @include abs-visually-hidden();
            }
        }

        .sidebar-main {
            width: 100%;
            padding-right: 0 !important;

            .filter-title,
            .filter-subtitle {
                display: none;
            }

            .modal__wrapper {
                position: relative;
                max-width: 100%;
                padding: 50px 20px 0;
                transform: none;
                border-radius: 0;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                min-height: 100vh;
                background-color: $c_bg-grey;
                padding-bottom: 120px;

                .modal__close {
                    top: 0;
                    right: 0;
                    border-radius: 0;

                    svg path {
                        fill: #fff;
                    }
                }
            }
        }
    }

    &.modal-opened .am_shopby_apply_filters {
        display: flex;
        opacity: 1;
        pointer-events: all;
    }

    &.page-with-filter--filtered {

        .columns {

            .filter-title h2 {
                margin-bottom: 31px;
            }
        }
    }
}

.sorter-mobile {
    margin-bottom: 20px;

    .option {
        margin-bottom: 10px;
    }
}

.breadcrumbs {
    max-width: none;
    margin: 0;
    display: flex;
    justify-content: center;
    background-color: $c_bg-grey;
    border-bottom: 1px solid $c_white;
    padding-bottom: 11px;
    padding-top: 12px;

    @media screen and (max-width: $screen--s) {
        display: block;
    }

    .items {
        display: flex;

        @media screen and (max-width: $screen--s) {
            flex-wrap: wrap;
        }

        .item {
            display: flex;

            a,
            strong {
                color: $c_text-medium;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 0.6px;
            }

            &:after {
                content: '/';
                font-size: 12px;
                font-family: 'lato', sans-serif;
                vertical-align: initial;
                overflow: initial;
                font-weight: 700;
                margin-left: 12px;
                margin-right: 12px;

                @media screen and (max-width: $screen__s) {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }

            &:last-of-type {

                &:after {
                    content: none;
                }
            }

            &.product {
                text-transform: uppercase;
            }
        }
    }
}

.products.list,
.widget-product-grid,
.widget-new-grid,
.widget-viewed-grid{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    & + .toolbar-products {

        .mobile-filter-trigger {
            display: none !important;
        }

        .toolbar-sorter {
            display: none;
        }
    }

    .item.product,
    .product-item {
        position: relative;
        width: 33.33%;
        padding: 8px;
        list-style-type: none;
        box-sizing: border-box;

        .gift-idea-tag, .last-chance-tag {
            position: absolute;
            top: 5px;
            left: 15px;
            z-index: 2;
            display: block;
            width: 65px;
            height: 65px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .last-chance-tag {
            top: 10px;

            &.fr {
                background-image: url('/media/wysiwyg/product-tag/last_chance_fr.png');
            }

            &.en {
                background-image: url('/media/wysiwyg/product-tag/last_chance_en.png');
            }
        }

        .gift-idea-tag {
            &.fr {
                background-image: url('/media/wysiwyg/product-tag/gift_idea_fr.png');
            }

            &.en {
                background-image: url('/media/wysiwyg/product-tag/gift_idea_en.png');
            }
        }

        .action.towishlist {
            display: none;
        }

        .product-label-tag {
            position: absolute;
            right: 4px;
            top: 30px;
            background-color: $c_black;
            padding: 4px 6px;
            z-index: 10;
            min-width: 46px;
            text-align: center;
            border-radius: 2px;
            font-weight: 600;

            &--discount {
                background-color: $c_primary;
            }

            &__best-seller {
                background-color: $c_black;
            }

            &__new {
                background-color: $c_secondary;
            }

            span {
                color: $c_white;
                font-size: 14px;
            }
        }

        .product-label-tag + .product-label-tag {
            top: 64px;
        }

        .product-item-info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: $c_white;
        }

        .product-item-photo {
            position: relative;
            display: block;
            width: 100%;
            background-color: $c_white;

            .product-image-container {
                width: 100% !important;
            }

            .product-image-wrapper {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img.product-image-photo {
                    width: 85%;
                }
            }

            .owl-stage-outer {
                height: 100%;

                .owl-stage {
                    height: 100%;

                    .owl-item {
                        height: 100%;

                        .product-media__image.chocolat-image {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;

                            img {
                                object-fit: contain;
                                width: 85%;
                            }
                        }
                    }
                }
            }

            .owl-next, .owl-prev {
                visibility: hidden;
                position: absolute;
                top: 50%;
                margin: 0;
                padding: 1rem 0.7rem 0.7rem 1rem !important;

                &:hover {
                    background: none;
                }

                @media screen and (max-width: $screen--s) {
                    display: none;
                }
            }

            .owl-next {
                border-radius: 3px 0 0 3px;
                right: 0;
            }

            .owl-prev {
                border-radius: 0 3px 3px 0;
                left: 0;
            }

            .owl-dots {
                display: none;

                @media screen and (max-width: $screen--s) {
                    display: block;
                    position: absolute;
                    width: 100%;
                    bottom: -2px;
                }

                .owl-dot {
                    span {
                        width: 56px;
                        height: 3px;
                        margin: 0;
                        background: #DEDFE2;
                        border-radius: 0;
                    }

                    &.active {
                        span {
                            background: #000;
                        }
                    }
                }
            }

            &:hover {
                .owl-next, .owl-prev {
                    visibility: visible;
                    background-color: #f9f9fb !important;
                }
            }

            &.ad-simple, &.ad-popup {
                padding: 0 !important;
                height: 100%;

                .product-image-container {
                    height: 100%;
                    padding: 0 !important;

                    .product-image-wrapper {
                        width: 100%;

                        img.product-image-photo {
                            object-fit: cover;
                            width: 100% !important;
                            height: 100%;
                        }
                    }
                }
            }

            &.ad-simple, &.ad-popup, &.ad-video, &.ad-iframe  {

                .product-image-photo, iframe, video {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }

                @media screen and (min-width: $screen--s) {
                    padding-bottom: 108px;
                    position: unset;

                    .product-image-container {
                        padding: 8px;
                    }
                }
            }
        }

        .product-item-name {
            display: flex;
            flex-direction: column;

            .product-item-brand {
                text-transform: uppercase;
                color: $c_black;
                min-height: 20px;
                font-weight: 600;
            }
        }

        .product-item-details {
            display: flex;
            flex-direction: column;

            .product-item-brand {
                text-transform: uppercase;
                color: $c_black;
                min-height: 20px;
                font-weight: 600;
            }
        }

        .product-item-details {
            padding: 0 12px 20px 20px;
            background-color: $c_white;
            min-height: 108px;

            .name-price-wrapper {
                display: flex;
                justify-content: space-between;
                min-height: 40px;
            }

            .details-widget {
                flex-wrap: nowrap;
            }

            &__swatches {
                width: 100%;
                min-height: 36px;

                .swatch-attribute-options {
                    display: flex;
                    justify-content: center;
                    margin-top: 0;
                    margin-bottom: 20px;
                    @media screen and (max-width: $screen--m) {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                    .swatch-option {
                        &.selected {
                            outline: none;
                            pointer-events: none;
                        }

                        &.color,
                        &.image {
                            position: relative;
                            margin: 0 13px 0 0;
                            min-width: 16px;
                            border-radius: 50%;
                            width: 16px !important;
                            height: 16px !important;

                            &:hover,
                            &.selected {
                                outline: none;
                                border: 1px solid $c_white;

                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate3d(-50%, -50%, 1px);
                                    width: 12px;
                                    height: 12px;
                                    border: 2px solid $c_white;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-item-price {
            text-align: right;

            .price-box {
                display: flex;
                flex-direction: column-reverse;
                font-weight: 600;
            }

            .special-price {
                color: $c_primary;
            }

            .old-price {
                text-decoration: line-through;
            }

            .price-label {
                display: none;
            }
        }


        .product-item-link {
            color: $c_text-medium;
        }
    }

    @media screen and (max-width: $screen--m) {
        padding-left: 0;
        padding-right: 0;

        .item.product {
            width: 50% !important;
            margin: 0 !important;
        }
    }

    @media screen and (max-width: $screen--s) {

        .item.product {
            width: 100% !important;
            margin: 0 !important;
        }
    }
}

.widget.block-new-products,
.widget.block-products-list {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $c_bg-grey;
    margin-bottom: 0;

    .block-products-list__container {
        position: relative;
        max-width: 1320px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;

        @media screen and (max-width: $screen--s) {
            padding-left: 12px;
            padding-right: 12px;
        }

        &:before {
            //content: '';
            position: absolute;
            background-color: $c_bg-grey;
            right: calc(100% - 26px);
            height: 100%;
            width: 100vw;
            z-index: 10;
        }

        .owl-stage-outer {
            @media screen and (max-width: $screen--s) {
                .product-item {
                    padding: 0;
                }
            }
        }

        .products-grid .product-items {
            margin-left: 0;
            margin-bottom: 0 !important;

            .product-item {
                position: relative;

                .gift-idea-tag, .last-chance-tag {
                    position: absolute;
                    top: 5px;
                    left: 15px;
                    z-index: 2;
                    display: block;
                    width: 65px;
                    height: 65px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .last-chance-tag {
                    top: 10px;

                    &.fr {
                        background-image: url('/media/wysiwyg/product-tag/last_chance_fr.png');
                    }

                    &.en {
                        background-image: url('/media/wysiwyg/product-tag/last_chance_en.png');
                    }
                }

                .gift-idea-tag {
                    &.fr {
                        background-image: url('/media/wysiwyg/product-tag/gift_idea_fr.png');
                    }

                    &.en {
                        background-image: url('/media/wysiwyg/product-tag/gift_idea_en.png');
                    }
                }

                &-details {
                    padding: 10px;
                }

                &-brand {
                    @media screen and (max-width: $screen--s) {
                        display: none;
                    }
                }

                &-price {
                    text-align: left;
                    margin-top: 5px;
                }

                .name-price-wrapper {
                    @media screen and (max-width: $screen--s) {
                        flex-direction: column;
                    }
                }
            }
        }
    }

    .block-title {
        padding-left: 8px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 30px;
        letter-spacing: 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        @media screen and (max-width: $screen--s) {
            padding-left: 0;
            justify-content: center;
            width: 100%;
            font-size: 26px;
            text-align: center;
        }
    }

    .block-products-list__nav {
        margin-top: 16px;
        padding-right: 8px;

        button {
            border: none;
            padding: 0;
            line-height: 1;
            background-color: transparent;
            height: 24px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        button + button {
            margin-left: 40px;
        }

        @media screen and (max-width: $screen--s) {
            order: 3;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
        }
    }

    .block-content {
        width: 100%;
    }

    > .block-actions {
        text-align: center;
        margin-top: 20px;

        @media screen and (max-width: $screen--s) {
            margin-top: 30px;
        }
    }

    .widget-product-grid,
    .widget-viewed-grid{
        width: 100%;
    }

    .product-items {
        padding-left: 0 !important;
        margin-bottom: 20px !important;
    }

    .product-item {
        margin-bottom: 0 !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 8px !important;
    }
}

.widget.block-viewed-products-grid,
.block.related{
    background-color: $c_bg-grey;
    margin: 0;

    .page-main {
        padding: 80px 0 0 !important;
    }

    .block-title {
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 25px;
        color: $c_grey;
    }

    .block-content {
        padding-bottom: 30px;
    }

    .product-items {
        padding: 0;
        justify-content: space-between;
    }

    .product-item {
        margin-bottom: 0 !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 5px !important;
    }
}

.widget.block-viewed-products-grid {
    .product-item-details {
        min-height: 137px;

        .name-price-wrapper {
            display: block;

            > div {
                display: block;
                text-align: left;
            }

            .product-item-price {
                margin-top: 5px;
            }
        }
    }
}

.swatch-option-tooltip {

    .image {
        max-width: 100%;
    }
}

// product page

.catalog-product-view {
    padding-top: 0;

    .page-main {
        max-width: 1360px;

        @media screen and (min-width: $screen--s + 1) {
            padding-top: 0 !important;
        }
    }

    .breadcrumbs {
        margin-top: 120px !important;

        @media screen and (max-width: $screen--l) {
            margin-top: 60px !important;
            display: block;
        }

    }

    &.top-header-message-opened {
        .breadcrumbs {
            margin-top: 144px !important;

            @media screen and (max-width: $screen--l) {
                margin-top: 90px !important;
            }

            @media screen and (max-width: 410px) {
                margin-top: 106px !important;
            }
        }

        .page-main {
            padding-top: 0 !important;
        }
    }

    .footer.content .footer__contact {
        background-color: $c_bg-grey;
    }

    .column.main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .product-media {
            position: relative;
            width: calc(100% - 320px);
            max-width: 660px;
            margin-right: 80px;

            .gift-idea-tag, .last-chance-tag {
                position: absolute;
                top: 0;
                left: 11rem;
                z-index: 2;
                display: block;
                width: 100px;
                height: 100px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                @media screen and (max-width: $screen--m) {
                    left: 5px;
                    width: 65px;
                    height: 65px;
                }
            }

            .last-chance-tag {
                top: 10px;

                &.fr {
                    background-image: url('/media/wysiwyg/product-tag/last_chance_fr.png');
                }

                &.en {
                    background-image: url('/media/wysiwyg/product-tag/last_chance_en.png');
                }
            }

            .gift-idea-tag {
                &.fr {
                    background-image: url('/media/wysiwyg/product-tag/gift_idea_fr.png');
                }

                &.en {
                    background-image: url('/media/wysiwyg/product-tag/gift_idea_en.png');
                }
            }

            .owl-carousel {
                z-index: inherit;
            }

            .owl-stage-outer {
                position: relative;
                overflow: visible;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: #fff;
                    top: 0;
                    left: 100%;
                    width: 100vw;
                    height: 100%;
                }

                .owl-item {
                    transition: opacity .24s ease-in-out;
                }

                .owl-item:not(.active) {
                    opacity: .1;
                }
            }

            .owl-nav {
                display: flex;
                display: -ms-flexbox;
                justify-content: space-between;
                -ms-flex-pack: justify;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                margin: 0 0 12px;
                bottom: 0;

                .owl-prev,
                .owl-next {

                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            .owl-dots {
                display: inline-block;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                text-align: center;
                margin: 7px 0;
                bottom: 0;

                .owl-dot {

                    span {
                        background-color: $c_text-lighter;
                        width: 6px;
                        height: 6px;
                    }

                    &.active {

                        span {
                            background-color: $c_black;
                            width: 8px;
                            height: 8px;
                        }
                    }
                }
            }

            .gallery-placeholder {
                .fotorama {
                    overflow: inherit;

                    .fotorama__nav-wrap--vertical.fotorama__nav-wrap {
                        .fotorama__nav__shaft {
                            width: 100px;
                            margin: 0;
                        }
                    }

                    .fotorama__wrap {
                        .fotorama__stage:before,
                        .fotorama__nav:before,
                        .fotorama__stage:after,
                        .fotorama__nav:after {
                            display: none;
                        }
                        .fotorama__nav-wrap.fotorama__nav-wrap--vertical {
                            width: 100px;
                            display: flex !important;
                            align-items: center;
                            height: 100%;
                            .fotorama__nav.fotorama__nav--thumbs {
                                padding: 0;
                                margin: 0;
                                height: auto !important;
                                max-height: 100%;
                                &:before,
                                &:after {
                                    display: block !important;
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    width: 100%;
                                    height: 35px;
                                    background-color: #fff;
                                    z-index: 1;
                                    background-image: none;
                                }
                                &:before {
                                    top: 0;
                                }
                                &:after {
                                    bottom: 0;
                                }
                                .fotorama__thumb__arr {
                                    .fotorama__thumb--icon {
                                        background-image: url('../images/icons/arrow-left.svg');
                                        background-position: center;
                                        height: 24px;
                                        width: 13px;
                                    }
                                    &.fotorama__thumb__arr--right {
                                        .fotorama__thumb--icon {
                                            transform: rotate(270deg);
                                        }
                                    }
                                }

                                .fotorama__nav__shaft {
                                    &:before,
                                    &:after {
                                        display: block !important;
                                        content: '';
                                        width: 100%;
                                        height: 30px;
                                        background-color: #fff;
                                    }
                                    .fotorama__thumb-border {
                                        display: none;
                                    }
                                    .fotorama__nav__frame.fotorama__nav__frame--thumb {
                                        width: calc(100% - 2px) !important;
                                        height: 98px;
                                        padding: 0 !important;
                                        margin: 5px 0;
                                        box-sizing: border-box;
                                        border: 1px solid #ddd;
                                        &.fotorama__active {
                                            border: 1px solid #999;
                                        }
                                        &:not(.fotorama__active){
                                            position: relative;
                                            &:before {
                                                content: '';
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                z-index: 2;
                                                pointer-events: none;
                                                background-color: rgba(0,0,0,0.15);
                                            }
                                        }
                                    }
                                }
                                .fotorama__thumb__arr.fotorama__thumb__arr--left,
                                .fotorama__thumb__arr.fotorama__thumb__arr--right {
                                    width: 100%;
                                    z-index: 3;
                                }
                                .fotorama__thumb__arr.fotorama__thumb__arr--left {
                                    top: 0;
                                }
                                .fotorama__thumb__arr.fotorama__thumb__arr--right {
                                    bottom: 0;
                                }
                            }
                        }

                        .fotorama__stage {
                            @media screen and (min-width: $screen__m) {
                                transform: translateX(120px);
                            }
                            .fotorama__arr {
                                visibility: hidden;
                            }

                            .fotorama__stage__shaft {
                                max-width: 100% !important;
                                .fotorama__stage__frame {
                                    .fotorama__img {
                                        top: 50%;
                                    }
                                }
                            }
                        }

                        .fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
                            .fotorama__nav.fotorama__nav--dots {
                                .fotorama__nav__shaft {
                                    .fotorama__nav__frame--dot {
                                        width: 24px;

                                        .fotorama__dot {
                                            display: block;
                                            position: relative;
                                            top: 12px;
                                            width: 6px;
                                            height: 6px;
                                            margin: 0;
                                            background: rgb(222, 223, 226);
                                            border-radius: 100%;
                                            border-color: #fff;
                                        }

                                        &.fotorama__active {
                                            .fotorama__dot {
                                                background-color: #000;
                                                border-color: #000;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .copyright-img {
                color: #a19b9b;
                position: absolute;
                bottom: 10%;
                left: 35%;
                padding-left: 1rem;
                text-align: center;
                z-index: 9;
                width: 100%;
                padding-right: 1rem;
                font-size: 14px;
            }
        }

        .product-info-main {
            position: relative;
            width: 100%;
            max-width: 360px;
            padding-left: 10px;
            margin-top: 55px;
            padding-bottom: 80px;
            box-sizing: border-box;

            .product-brand {
                font-size: 12px;
                color: $c_Text-grey;
                text-transform: uppercase;
            }

            .page-title {
                text-transform: none;
                font-size: 22px;
                margin-bottom: 10px;
                margin-top: 7px;
                font-weight: 400;
            }

            .product-info-price {
                margin-bottom: 4px;

                .price-box {
                    margin-top: 0;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    gap: 20px;

                    .price {
                        margin-top: 0;
                        margin-bottom: 0;
                        font-family: 'Gotham', sans-serif;
                        font-size: 22px;
                        font-weight: 500;
                        line-height: 1.43;
                        letter-spacing: normal;
                        text-align: center;
                    }

                    .old-price {

                        .price {
                            color: rgba(140, 150, 169, 0.6);
                            text-decoration: line-through;
                            font-weight: 400;
                        }
                    }

                    .special-price {
                        margin-right: 14px;

                        .price {
                            color: $c_primary;
                            text-decoration: none;
                        }
                    }

                    .price-label {
                        display: none;
                    }
                }
            }

            .-splitit--text {
                font-size: 13px;
            }

            .-splitit--logo {
                img {
                    height: 25px !important;
                }
            }

            .splitit-product-block {
                padding: 0.5rem 0;
                margin-bottom: 20px;

                .-splitit--learn-more {
                    display: block;
                    text-decoration: underline;
                    color: #a77bca;
                    font-weight: 600;
                }
            }

            #paybright-widget-container {
                font-size: 13px;

                p {
                    display: inline-block;
                }

                span {
                    margin-left: 6px;
                    cursor: pointer;

                    @media screen and (max-width: $screen__m) {
                        margin-left: 4px;

                        svg {
                            width: 70px;
                        }
                    }

                }
            }

            .product-store {
                &__link {
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media screen and (max-width: $screen--s) {
                            height: 60px;
                            z-index: 10;
                            position: fixed;
                            bottom: 0;
                            right: 0;
                            width: 50%;
                            padding: 10px 15px;
                            font-size: 11px;
                            background: #1c2028;
                            border: 1.5px solid #1c2028;
                            border-width: 2px;
                        }
                    }

                    svg {
                        margin-right: 5px;

                        @media screen and (max-width: $screen--s) {
                            display: none;
                        }
                    }

                    span {
                        font-size: 13px;
                        text-decoration: none;
                        color: $c_black;
                        font-weight: 600;
                        position: relative;

                        @media screen and (max-width: $screen--s) {
                            font-size: 12px;
                            text-transform: uppercase;
                            color: #fff;
                            letter-spacing: 1px;
                        }

                        &:after {
                            content: '';
                            display: flex;
                            width: 100%;
                            height: 1px;
                            background: $c_black;
                            bottom: -2px;

                            @media screen and (max-width: $screen--s) {
                                display: none;
                            }
                        }
                    }

                    p {
                        margin-top: 1rem;
                    }
                }
            }

            .product-options-wrapper {

                .swatch-opt {
                    display: flex;
                    flex-direction: column;
                }

                .swatch-attribute {
                    display: flex;
                    flex-wrap: wrap;

                    .mage-error {
                        width: 100%;
                        order: 10;
                    }

                    &.matrix_attr_visual{
                        margin-bottom: 25px;
                        order: -1;

                        .swatch-attribute-label {
                            order: 1;
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .swatch-attribute-selected-option {
                            order: 2;
                        }

                        .swatch-attribute-options {
                            order: 2;
                            width: 100%;
                            margin-bottom: 8px;
                        }

                        .swatch-option {
                            position: relative;
                            border-radius: 50%;
                            border: 2px solid $c_white;
                            height: 32px;
                            width: 32px;
                            background-size: cover !important;

                            &.selected,
                            &:hover {
                                outline: none;
                                border: 2px solid $c_text-dark !important;

                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: -1px;
                                    left: -1px;
                                    width: 30px;
                                    height: 30px;
                                    border: 4px solid #fff;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }

                    &.matrix-attr-txt {
                        position: relative;

                        .size-guide-link {
                            float: right;
                            padding-right: 8px;

                            a {
                                color: $c_black;
                            }

                            &--mobile {
                                display: none;
                            }

                            @media screen and (max-width: $screen__l) {

                                &--desktop {
                                    display: none;
                                }

                                &--mobile {
                                    display: inline-block !important;
                                }
                            }
                        }

                        .swatch-attribute-label {
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .swatch-attribute-options {
                            width: 100%;
                        }

                        .swatch-option.text {
                            height: auto;
                            border-radius: 4px;
                            padding: 12px 18px;
                            font-size: 13px;
                            margin-right: 8px;
                            margin-bottom: 8px;
                            border-color: $c_text-lighter;
                            background-color: $c_white;
                            transition: background-color .24s ease-in-out;

                            &[data-child-product-stock="0"] {
                                background-color: $c_text-lighter;
                                color: $c_text-light;

                                &.selected {
                                    border-color: $c_black;
                                    background-color: $c_black;
                                }
                            }

                            &:hover {
                                background-color: $c_white;
                                outline: none;
                                border-color: $c_black;
                            }

                            &.selected {
                                border-color: $c_black;
                                background-color: $c_black !important;
                                outline: none;
                                color: $c_white;
                            }

                            &.disabled {
                                color: $c_grey;
                                background: $c_bg-grey;

                                &:after {
                                    display: none;
                                }

                                &:hover {
                                    border-color: $c_text-lighter;
                                }
                            }
                        }

                        .size-guide-popup-button {
                            position: absolute;
                            right: 0;
                            cursor: pointer;
                            text-decoration: underline;
                            text-underline-offset: 0.18rem;

                            &:before {
                                content: '';
                                background-image: url('../images/logo_size_guide.png');
                                width: 25px;
                                height: 25px;
                                position: absolute;
                                left: -33px;
                                bottom: 0px;
                                background-repeat: no-repeat;
                                background-size: cover;
                            }

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            .product-add-form {
                margin-top: 20px;
            }

            .field.qty {
                display: none;
            }

            .actions {

                .action.tocart {
                    @extend .q_b-primary;
                    width: 100%;
                    margin-bottom: 20px;

                    &.primary {
                        @media screen and (max-width: $screen--s) {
                            height: 60px;
                            z-index: 10;
                            position: fixed;
                            bottom: 0;
                            left: 0;
                            width: 50%;
                            margin: 0;
                            padding: 10px 15px;
                            border-radius: 0;
                            font-size: 12px;

                            span {
                                font-size: 12px;
                            }
                        }
                    }

                    &.disabled {
                        @media screen and (max-width: $screen--s) {
                            opacity: 1 !important;
                        }
                    }
                }

                .paypal.before:before {
                    @media screen and (max-width: $screen--s) {
                        display: none;
                    }
                }
            }

            .assurance-pictos {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;

                .picto {
                    position: relative;

                    svg {
                        width: 116px;

                        path {
                            stroke-width: 4px;

                            &.cls-1, .cls-5 {
                                stroke-width: 3.5px !important;
                            }

                            &.cls-4 {
                                stroke-width: 7px;
                            }
                        }

                        .cls-1, .cls-4 {
                            stroke: #000;
                        }

                        rect {
                            &.cls-1 {
                                fill: #000;
                            }

                            &.cls-4 {
                                stroke-width: 7px;
                            }
                        }
                    }

                    span {
                        position: absolute;
                        bottom: 11px;
                        width: 100%;
                        text-align: center;
                        left: 0;
                        font-size: 14px;
                        color: #000;
                    }
                }
            }
        }

        .product.info.detailed {
            width: 100%;

            .product-info-navigation {
                position: relative;
                background-color: $c_bg-grey;
                display: flex;
                justify-content: center;
                align-items: center;

                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    height: 100%;
                    width: 300vw;
                    background-color: $c_bg-grey;
                    left: -100%;
                    right: -100%;
                    top: 0;
                    bottom: 0;
                }

                ul {
                    position: relative;
                    display: flex;
                    z-index: 10;
                    margin: 0;
                    padding: 0;

                    li {
                        position: relative;
                        list-style-type: none;
                        margin-bottom: 0;
                    }

                    li + li {
                        margin-left: 30px;
                        padding-left: 38px;

                        &:before {
                            position: absolute;
                            content: '';
                            display: block;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            background-color: $c_text-medium;
                            vertical-align: center;
                        }
                    }
                }

                a {
                    display: block;
                    color: $c_text-medium;
                    position: relative;
                    padding: 36px 6px;
                    font-size: 14px;
                    letter-spacing: .7px;
                    line-height: 1.43;
                    font-weight: 600;

                    &:hover {
                        color: $c_black;
                        text-decoration: none;

                        &:before {
                            position: absolute;
                            display: block;
                            content: '';
                            height: 2.5px;
                            bottom: 0;
                            left: -4px;
                            right: -4px;
                            background-color: #df5d24;
                        }
                    }
                }
            }

            .product-section{
                &__title {
                    margin-top: 0;
                    text-transform: uppercase;
                    font-size: 22px;
                    margin-bottom: 15px;
                }
            }

            .product-info-topdesc {
                display: flex;
                justify-content: center;
                padding-top: 50px;
                padding-bottom: 0px;

                &__text {
                    max-width: 660px;

                    .product-section__title {
                        margin-bottom: 24px;
                    }

                    .topdesc__text__title {
                        margin-top: 0;
                        margin-bottom: 30px;
                        color: $c_text-light;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: 30px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 30px;
                        margin-bottom: 24px;
                    }

                    p:last-child {
                        margin-bottom: 0px;
                    }
                }

                &__mainstats {
                    margin-top: 62px;
                    min-width: 320px;
                    padding-left: 140px;

                    .topdesc {

                        &__mainstats {

                            &__title {
                                text-transform: uppercase;
                                color: $c_primary;
                                font-size: 20px;
                                font-weight: 700;
                                max-width: 240px;
                                line-height: normal;
                            }

                            &__stats {
                                list-style-type: none;
                                margin: 0;
                                padding: 0;

                                li {
                                    display: flex;
                                    padding: 20px 0;
                                    margin-bottom: 0;
                                    border-bottom: 1px solid $c_text-lighter;
                                }

                                .label,
                                .value {
                                    width: 50%;
                                }

                                .label {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }

            .product-info-quadimg {
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 0;
                padding-top: 100px;

                &__img {
                    padding: 5px;
                    width: 50%;
                }
            }

            .product-info-bikesize {
                padding-bottom: 100px;
                max-width: 700px;
                margin: 0 auto;

                .product-info-bikesize__bar {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 26px;
                }

                &__chart {
                    margin-bottom: 50px;

                    img {
                        display: none;

                        &.active {
                            display: block;
                            position: relative;
                            float: none;
                        }
                    }
                }

                &__measure {
                    display: flex;
                    align-items: center;
                    font-size: 16px;

                    .label {
                        font-weight: 700;
                        margin-right: 18px;
                    }

                    .sizes {
                        display: flex;
                        align-items: center;
                        margin: 0;
                        padding: 0;
                        list-style-type: none;

                        li {
                            display: flex;
                            margin-bottom: 0;

                            &.active {
                                font-weight: 700;
                                color: $c_primary;
                            }

                            &:not(.active) {
                                cursor: pointer;
                            }

                            &:not(.active):hover {
                                text-decoration: underline;
                            }
                        }

                        li + li {

                            &:before {
                                content: '|';
                                display: block;
                                margin: 0 12px;
                                color: $c_black;
                            }
                        }
                    }
                }

                &__more {
                    display: flex;
                    justify-content: center;
                }
            }

            .product-info-video {
                padding-top: 100px;
                &__container {
                    position: relative;
                    padding-top: 56.25%;

                    iframe,
                    video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
            }

            .anchor-section-5 {
                margin-bottom: 80px;

                @media screen and (max-width: $screen--s) {
                    margin-bottom: 24px;
                }
            }

            .product-info-techspecs {
                padding-top: 100px;
                padding-bottom: 100px;
                max-width: 1120px;
                margin: 0 auto;

                ul,
                li {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                }

                ul {
                    @media screen and (max-width: $screen--m) {
                        padding: 0 20px;
                    }
                }

                &__toggle {
                    position: relative;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 16px;
                    border-bottom: 2px solid $c_black;
                    display: block;
                    padding-top: 30px;
                    padding-bottom: 16px;
                }

                input[type="checkbox"] {
                    display: none;
                }

                &__content {
                    //height: 0;
                    //overflow: hidden;

                    ul {
                        padding-top: 10px;

                        @media screen and (max-width: $screen__s) {
                            padding: 0;
                        }

                        li {
                            display: flex;
                            padding: 12px 20px;
                            font-size: 14px;

                            @media screen and (max-width: $screen__s) {
                                padding: 12px 0;
                            }

                            .label {
                                font-weight: 700;
                                width: 33.33%;
                            }

                            .value {
                                width: 66.66%;

                                > ul {
                                    padding-top: 0;

                                    li {
                                        padding: 0;
                                        background-color: inherit;
                                    }
                                }
                            }

                            &:nth-child(odd) {
                                background-color: $c-bg-grey;
                            }
                        }
                    }

                    &.active {
                        height: auto;
                    }
                }

                &__more {
                    display: flex;
                    justify-content: center;
                    padding-top: 50px;
                }

                input[type="checkbox"]:checked + .product-info-techspecs__content {
                    height: auto;
                }
            }

            .product-info-sidebyside {
                padding: 0 0 100px;
                display: flex;
                align-items: stretch;

                .side {
                    flex-basis: 100%;
                }

                .side--image {
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    img {
                        opacity: 0;
                    }
                }

                .side--text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: $c-darkblue-bg;

                    h3 {
                        color: $c_white;
                        text-align: center;
                        font-size: 30px;
                        font-weight: 700;
                        margin-top: 0;
                        margin-bottom: 30px;
                    }
                }

                @media screen and (max-width: $screen__m) {
                    flex-direction: column;

                    .side--text {
                        order: -1;
                        padding: 60px 15px;
                    }
                }
            }

            .product-info-services {
                padding: 0 30px 100px;
                display: flex;
                text-align: center;

                &__service {
                    flex-basis: 100%;
                    padding: 0 30px;
                }

                h3 {
                    margin: 30px 0 15px 0;
                    font-weight: bold;
                    font-size: 24px;
                    min-height: 52px;
                }

                p {
                    margin-bottom: 40px;
                    min-height: 60px;
                }

                @media screen and (max-width: $screen__m) {
                    flex-direction: column;

                    &__service {
                        padding: 0;

                        + .product-info-services__service {
                            margin-top: 60px;
                        }
                    }

                    p {
                        margin-bottom: 20px;
                        min-height: 0;
                    }
                }
            }

            .copyright-bottom {
                font-size: 14px;
                border-top: 1px solid #000000;
                color: #000000;
                padding-top: 1.5rem;
                max-width: 1120px;
                text-align: center;
                margin: 0 auto;
                clear: both;
            }
        }

        @media screen and (max-width: $screen--l) {

            .product-media {
                margin-right: 0;
                max-width: none;
                width: 100%;
            }

            .product-info-main {
                max-width: 500px;
                width: 100%;
            }
        }

        @media screen and (max-width: $screen--m) {
            flex-direction: column;

            .product-media {
                .owl-stage-outer {
                    &:after {
                        content: none;
                    }
                }

                .copyright-img {
                    color: #514f4f;
                    bottom: 30px;
                    left: 0;
                    font-size: 14px;
                }
            }

            .product-info-main {
                margin-top: 30px;
                padding: 20px;
                padding-bottom: 0;

                .page-title-wrapper {
                    margin-top: 0 !important;

                    .page-title {
                        margin-top: 0;
                    }
                }
            }

            .product.info.detailed {

                .product-info-navigation {
                    display: none;
                }

                .product-info-topdesc {
                    flex-direction: column;
                    margin-bottom: 0;
                    margin-top: 40px;
                    padding: 0 20px 60px 20px;


                    &__text {
                        width: 100%;
                        max-width: 100%;
                    }

                    &__mainstats {
                        width: 100%;
                        margin-top: 10px;
                        padding-left: 0;

                        .topdesc__mainstats__title {
                            max-width: none;
                        }
                    }
                }

                .product-info-quadimg {
                    flex-direction: column;
                    margin-left: -15px;
                    margin-right: -15px;
                    padding-bottom: 60px;
                    padding-top: 0px;

                    &__img {
                        width: 100%;
                        padding: 0;
                    }
                }

                .product-info-video {
                    margin-left: -15px;
                    margin-right: -15px;
                    padding-bottom: 60px;
                }

                .product-info-bikesize {
                    padding: 0 20px 60px 20px;

                    &__chart {
                        margin-bottom: 20px;
                    }
                }

                .product-info-techspecs {
                    padding: 0 0 60px 0;

                    .product-section__title {
                        padding: 0 20px;
                    }
                }

                .copyright-bottom {
                    margin: 0 20px;
                }
            }
        }
    }

    .fb_dialog .fb_dialog_content iframe {
        @media screen and (max-width: $screen--s) {
            bottom: 70px !important;
            right: 0px !important;
        }
    }
}

#store-locator-modal{

    .modal {
        &__wrapper {
            max-width: 1040px;
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            margin: 50px auto;

            @media screen and (max-width: $screen--m) {
                margin: 0;
                height: auto;
                overflow: scroll;
            }

            .modal__close {
                top: -20px;
                right: -20px;

                @media screen and (max-width: $screen--m) {
                    top: 25px;
                    right: 25px;
                    position: fixed;
                }
            }
        }
    }

    .locator {
        &__list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: baseline;
            padding: 0;
            color: $c_black;
            margin-left: 100px;

            @media screen and (max-width: $screen--m) {
                margin: auto;
                justify-content: center;
            }

            > li {
                list-style: none;
                width: 100%;
                max-width: 33%;
                min-width: 227px;
                margin: 20px 0;
            }

            &__title {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 7px;
            }

            &__option {
                font-weight: 600;
                margin-top: 12px;

                .option-green {
                    color: #008000;
                }
            }

            &__option-list {
                margin-top: 5px;

                > span {
                    min-width: 60px;
                    line-height: 32px;
                    padding: 0 5px;
                    border-radius: 4px;
                    border: solid 1px $c_text-lighter;
                    display: inline-block;
                    text-align: center;
                    color: #008000;
                }
            }

            &__actions {
                margin-top: 12px;

                a {
                    color:$c-black;
                    margin-right: 10px;
                    text-decoration: underline;
                }
            }

            .no-stock {
                margin-top: 12px;
                font-weight:600;
                color: #ff0000;
            }
        }
    }
}

.catalogsearch-result-index .page-main {
    .columns {
        padding-top: 0;

        .sidebar.sidebar-main {
            margin-top: 55px;
        }

        .column.main {
            .page-title-wrapper {
                margin-bottom: 25px;
                margin-top: 20px;

                .page-title {
                    text-transform: none;
                }
            }
        }
    }
}

.no-stock-modal.modal-popup._show {
    background: rgba(28, 32, 40, 0.8);
    justify-content: center;

    .modal-inner-wrap {
        position: inherit;
        margin: 5rem 0;
        overflow: initial;

        @media screen and (max-width: $screen--s) {
            left: 0;
            margin: 0;
        }

        .modal-header {
            position: relative;

            .action-close {
                position: absolute;
                top: 0px;
                right: 0px;

                @media screen and (max-width: $screen--s) {
                    top: 30px;
                    right: 30px;
                }

                &:before {
                    width: 40px;
                    font-size: 30px;
                    background: #de5d24;
                    color: #fff;
                    border-radius: 50%;
                }
            }
        }
    }
}

/*splitit-popup*/

.modal-popup.splitit-popup.modal-slide._inner-scroll._show {
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    overflow-y: scroll;
    background-color: rgba(0,0,0,.7);

    .modal-inner-wrap {
        max-width: 898px;
        border-radius: 10px;

        .modal-header {
            padding: 0;
            .action-close {
                z-index: 2;
                position: absolute;
                top: 3px;
                right: 4px;
                background: #a77bca;
                padding: 0rem;
                border-radius: 20px;

                @media screen and (max-width: 800px) {
                    top: 30px;
                    right: 30px;
                }

                &:before {
                    color: #fff;
                }
            }
        }

        .modal-content {
            border-radius: 10px;
            padding: 0;

            .header-split-it {
                padding: 30px 40px 20px;
                background-color: #f5f5f5;
                position: relative;

                h2 {
                    font-size: 30px;
                    color: #000;
                    font-family: Avenir;
                    font-weight: 700;
                    text-transform: inherit;
                    margin: 20px 0 10px;

                    @media screen and (max-width: 800px) {
                        font-size: 25px;
                    }
                }

                p {
                    font-family: Avenir;
                    font-weight: 500;
                }

                .pictos {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    border: 0;
                    height: 100%;

                    @media screen and (max-width: 800px) {
                        display: none;
                    }

                    .picto-violet, .picto-brown, .picto-blue {
                        position: absolute;
                        height: auto;
                    }

                    .picto-violet {
                        width: 75px;
                        right: 146px;
                        top: 55px;
                    }

                    .picto-brown {
                        width: 45px;
                        right: 480px;
                        top: 20px;
                    }

                    .picto-blue {
                        width: 35px;
                        right: 40px;
                        bottom: -20px;
                    }
                }
            }

            .header-subtitle {
                font-size: 25px;
                text-align: center;
                font-weight: 600;
                text-transform: none;

                @media screen and (max-width: 800px) {
                    margin-top: 0;
                    font-size: 20px;
                    text-transform: none;
                }
            }

            .sub-message {
                text-align: center;
                color: #aaa;
            }

            .possible-plans {
                margin-top: 30px;
                display: flex;
                justify-content: center;
                text-align: center;

                .items {
                    .data.item.title {
                        width: 100px;
                        font-size: 20px;
                        padding-bottom: 10px;

                        .data.switch {
                            color: #B9BCBF;

                            &:focus {
                                outline: none;
                            }
                        }

                        &.active {
                            border-bottom: 2px solid #00A9B4;

                            .data.switch {
                                color: #12110C;

                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }
                }

                .sample-plan-label {
                    font-weight: 700;
                    font-size: 20px;
                    margin: 20px 0;

                    @media screen and (max-width: 800px) {
                        font-size: 16px;
                    }
                }

                .data.item.content {
                    .plan-schema {
                        .item {
                            width: 100px;
                            margin-bottom: 10px;

                            div:first-child {
                                position: relative;

                                &:before {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 40%;
                                    width: 22px;
                                    height: 22px;
                                    border: 2px solid;
                                    border-radius: 25px;
                                    color: #592E5A;
                                    background: #fff;
                                }
                            }

                            &:not(:last-child) div:first-child {
                                &:after {
                                    content: "";
                                    position: absolute;
                                    right: -45px;
                                    top: 10px;
                                    width: 85px;
                                    display: block;
                                    height: 2px;
                                    background: #592E5A;

                                    @media screen and (max-width: 800px) {
                                        display: none;
                                    }
                                }
                            }

                            .price-per-month {
                                font-weight: 700;
                                font-size: 16px;
                                color: #592E5A;
                                margin-top: 4px;

                                &:before {
                                    content: "$ ";
                                }
                            }

                            .date {
                                color: #00A9B4;
                            }
                        }
                    }
                }

                #plan-12 .plan-schema .item:nth-child(3) div:first-child:after {
                    color: white;
                    border: dashed;
                    right: -47px;
                }
            }

            .total {
                max-width: 300px;
                width: 100%;
                margin: 30px auto 10px;
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 5px;
                box-shadow: 0 0 5px rgba(0, 0, 0 , 0.15);

                .total-label {
                    font-weight: 600;
                }

                .total-amount {
                    color: #592E5A;
                    font-weight: 700;
                    font-size: 20px;

                    &:before {
                        content: "$ ";
                    }
                }
            }

            .footer-split-it {
                .splitit-icon-container {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 20px;
                    gap: 25px;

                    @media screen and (max-width: 800px) {
                        flex-direction: column;
                    }

                    .splitit-icon-wrapper {
                        flex-basis: 100%;
                        text-align: center;

                        .splitit-title {
                            max-width: 245px;
                            margin: 0 auto 13px;
                            font-size: 16px;
                            font-weight: 800;
                            text-transform: inherit;
                            color: #642f6c;
                        }
                        .splitit-description {
                            font-size: 13px;
                            font-weight: 600;
                            max-width: 210px;
                            margin: auto;
                            text-align: justify;

                            @media screen and (max-width: 800px) {
                                max-width: 285px;
                                text-align: center;
                            }
                        }
                    }
                }

                .splitit-legal-content {
                    p {
                        color: #999;
                        font-family: avenir;
                        font-size: 12px;
                        padding: 10px 20px;
                    }
                }
            }
        }

        .modal-footer {
            display: none;
        }
    }
}

.fotorama-item.fotorama--fullscreen {
    .fotorama__wrap {
        .fotorama__fullscreen-icon {
            background-image: url('../images/icons/close-foto.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 25px;
            width: 25px;
            top: 20px;
            right: 20px;
            opacity: 1;

            &:focus {
                &:after {
                    display: none;
                }
            }
        }

        .fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
            display: none !important;
        }

        .fotorama__stage {
            height: 100vh !important;
            .fotorama__arr {
                opacity: 1;

                .fotorama__arr__arr {
                    background-image: url('../images/icons/arrow-left.svg');
                    background-position: center;
                    height: 24px;
                    width: 13px;
                }

                &.fotorama__arr--next {
                    .fotorama__arr__arr {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .fotorama__fullscreen-icon:focus:after, .fotorama__zoom-out:focus:after, .fotorama__zoom-in:focus:after, .fotorama__arr:focus:after, .fotorama__stage__shaft:focus:after, .fotorama__nav__frame--thumb:focus .fotorama__thumb:after, .fotorama__nav__frame--dot:focus .fotorama__dot:after {
        box-shadow: none;
    }
}

/* Owl Touch Scroll Fix */
.owl-carousel .owl-stage, .owl-carousel.owl-drag .owl-item{
    -ms-touch-action: auto;
    touch-action: auto !important;
}

.products.list .item.product .product-item-price .special-price.disabled,
.products.list .product-item .product-item-price .special-price.disabled,
.widget-product-grid .item.product .product-item-price .special-price.disabled,
.widget-product-grid .product-item .product-item-price .special-price.disabled,
.widget-new-grid .item.product .product-item-price .special-price.disabled,
.widget-new-grid .product-item .product-item-price .special-price.disabled,
.widget-viewed-grid .item.product .product-item-price .special-price.disabled,
.widget-viewed-grid .product-item .product-item-price .special-price.disabled {color: #000;}

.catalog-product-view .column.main .product-info-main .product-info-price .price-box .special-price.disabled .price {color: #000;}


.swatch-option-tooltip {
    display: none !important;
}
