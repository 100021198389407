
// Temporary parent class to scope styles
.catalogsearch-result-index,
.checkout-index-index,
.checkout-cart-index,
.catalog-category-view,
.ambrand-index-index {
    .page-wrapper {

        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="tel"],
        input[type="number"],
        select,
        textarea {
            border-radius: 4px;
            border-color: $c_border-grey;
            border-width: 1px;
            font-size: 1.4rem;
            padding: 14px;
            height: auto;
            font-family: "Lato", sans-serif;

            &:focus {
                border-color: $c_primary;
            }
        }

        select {
            padding: 16px 40px 15px 14px;
            background-image: url('../images/icons/sort-down.png');
            background-size: 16px 16px;
        }

        input[disabled] + label {
            color: $c-text-light !important;
        }

        input[type="checkbox"],
        input[type="radio"] {
            position: relative;
            margin-right: 5px;
            vertical-align: top;
            width: 20px;
            height: 20px;
            appearance: none;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &:before {
                content: "";
                display: block;
                height: 20px;
                width: 20px;
                position: absolute;
                left: 0;
                top: 0;
                border: none;
                background-color: $c-white;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 18px 18px;
            }
        }

        input[type="checkbox"] {

            &:before {
                background-image: url("../images/icons/checkbox.svg");
            }

            &:checked:before,
            &.active:before {
                background-image: url("../images/icons/checkbox-checked.svg");
            }
        }

        input[type="radio"] {
            font-size: 1.2rem;

            &:before {
                left: -1px;
                border-radius: 100%;
                background-image: url("../images/icons/radio.svg");
            }

            &:checked:before,
            &.active:before {
                background-image: url("../images/icons/radio-checked.svg");
                border-color: $c_text-dark;
            }
        }

        .field.choice {
            position: relative;

            &:before {
                content: none;
            }

            > input {
                position: absolute;
            }

            > label {
                display: block;
                padding-left: 30px;
            }
        }
    }
}
