@media only screen and (min-width: 768px) {
  .form.password.forget {
    .field {
      label {
        width: 15%;
        text-align: left;
      }
      div.control {
        width: 85%;
      }
    }
    .actions-toolbar {
      margin-left: 0;
      .secondary {
        text-align: left;
        width: 15%;
      }
      .primary {
        float: none;
      }
    }
  }
}
