.cms-page-view {
    &.blog-index-index {

        .page-main {
            max-width: 1360px;
            padding-top: 0 !important;
        }

        .breadcrumbs {
            margin-top: 120px !important;

            @media screen and (max-width: $screen--l) {
                margin-top: 60px !important;
            }

        }

        &.top-header-message-opened {
            .breadcrumbs {
                margin-top: 144px !important;

                @media screen and (max-width: $screen--l) {
                    margin-top: 86px !important;
                }
            }

            .page-main {
                padding-top: 0 !important;
            }
        }
    }

    button.primary, button.action.submit.primary {
        display: inline-block;
        padding: 12px 20px;
        font-size: 13px;
        color: #fff;
        background-color: #df5d24;
        border: 1.5px solid #df5d24;
        border-radius: 4px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: background-color 0.24s ease, color 0.24s ease, border-color 0.24s ease;
        border-width: 2px;

        &:hover {
            text-decoration: none;
            background-color: #b54a1b;
            color: #fff;
            border-color: #b54a1b;
            border-width: 2px;
        }
    }
}

.box-newsletter {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
    font-weight: 500;
    
        strong {
            font-weight: 900;
        }
    }

    p {
        font-size: 16px;
    }
}

.mgz-element-newsletter_form {
    .newsletter-description {
        margin: 0 0 30px 0 !important;
        font-weight: 600;
        line-height: 1.1;
        font-size: 1.5rem;
        text-transform: uppercase;
    }

    .mgz-newsletter-form {
        .mgz-newsletter-fields {
            display: grid;
            grid-template:
                "firstname lastname"
                "email email";
            grid-gap: 10px;

            @media screen and (max-width: $screen--l) {
                grid-template:
                "firstname"
                "lastname"
                "email";
            }

            .mgz-newsletter-field.email {
                grid-area: email;

            }

            .mgz-newsletter-field.lastname {
                grid-area: lastname;
            }

            .mgz-newsletter-field.firstname {
                grid-area: firstname;
            }

            input {
                background-color: transparent;
                border: 1px solid rgba(140, 150, 169, 0.6);
                border-radius: 4px;
                color: #000;
                height: 49px;
                font-size: 14px;
                padding-left: 22px;

                &::placeholder {
                    color: rgba(140, 150, 169, 0.6);
                    opacity: 1;
                }
            }
        }

        .actions {
            width: 100%;
            display: flex;
            justify-content: right;
            margin-top: 25px;

            button.mgz-newsletter-btn.action.primary {
                height: auto;
            }
        }
    }
}

.mgz-newsletter-form-wrapper .mgz-newsletter-message-success {
    color: #006400;
}