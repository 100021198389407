
$checkout-container: 1090px;

@import "./twodev/layout";

.checkout-container {

    &:before {
        content: none !important;
    }
}

// Global stylingl
.checkout-index-index {

    .step-title {
        font-size: 1.8rem;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 0;
    }

    .fieldset > .field.required > .label:after,
    .fieldset > .field._required > .label:after,
    .fieldset > .fields > .field.required > .label:after,
    .fieldset > .fields > .field._required > .label:after {
        content: none;
    }

    .abs-field-tooltip input,
    .field .control._with-tooltip input {
        width: 100%;
        padding-right: 40px;
    }

    .field-tooltip {
        top: 8px;
        right: 10px;

        .label >span {
            display: none;
        }

        .field-tooltip-action {

            &:before {
                color: $c_text-medium;
            }
        }

        &-content {
            top: -10px;
            transform: translate(-100%, -100%);
            left: 38px;
            background-color: $c_bg-grey;
            border: 0;
            box-shadow: 0 9px 10px 0 rgba(0,0,0,0.2);
            border-radius: 4px;
            color: $c_text-light;

            &:before {
                top: auto;
                bottom: -18px;
                left: auto;
                right: 17px;
                transform: rotate(-90deg);
                border-right-color: $c-bg-grey;
            }

            &:after {
                content: none;
            }
        }
    }

    .fieldset > .field:not(.choice) > .control,
    .fieldset > .field:not(.choice) > .label {
        width: 100%;
        float: none;
        text-align: left;
    }

    .fieldset > .field:not(.choice) > .label {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .modal-popup {

        .modal-inner-wrap {
            width: auto;
            margin-left: 0;
        }

        .modal-footer {
            display: flex;

            .action-save-address {
                float: left;
                margin-left: 0;
                margin-right: 15px;
                @extend .q_b-secondary;
            }

            .action-hide-popup {
                @extend .a_underline;
            }
        }

        .form-shipping-address {
            max-width: none;
        }
    }

    .agreements-modal {

        h2 {
            margin-top: 40px;
            margin-bottom: 25px;
            font-size: 2.4rem;
            font-weight: bold;
            letter-spacing: 1px;
        }

        h3 {
            margin-top: 30px;
            font-size: 1.8rem;
            font-weight: bold;
            letter-spacing: 1px;
        }

        p {
            line-height: 1.5;
        }
    }
}

@import './twodev/shipping-step';
@import './twodev/shipping-methods';
@import './twodev/payment-step';
@import './twodev/summary';
@import './twodev/sucess';

@media screen and (max-width: $screen__m) {

    .opc-wrapper {
        flex-basis: 100%;
    }

    .checkout-container {

        .messages {
            flex-basis: 100%;
        }
    }
}

@media screen and (min-width: $screen__m) {
    .promo-container {
        .estimation_and_promo {
            #discount-coupon-form-minicart {
                [for="coupon_code"] {
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }
}
.promo-container {
    .estimation_and_promo {
        #discount-coupon-form-minicart {
            .actions-toolbar {
                align-self: flex-start;
                margin-left: 5px;
                button {
                    &.primary {
                        width: 100%;
                        height: 46px;
                        margin-bottom: 15px;
                        padding: 14px 17px;
                        line-height: 1.2;
                        font-size: 13px;
                        text-transform: uppercase;
                        color: #fff;
                        background: #df5d24;
                        border: none;
                    }
                }
            }
        }
    }
}

.table-checkout-shipping-method {
    .col-tooltip {
        padding: 0;
        .field-tooltip {
            position: relative;
            top: 0;
            right: 0;

            #tooltip-label {
                display: none;
            }
        }
    }
}
