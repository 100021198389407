
// Summary
.column.main .opc-sidebar {
    margin-top: -91px;

    .opc-block-summary {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        > .title {
            font-size: 2.4rem;
            letter-spacing: 1px;
            font-weight: bold;
            text-transform: uppercase;
            border-bottom: 0;
        }
    }

    .opc-block-summary .mark {

        .giftcard-code,
        .discount.coupon,
        .value {
            color: $c_text-light;
            display: block;

            a {
                margin-left: 10px;
                color: $c_text-light;
                text-decoration: underline;
                text-transform: lowercase;
            }
        }
    }

    .aw-gc-totals .mark {
        font-size: 1.4rem;
    }

    .minicart-items-wrapper {
        max-height: none;
        overflow: visible;
        padding-right: 0;
    }

    .table-totals {
        margin-top: 35px;
    }

    .items-in-cart {
        margin-top: 35px;

        .title {
            font-size: 1.6rem;
            color: $c_text-light;
            border-bottom-color: $c_border-grey;

            &:after {
                color: $c_text-light;
                margin: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        ol.minicart-items {
            list-style-type: none;
        }

        .product-item {
            margin-top: 30px;
            border-top: 0;
            padding-bottom: 0;
        }

        .price,
        .product-item-name {
            color: $c_text-medium;
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: bold;
        }

        .price-including-tax,
        .price-excluding-tax,
        .product-item-name-block {
            margin-top: 0;
        }

        .product-item-name-block {
            padding-right: 30px;
        }

        .details-qty {
            color: $c_text-light;
        }

        .product.options {
            color: $c_text-light;

            .toggle {

                &:after {
                    content: none;
                }
            }
        }

        .item-options {
            margin-top: 15px;

            .label {
                font-weight: bold;

                &:after {
                    content: ": ";
                }
            }
        }
    }
}

@media screen and (max-width: $screen__m) {

    .column.main {

        .opc-sidebar {
            margin-top: 0;

            &.custom-slide {
                left: 0;

                .modal-inner-wrap {
                    background-color: $c_bg-grey;
                }

                .action-close {
                    padding: 28px 20px;
                    margin: 0;

                    &:before {
                        font-size: 42px;
                        color: $c_text-light;
                    }
                }
            }

            .opc-block-summary,
            .opc-block-shipping-information {
                background: $c_bg-grey;
            }

            .opc-block-summary > .title {
                background-color: $c_white;
                padding: 30px 20px;
                font-size: 2.0rem;
            }

            .table-totals {
                max-width: calc(100% - 40px);
                margin: 25px auto 0;

                .totals-tax {

                    th, td {
                        padding-bottom: 20px;
                    }
                }

                .grand.totals {

                    th, td {
                        border-top: 1px solid $c_border-grey;
                        font-size: 1.6rem;
                    }
                }
            }

            .items-in-cart {
                margin-top: 0;
                padding: 30px 20px 0;
                background: $c_bg-grey;
            }

            .opc-block-shipping-information {
                padding: 0 20px;
                margin-top: 20px;
            }
        }
    }
}
