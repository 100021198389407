.aw-gc-amount {
  .amount-options {
    .amount-option {
      padding: 0;
      input {
        display: none;
      }
      label {
        padding: 10px;
        display: block;
        width: 100%;
      }
      &.active {
        border: 1px solid #000000;
        color: #000000;
      }
    }
  }
}