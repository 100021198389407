
.checkout-shipping-method {

    .step-title {
        margin-bottom: 20px;
    }
}

#checkout-step-shipping_method {
    margin-bottom: 0;

    .actions-toolbar {
        margin-left: 0;
        margin-top: 20px;
        @extend .abs-add-clearfix;

        > .primary {
            float: left;
        }

        .action.primary {
            @extend .q_b-secondary;
            font-weight: normal;
            text-transform: uppercase;
            font-size: 1.4rem;
        }
    }
}

.table-checkout-shipping-method tbody td {
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 0;
}

.instore-option {
    margin-top: 13px;

    .fieldset {
        margin-bottom: 0;

        .field {
            width: 50%;
            float: left;

            &:first-of-type {
                width: calc(50% - 10px);
                margin-right: 10px;
            }
        }
    }

    .warning-message {
        clear: both;

        &.success-message {
            color: $c_text-light;
        }
    }

    .control {
        position: relative;

        .ui-datepicker-trigger {
            position: absolute;
            top: 3px;
            right: 0;
        }
    }

    [name="selectShopCheckoutForm.pickup_date"] {
        padding-right: 40px;
    }
}

@media screen and (max-width: $screen__m) {

    #checkout-step-shipping_method {

        .actions-toolbar {

            > .primary {
                width: 100%;
            }
        }
    }
}
