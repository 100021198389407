.chocolat-zoomable.chocolat-zoomed {
    cursor: zoom-out;
}

.chocolat-open {
    overflow: hidden;
}

.chocolat-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: #fff;
    display: none;
    opacity: 0.95;
}

.chocolat-wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    z-index: 16;
    color: #fff;
}

.chocolat-zoomable .chocolat-img {
    cursor: zoom-in;
}

.chocolat-loader {
    height: 32px;
    width: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -16px;
    margin-top: -16px;
    z-index: 11;
    background: url(../images/loader.svg);
    display: none;
}

.chocolat-content {
    position: fixed;
    width: 0;
    height: 0;
    left: 50%;
    top: 50%;
    z-index: 14;
    text-align: left;
}

.chocolat-content .chocolat-img {
    position: absolute;
    width: 100%;
    height: 100%;
}

.chocolat-wrapper .chocolat-left {
    @include lib-icon-font(
            $_icon-font-content: $icon-prev,
            $_icon-font-size: 60px,
            $_icon-font-line-height: 1,
            $_icon-font-text-hide: false,
            $_icon-font-position: after,
            $_icon-font-display: block
    );
    position: absolute;
    z-index: 17;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 7px;
    cursor: pointer;
    color: $c_black;
    display: none;
}

.chocolat-wrapper .chocolat-right {
    @include lib-icon-font(
            $_icon-font-content: $icon-next,
            $_icon-font-size: 60px,
            $_icon-font-line-height: 1,
            $_icon-font-text-hide: false,
            $_icon-font-position: after,
            $_icon-font-display: block
    );
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 7px;
    cursor: pointer;
    z-index: 17;
    color: $c_black;
    display: none;
}

.chocolat-wrapper .chocolat-right.active {
    display: block;
}

.chocolat-wrapper .chocolat-left.active {
    display: block;
}

.chocolat-wrapper .chocolat-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    line-height: 50px;
    height: 50px;
    overflow: hidden;
    z-index: 17;
    margin-bottom: 10px;
}

.chocolat-wrapper .chocolat-close {
    @include lib-icon-font(
            $_icon-font-content: $icon-remove,
            $_icon-font-size: 42px,
            $_icon-font-line-height: 16px,
            $_icon-font-text-hide: false,
            $_icon-font-position: after,
            $_icon-font-display: block
    );
    padding: 15px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    color: $c_black;
}

.chocolat-wrapper .chocolat-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 40px;
    height: 50px;
    z-index: 17;
    padding-left: 15px;
    padding-right: 15px;
    text-align: right;
    margin-top: 10px;
    color: #000;
    font-size: 2.4rem;

    .chocolat-fullscreen {
        display: none !important;
    }
}

.chocolat-wrapper .chocolat-set-title {
    display: inline-block;
    padding-right: 15px;
    line-height: 1;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.chocolat-wrapper .chocolat-pagination {
    float: right;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 15px;
    //border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.chocolat-wrapper .chocolat-fullscreen {
    width: 16px;
    height: 40px;
    background: url(../images/fullscreen.png) 50% 50% no-repeat;
    display: block;
    margin: auto;
    cursor: pointer;
    float: right;
}

.chocolat-wrapper .chocolat-description {
    display: inline-block;
    float: left;
}

// no container mode
body.chocolat-open > .chocolat-overlay {
    z-index: 15;
}

body.chocolat-open > .chocolat-loader {
    z-index: 15;
}

body.chocolat-open > .chocolat-content {
    z-index: 17;
}

// container mode
.chocolat-in-container .chocolat-wrapper,
.chocolat-in-container .chocolat-content,
.chocolat-in-container .chocolat-overlay {
    position: absolute;
}

.chocolat-in-container {
    position: relative;
}