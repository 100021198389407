

.home__blog .blog-widget-recent {

    .title {
        text-align: center;
    }

    .post-list {
        display: flex;
    }

    .post-holder {
        position: relative;
        width: 33%;
        float: none;
        flex: 1 1 0;
        padding: 0 15px 20px;
        background-color: transparent;

        &:hover {
            box-shadow: none;
        }
    }

    .post-content {
        margin-top: 30px;
    }

    .post-posed-date {

        span {
            color: $c-text-light;
        }
    }

    .post-title {
        margin: 12px 0;

        .post-item-link {
            text-transform: uppercase;
            color: $c_text-dark;
            font-size: 18px;
            font-weight: bold;
        }
    }

    .post-description {

        img,
        video,
        iframe {
            display: none !important;
        }

        .post-text-hld {
            max-height: 143px;
            min-height: 143px;
            overflow-y: hidden;
        }
    }

    .post-read-more {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 15px;
    }

    @media screen and (max-width: $screen__m) {

        .post-list {
            flex-direction: column;
        }

        .post-holder {
            width: 100%;
            padding-bottom: 0;
        }

        .post-holder + .post-holder {
            margin-top: 40px;
        }

        .post-description {

            .post-text-hld {
                max-height: 143px;
                min-height: 0;
            }
        }

        .post-read-more {
            margin-top: 15px;
            position: static;
            padding: 0;
        }
    }
}
