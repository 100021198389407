@media only screen and (max-width: 767px) {
    .filter-content {
        .active {
            .am-show-more {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
            }
        }
    }
    .am-porto-cmtb {
        .filter.active {
            .filter-current.filter-current.amshopby-filter-current+.block-actions {
                top: 187px;
                border: 0;
                border-radius: inherit;
                background: #fff;
                box-shadow: none;
            }
            .filter-current.amshopby-filter-current {
                padding: 17px 30px;
                height: 140px;
                .items {
                    padding: 15px;
                    border-width: 1px;
                }
                .filter-current-subtitle {
                    display: block;
                    padding: 10px 15px;
                }
                ~ {
                    .filter-options {
                        top: 221px;
                    }
                }
            }
        }
    }
}
@media all and (min-width: 768px) {
    .ui-tooltip.ui-widget {
        max-width: 450px;
    }
    .filter-options-content {
        a {
            &:hover {
                text-decoration: none;
            }
        }
        .am-filter-price {
            width: 40%;
        }
    }
    .catalog-topnav {
        display: block;
    }
    .page-layout-1column {
        .toolbar-products {
            position: inherit !important;
        }
    }
    #amasty-shopby-product-list {
        #amasty-shopby-overlay {
            .loader {
                top: 30%;
                transform: translate(-50%,-30%);
            }
        }
    }
    .sidebar {
        >.block-category-list {
            margin-bottom: 14px;
        }
    }
    .amasty-catalog-topnav {
        margin-top: 3px;
        .filter-options {
            margin-top: 20px;
            padding: 3px 0;
            border-top: 1px solid #ccc;
        }
        .filter-subtitle {
            display: none;
        }
        .amshopby-category-dropdown {
            min-width: 200px;
        }
        .filter-options-item {
            margin-bottom: 0;
        }
        .filter-options-title {
            padding-right: 5px;
            &:after {
                position: static;
                display: inline-block;
                margin: 0 0 0 15px;
            }
        }
    }
    .amasty-catalog-topnav.catalog-topnav {
        .filter-options {
            .filter-options-content {
                min-width: 200px;
            }
        }
    }
    .am_shopby_apply_filters {
        position: absolute;
        bottom: inherit;
        z-index: 9999;
        display: none;
        visibility: hidden;
        padding: 0;
        width: inherit;
    }
    .am_shopby_apply_filters.visible {
        bottom: inherit;
        visibility: visible;
    }
    .am_shopby_apply_filters.-fixed {
        position: fixed;
        .am-show-button {
            border: 1px solid #ccc;
            border-radius: 3px;
            text-align: center;
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
    }
    .am-show-button {
        position: static;
        margin: 0 0 10px;
        padding: 10px;
        width: inherit;
        border: 1px solid #ccc;
        >.am-button {
            margin-left: 3px;
            padding: 10px 15px;
        }
    }
    .am-show-button.-vertical {
        padding-left: 0;
        border-left: 0;
        border-radius: 0 3px 3px 0;
        box-shadow: 10px 8px 16px rgba(0,0,0,0.16);
        &:before {
            position: absolute;
            top: 8px;
            left: -20px;
            z-index: -1;
            display: block;
            box-sizing: border-box;
            width: 40px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 0 0 0 3px;
            background: #eee;
            box-shadow: 7px 6px 16px rgba(0,0,0,0.16);
            content: '';
            transform: rotate(45deg);
            transform-origin: 50%;
        }
    }
    .am-show-button.-vertical-right {
        padding: 10px 0;
        min-width: 145px;
        border-right: 0;
        border-radius: 3px 0 0 3px;
        box-shadow: -8px 8px 16px rgba(0,0,0,0.16);
        text-align: right;
        >.-loading {
            margin: 0 20px;
        }
        &:before {
            position: absolute;
            top: 8px;
            right: -20px;
            z-index: -1;
            display: block;
            box-sizing: border-box;
            width: 40px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 0 0 0 3px;
            background: #eee;
            box-shadow: 5px 6px 16px rgba(0,0,0,0.16);
            content: '';
            transform: rotate(45deg);
            transform-origin: 50%;
        }
        >.am-button {
            margin-left: 5px;
        }
    }
    .am-show-button.-horizontal {
        border-radius: 4px;
        box-shadow: 0 5px 16px rgba(0,0,0,0.16);
        &:before {
            position: absolute;
            left: 50%;
            display: block;
            border: 6px solid transparent;
            content: '';
            transform: rotate(45deg) translateX(-50%);
            bottom: 0;
            border-right: 6px solid #ccc;
            border-bottom: 6px solid #ccc;
            border-radius: 0 0 3px 0;
        }
        &:after {
            position: absolute;
            left: 50%;
            display: block;
            border: 6px solid transparent;
            content: '';
            transform: rotate(45deg) translateX(-50%);
            bottom: 1px;
            border-right: 6px solid #eee;
            border-bottom: 6px solid #eee;
            border-radius: 0 0 2px 0;
        }
    }
}
@media print {
    .ui-tooltip.ui-widget {
        max-width: 450px;
    }
    .filter-options-content {
        a {
            &:hover {
                text-decoration: none;
            }
        }
        .am-filter-price {
            width: 40%;
            width: 43%;
        }
    }
    .catalog-topnav {
        display: block;
    }
    .page-layout-1column {
        .toolbar-products {
            position: inherit !important;
        }
    }
    #amasty-shopby-product-list {
        #amasty-shopby-overlay {
            .loader {
                top: 30%;
                transform: translate(-50%,-30%);
            }
        }
    }
    .sidebar {
        >.block-category-list {
            margin-bottom: 14px;
        }
    }
    .amasty-catalog-topnav {
        margin-top: 3px;
        .filter-options {
            margin-top: 20px;
            padding: 3px 0;
            border-top: 1px solid #ccc;
        }
        .filter-subtitle {
            display: none;
        }
        .amshopby-category-dropdown {
            min-width: 200px;
        }
        .filter-options-item {
            margin-bottom: 0;
        }
        .filter-options-title {
            padding-right: 5px;
            &:after {
                position: static;
                display: inline-block;
                margin: 0 0 0 15px;
            }
        }
    }
    .amasty-catalog-topnav.catalog-topnav {
        .filter-options {
            .filter-options-content {
                min-width: 200px;
            }
        }
    }
    .am_shopby_apply_filters {
        position: absolute;
        bottom: inherit;
        z-index: 9999;
        display: none;
        visibility: hidden;
        padding: 0;
        width: inherit;
    }
    .am_shopby_apply_filters.visible {
        bottom: inherit;
        visibility: visible;
    }
    .am_shopby_apply_filters.-fixed {
        position: fixed;
        .am-show-button {
            border: 1px solid #ccc;
            border-radius: 3px;
            text-align: center;
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
    }
    .am-show-button {
        position: static;
        margin: 0 0 10px;
        padding: 10px;
        width: inherit;
        border: 1px solid #ccc;
        >.am-button {
            margin-left: 3px;
            padding: 10px 15px;
        }
    }
    .am-show-button.-vertical {
        padding-left: 0;
        border-left: 0;
        border-radius: 0 3px 3px 0;
        box-shadow: 10px 8px 16px rgba(0,0,0,0.16);
        &:before {
            position: absolute;
            top: 8px;
            left: -20px;
            z-index: -1;
            display: block;
            box-sizing: border-box;
            width: 40px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 0 0 0 3px;
            background: #eee;
            box-shadow: 7px 6px 16px rgba(0,0,0,0.16);
            content: '';
            transform: rotate(45deg);
            transform-origin: 50%;
        }
    }
    .am-show-button.-vertical-right {
        padding: 10px 0;
        min-width: 145px;
        border-right: 0;
        border-radius: 3px 0 0 3px;
        box-shadow: -8px 8px 16px rgba(0,0,0,0.16);
        text-align: right;
        >.-loading {
            margin: 0 20px;
        }
        &:before {
            position: absolute;
            top: 8px;
            right: -20px;
            z-index: -1;
            display: block;
            box-sizing: border-box;
            width: 40px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 0 0 0 3px;
            background: #eee;
            box-shadow: 5px 6px 16px rgba(0,0,0,0.16);
            content: '';
            transform: rotate(45deg);
            transform-origin: 50%;
        }
        >.am-button {
            margin-left: 5px;
        }
    }
    .am-show-button.-horizontal {
        border-radius: 4px;
        box-shadow: 0 5px 16px rgba(0,0,0,0.16);
        &:before {
            position: absolute;
            left: 50%;
            display: block;
            border: 6px solid transparent;
            content: '';
            transform: rotate(45deg) translateX(-50%);
            bottom: 0;
            border-right: 6px solid #ccc;
            border-bottom: 6px solid #ccc;
            border-radius: 0 0 3px 0;
        }
        &:after {
            position: absolute;
            left: 50%;
            display: block;
            border: 6px solid transparent;
            content: '';
            transform: rotate(45deg) translateX(-50%);
            bottom: 1px;
            border-right: 6px solid #eee;
            border-bottom: 6px solid #eee;
            border-radius: 0 0 2px 0;
        }
    }
    .amslider-container.am-swiper-slider {
        width: 85%;
    }
}
@media all and (min-width: 1024px) {
    .filter-options-content {
        .am-filter-price {
            width: 43%;
        }
    }
    .amslider-container.am-swiper-slider {
        width: 85%;
    }
}
.ui-tooltip {
    .arrow {
        left: 8px;
        width: 15px;
        &:after {
            border-right: 15px solid transparent;
        }
        &:before {
            border-right: 14px solid transparent;
        }
    }
}
.amslider-container {
    margin: auto;
    margin-bottom: 15px;
    width: 100%;
}
.am-rating-up {
    font-size: smaller;
}
.amshopby-hightlighted {
    background-color: #ff0;
}
.amshopby-item {
    .filter-value {
        margin-left: 5px;
    }
}
.amshopby-filter-tooltip {
    display: inline-block;
    margin-left: 6px;
    max-width: 20px;
    max-height: 20px;
    vertical-align: middle;
}
.filter-options-content {
    .delimiter {
        margin: 0 5px;
        line-height: 30px;
    }
    .range {
        display: flex;
        align-items: center;
        margin-top: 10px;
        text-align: center;
    }
    .am-filter-price {
        padding: 5px;
        max-width: 50px;
        width: 46%;
    }
    .items {
        a {
            margin-left: 0;
        }
    }
    .count {
        display: inline-flex;
    }
    .am-shopby-swatch-label {
        .count {
            margin: 0;
            padding: 0;
        }
        .label {
            margin-right: 5px;
        }
    }
    .am-shopby-form {
        overflow-x: hidden;
        overflow-y: auto;
        padding: 3px;
    }
    .am-swatch-wrapper {
        display: inline-block;
        &:hover {
            .am-swatch-link {
                background: 0;
            }
        }
        >.input.-hidden {
            display: none;
        }
    }
}
.am-show-more {
    transition: .1s all ease-in;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    padding: 5px 25px 10px 0;
    color: inherit;
    cursor: pointer;
    &:hover {
        background-color: transparent;
        color: #006bb4;
    }
    &:before {
        transition: .1s all ease-in;
        position: absolute;
        right: 0;
        width: 8px;
        height: 1px;
        background: #006bb4;
        content: '';
        right: 5px;
        transform: rotate(45deg);
    }
    &:after {
        transition: .1s all ease-in;
        position: absolute;
        right: 0;
        width: 8px;
        height: 1px;
        background: #006bb4;
        content: '';
        transform: rotate(-45deg);
    }
    .-active {
        display: flex;
    }
    .am-counter {
        display: inline-block;
        margin: 0 2px;
        opacity: .5;
    }
}
.am-show-more[data-is-hide='false'] {
    &:before {
        right: 6px;
        transform: rotate(-45deg);
    }
    &:after {
        transform: rotate(45deg);
    }
}
.range.am-fromto-widget {
    .am-filter-go {
        margin: 0 10px;
        vertical-align: top;
    }
}
.am-filter-go {
    margin: 15px 0 0;
    width: 25%;
}
input[type='text'].am-filter-price.-to.right {
    float: right;
}
.amshopby-slider-container {
    margin: 10px 15px 15px 5px;
    .ui-slider-range {
        position: absolute;
        height: 10px;
        background: #b6b6b6;
    }
    .am-slider {
        .ui-slider-handle {
            margin-top: -5px;
            margin-left: -5px;
            padding: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #f98b25;
            &:hover {
                background-color: #e17e17;
            }
            &:active {
                background-color: #e17e17;
            }
        }
    }
    .amshopby-slider-display {
        text-align: center;
    }
}
.am-swatch-options {
    display: flex;
    flex-direction: column;
    .swatch-option {
        display: inline-block;
        margin: 0 10px 0 0;
        background-position: center;
        background-repeat: no-repeat;
    }
    >.item {
        padding-bottom: 4px;
        &:hover {
            background-color: transparent;
        }
    }
    .am-shopby-swatch-label {
        display: inline-block;
        max-width: 70%;
        vertical-align: middle;
        word-wrap: break-word;
    }
}
.filter-options {
    .am-swatch-wrapper {
        margin: 0 0 5px 0 !important;
        line-height: initial;
    }
    .chosen-container-multi {
        .chosen-choices {
            li.search-choice {
                .search-choice-close {
                    top: 5px;
                    right: 8px;
                }
            }
        }
    }
    .items {
        .items-children.-folding {
            padding-left: 18px;
            list-style: none;
            .item {
                margin: 5px 0;
            }
        }
    }
    .items.amshopby-fly-out-view {
        .items-children {
            z-index: 9999;
            background-color: #fff;
        }
        li {
            margin: 0;
            border: 1px solid #ccc;
            border-top: 0;
            &:hover {
                background-color: #ccc;
            }
            &:first-child {
                border-top: 1px solid #ccc;
            }
        }
        a {
            &:hover {
                background-color: transparent;
            }
        }
    }
    .am-ranges {
        overflow-x: hidden;
        overflow-y: auto;
    }
    .ui-widget-overlay {
        background: #aaa;
        opacity: .3;
    }
    .ui-widget-shadow {
        box-shadow: 0 0 5px #666;
    }
    .am-category-view {
        position: absolute;
        z-index: 9;
        display: none;
        overflow-y: auto;
        box-sizing: border-box;
        margin: 0;
        padding: 10px;
        max-height: 300px;
        width: 100%;
        border: 1px solid #ccc;
        background-color: white;
        white-space: nowrap;
        ol.items {
            margin: 0;
        }
        .item {
            a.amshopby-filter-parent {
                padding-left: 20px;
            }
        }
        a {
            display: inline-block;
            white-space: initial;
        }
    }
    .items.am-category-view {
        margin: 0;
    }
    .filter-options-content {
        .rating-summary {
            display: inline-block;
            vertical-align: sub;
        }
    }
    .amshopby-search-box {
        margin: 0 0 15px;
    }
    .am-labels-folding {
        .item {
            position: relative;
        }
    }
    .ui-menu {
        display: block;
        margin: 0;
        padding: 0;
        outline: 0;
        list-style: none;
        .ui-menu {
            position: absolute;
        }
        .ui-menu-item {
            margin: 0;
            list-style: none;
            cursor: pointer;
        }
        .ui-menu-item-wrapper {
            position: relative;
            padding: 3px 1em 3px .4em;
        }
        .ui-menu-divider {
            margin: 5px 0;
            height: 0;
            border-width: 1px 0 0 0;
            font-size: 0;
            line-height: 0;
        }
    }
}
.catalog-topnav {
    .filter-options {
        flex-wrap: wrap;
        min-height: 40px;
        border-bottom: 1px solid #ccc;
        .filter-options-item {
            display: inline-block;
            border: 0;
        }
        .filter-options-content {
            position: absolute;
            z-index: 9999;
            margin-top: 5px;
            min-width: 100px;
            border: 1px solid #ccc;
            background-color: white;
        }
        .am-filter-items-rating {
            li {
                margin: 0 0 10px;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    .block-actions {
        display: none;
    }
    display: none;
}
.page-layout-1column {
    .filter-options-item.active {
        .filter-options-content {
            padding: 10px 15px;
        }
    }
    .filter-options-content {
        li.item {
            padding: 0;
        }
    }
}
.filter {
    [data-role='collapsible'] {
        &:not([data-collapsible='true']) {
            [data-role='content'] {
                display: none;
            }
        }
    }
}
div.swatch-option-tooltip {
    z-index: 9999;
}
.swatch-option-tooltip {
    .image {
        background-size: contain !important;
    }
}
.swatch-option-tooltip-layered {
    .image {
        background-size: contain !important;
    }
}
.am-filter-items-attr_price {
    min-width: 130px;
    max-width: 350px;
}
.am-filter-items-attr_color {
    .swatch-option.text {
        padding: 1px 2px;
        width: 30px;
    }
}
.amasty-catalog-topnav.block.filter {
    flex-basis: auto;
}
.amasty-catalog-topnav {
    .filter-options-content {
        .amshopby-fly-out-view {
            a {
                display: block;
                box-sizing: border-box;
            }
        }
    }
}
.am-filter-items-stock {
    .am_shopby_link_selected {
        background-color: #e8e8e8;
        text-decoration: none;
    }
}
.am-filter-items-rating {
    .am_shopby_link_selected {
        background-color: #e8e8e8;
        text-decoration: none;
    }
}
.am_shopby_link_selected {
    font-weight: bold;
}
.amshopby-fly-out-view.ui-menu {
    position: relative;
}
.amshopby-fly-out-view {
    .ui-menu {
        width: 100%;
    }
    .items-children {
        &:not(.ui-menu) {
            display: none;
        }
    }
    a {
        display: inline-block;
        padding: 10px;
    }
    input[type='checkbox'] {
        margin: 3px 3px 3px 4px;
    }
    input[type='radio'] {
        margin: 3px 3px 3px 4px;
    }
}
.amshopby-category-dropdown {
    display: block;
    overflow: hidden;
    padding-right: 3.6rem;
    min-height: 3.2rem;
    border: 1px solid #adadad;
    background-color: #fff;
    text-align: left;
    text-overflow: ellipsis;
    white-space: normal;
    font-weight: 400;
    cursor: pointer;
    &:after {
        position: absolute;
        top: 50%;
        right: 1.2rem;
        box-sizing: border-box;
        margin-top: -0.2rem;
        width: 0;
        height: 0;
        border-width: .5rem .4rem 0 .4rem;
        border-style: solid;
        border-color: #000 transparent transparent;
        content: '';
        transition: all .2s linear;
    }
    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 3.2rem;
        border: 1px solid #adadad;
        background-color: #e3e3e3;
        content: '';
    }
}
.am-category-wrapper {
    position: relative;
    display: block;
    user-select: none;
    .amshopby-checkbox {
        margin-left: 17px;
    }
    .amshopby-radio {
        margin-left: 17px;
    }
    .amshopby-filter-parent {
        padding-left: 0 !important;
    }
    .am-multiselect-crumb {
        position: relative;
        display: inline-block;
        margin: .3rem 0 .3rem .3rem;
        padding: .3rem 2.4rem .4rem 1rem;
        border: 1px solid #a79d95;
        border-radius: 1px;
        background-color: #f5f5f5;
        font-size: 1.2rem;
        transition: border-color .1s linear;
        .action-close {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            padding: 0;
            width: 2rem;
            height: auto;
            border: 0;
            border-radius: 0;
            background-color: transparent;
            box-shadow: none;
            color: #514943;
            font-size: .5em;
            line-height: 1;
            &:before {
                content: 'x';
                font-size: 10px;
                transition: color .1s linear;
            }
            >span {
                position: absolute;
                display: none;
                visibility: hidden;
                overflow: hidden;
                clip: rect(0,0,0,0);
            }
        }
    }
}
.am-category-wrapper._active {
    .amshopby-category-dropdown {
        &:after {
            transform: rotate(180deg);
        }
    }
}
.am-labels-folding {
    .am-collapse-icon {
        left: -20px;
    }
}
.am-collapse-icon {
    position: absolute;
    box-sizing: border-box;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    &:after {
        position: absolute;
        top: 50%;
        right: 1.2rem;
        box-sizing: border-box;
        margin-top: -0.5rem;
        width: 0;
        height: 0;
        border-width: .5rem .4rem 0 .4rem;
        border-style: solid;
        border-color: #000 transparent transparent;
        content: '';
        transition: all .2s linear;
        transform: rotate(-90deg);
    }
}
.am-collapse-icon._active {
    &:after {
        transform: rotate(0);
    }
}
#amasty-shopby-product-list {
    z-index: 1;
    .products.wrapper {
        position: relative;
    }
    #amasty-shopby-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 999;
        display: none;
        width: 100%;
        background: #fff;
        opacity: .5;
        .loader {
            position: absolute;
            top: 10%;
            left: 50%;
            display: block;
            width: 64px;
            height: 64px;
            background-image: url('../Amasty_Shopby/images/loader-1.gif');
            transform: translate(-50%,-10%);
            &:before {
                content: '';
            }
        }
    }
}
.amshopby-option-link {
    display: inline-block;
    clear: both;
    margin-right: 5px;
    img {
        float: left;
        margin: 0 5px 5px 0;
        &:not([src*='/resized']) {
            max-width: 30px;
            max-height: 30px;
        }
    }
}
.am_shopby_apply_filters {
    position: fixed;
    bottom: -80px;
    z-index: 9999;
    width: 100%;
    transition: left .2s ease;
}
.am_shopby_apply_filters.visible {
    bottom: 0;
}
.am-show-button {
    z-index: 999;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 15px 0;
    width: 100%;
    background: #eee;
    text-align: center;
    >.am-items {
        font-size: 15px;
    }
    >.am-items.-loading {
        display: inline-block;
        margin: 0 5px;
        width: 25px;
        height: 25px;
        background-image: url('../Amasty_Shopby/images/loader-1.gif');
        background-size: contain;
        vertical-align: middle;
    }
    >.am-button {
        margin-left: 15px;
        padding: 17px 50px;
        height: auto;
        border: 0;
        border-radius: 5px;
        background: #ff9416;
        box-shadow: none;
        color: #fff;
        text-align: center;
        font-size: 14px;
        line-height: 1;
        &:hover {
            background: #e27a00;
            transition: background .3s ease;
        }
    }
}
.am-item-removed {
    text-decoration: line-through;
}
.am-category-image {
    vertical-align: middle;
}
.amshopby-filter-current {
    .filter-label {
        vertical-align: middle;
    }
    .amshopby-items {
        padding: 0 0 0 10px;
    }
    .amshopby-item.item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 0 0 20px;
    }
    .amshopby-filter-value {
        max-width: 100%;
        .am-swatch-options {
            .swatch-option {
                margin: 0;
            }
        }
    }
    .am-swatch-link {
        display: flex;
        align-items: center;
    }
    .amshopby-remove {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        width: 12px;
        height: 12px;
        &:hover {
            &:after {
                background-color: #006bb4;
            }
            &:before {
                background-color: #006bb4;
            }
        }
        &:after {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #757575;
            content: '';
            transform: rotate(45deg);
        }
        &:before {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #757575;
            content: '';
            transform: rotate(-45deg);
        }
    }
    .am-shopby-swatch-label {
        margin-left: 10px;
    }
    .swatch-option-link-layered {
        display: flex;
        align-items: center;
    }
    .item {
        padding-bottom: 0;
    }
}
.am-dropdown {
    .am-select {
        min-height: 32px;
        border-color: #ccc;
        background-color: #fff;
        font-size: 14px;
        font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
    }
    .chosen-container {
        .chosen-choices {
            min-height: 32px;
            border-color: #ccc;
            background-color: #fff;
            font-size: 14px;
            font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
            background-image: none;
        }
    }
    .chosen-container-multi {
        .chosen-choices {
            li.search-field {
                input[type='text'] {
                    min-width: 100px;
                    color: #000;
                    font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
                }
            }
        }
    }
}
.am-porto-cmtb {
    .amshopby-filter-current.filter-current {
        .amshopby-item.item {
            display: flex;
            align-items: center;
            padding: 0 0 10px 20px;
        }
        .items {
            padding: 15px;
        }
        .am-show-more {
            padding: 12px 25px 0 0;
        }
        .category-image {
            .image {
                display: block;
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
    }
    .filter-options-content {
        .swatch-option {
            min-width: 22px;
            width: 22px;
            height: 22px;
            border: 0;
            background-position: center center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            box-shadow: 0 0 3px rgba(0,0,0,0.2);
        }
    }
    .page-wrapper {
        >.page-header {
            z-index: 9005;
        }
    }
    .page-main {
        z-index: 9;
    }
}
