/**
 * Copyright © 2015 Magento. All rights reserved.
 * See COPYING.txt for license details.
 */

/* VARIABLES */
$main_color: #df5d24;
$line_color: #e8e8e8;
$text_color: #000;
$text_light: #FFF;
$text_night: #202020;
$text_hover: #999;
$text_women: #844a9e;
$text_font: 'Lato', sans-serif;

$font_18px: 1.00rem;
$font_20px: 1.12rem;
$font_24px: 1.33rem;
$font_28px: 1.56rem;
$font_32px: 1.77rem;
$font_36px: 2.00rem;
$font_48px: 2.60rem;
$font_64px: 3.43rem;
$font_72px: 4.00rem;

/* RESPONSIVE */
@media only screen and (min-width: 1367px) {
    html {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1366px) {
    html, .font-size-16px {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1199px) {
    .content-wrapper, .breadcrumbs {
        max-width: 960px;
    }

    /* CMS */
    .cms-page-view {
        /* PRIORITY */
        .column.main {
            .service-form {
                .if-main-frame {
                    padding-bottom: 0;

                    .container {
                        max-width: 960px;
                    }
                }
            }
        }

        /* COMPANY */
        .company-slider-section {
            padding-bottom: 25px;

            .timeline-wrap {
                height: 380px;
            }

            .timeline-nav {
                top: auto;
                bottom: 0;

                div {
                    display: block;
                    position: static;
                    height: auto;

                    ul {
                        display: block;
                        margin: 0;
                        text-align: center;

                        li {
                            display: inline-block;
                            margin: 0 2px;
                        }
                    }
                }
            }

            .timeline {
                padding-left: 20px;
                padding-bottom: 100px;

                &:before {
                    left: 0;
                }

                .timeline-items {
                    padding-top: 0;
                    margin-bottom: 0;

                }

                .timeline-item:not(.inverted),
                .timeline-item.inverted {
                    left: 0;
                    margin: 0;
                    width: 100%;
                    float: none;
                    right: auto;
                    transition: none !important;
                    animation-duration: 0ms !important;

                    after {
                        content: none;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 961px) {

    .fillwidth-mobile {
        width: -webkit-calc(50vw - 50%);
        width: calc(50vw - 50%);
    }
}

@media only screen and (min-width: 1024px) {
    /* BLOG */
    .blog-post-view {
        .post-view {
            position: relative;
        }

        .share-box {
            position: absolute;
            top: 58px;
            left: 0;
            display: block;
            width: 72px;
            margin-top: 0;

            h4 {
                margin: 0;
                padding: 5px;
                color: $text_light;
                background-color: $main_color;
                text-align: center;
                text-transform: uppercase;
                font-size: 12px;
            }

            .post-sharing-button {
                div {
                    display: block;
                    width: 100%;
                    height: 72px;
                    margin-right: 0;
                    padding: 12px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .fullwidth {
        margin-left: -webkit-calc(50% - 50vw);
        margin-left: calc(50% - 50vw);
        margin-right: -webkit-calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
    }

    .fillwidth {
        width: -webkit-calc(50vw - 50%);
        width: calc(50vw - 50%);
    }

    /* WISHLIST */
    body.account {
        .products-grid.wishlist {
            + .actions-toolbar {
                .primary {
                    text-align: left;

                    button.action {
                        display: block;
                        width: 100%;
                        max-width: 280px;
                        margin-bottom: 5px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .block.widget .products-grid .product-items, .block.widget .products-grid .product-items {
        margin-left: -5px;
    }

    /* CMS */
    .cms-page-view {
        /* GIFT CARD */
        .gift-card-content {
            .gift-list-block {
                .img-block {
                    width: 350px;
                }

                .text-block {
                    width: calc(100% - 400px);
                }
            }
        }

        /* COMPANY */
        .company-slider-section {
            > div {
                background-color: rgba(0, 0, 0, 0.75);
            }

            .page-main:first-child {
                background-color: transparent;
            }

            .timeline-nav {
                padding-top: 5px;
            }

            .timeline {
                .timeline-items {
                    padding-top: 40px;
                }
            }
        }
    }

    .cms-page-view {
        .service-form {
            .popup, .terms-block-popup {
                top: 105px !important;
                height: -webkit-calc(100% - 120px) !important;
                height: calc(100% - 120px) !important;
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    /* BLOG */
    .blog-post-view {
        .share-box {
            margin-top: 0;
            padding-bottom: 60px;

            h4 {
                margin-top: 0;
                margin-bottom: 1.5rem;
                font-size: 1.12rem;
                text-transform: uppercase;
            }

            .post-sharing-button {
                div {
                    width: 48px;

                    &.icon-fb {
                        background-color: #3b5998;
                    }

                    &.icon-tw {
                        background-color: #4099ff;
                    }
                }
            }
        }
    }

    /* CMS */
    .cms-page-view {
        /* COMPANY */
        .company-slider-section {
            .timeline {
                &:before {
                    top: 120px;
                    bottom: 97px;
                }

                h2:first-child {
                    margin-bottom: 25px;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    /* CMS */
    .cms-page-view {
        /* STORE */
        .store-service {
            .row.service-list {
                > div {
                    &:nth-of-type(n+4) {
                        margin-top: 40px;
                    }
                }
            }
        }

        /* COMPANY */
        .company-ambassador {
            ul.ambassador-list {
                li.ambassador-item {
                    width: 33.33%;
                }
            }
        }
    }
}

@media only screen and (max-width: 960px) {

    .fillwidth-mobile-2 {
        width: -webkit-calc(50vw - 50%);
        width: calc(50vw - 50%);
    }

    .fullwidth {
        margin-left: -20px;
        margin-right: -20px;

        &.category-view {
            margin-left: 0;
            margin-right: 0;
        }
    }

    /* CMS */
    .cms-page-view {
        .store-top .store-street-view {
            margin-left: 0;
            margin-right: 0;
        }

        /* BRANDS */
        .brands-content {
            dl {
                dd {
                    .bare-list {
                        li.brand-item {
                            display: block;
                            width: 100%;
                            padding-right: 0;

                            .count {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    /* CMS */
    .cms-page-view {
        /* COMPANY */
        .company-slider-section {
            .timeline {
                &:before {
                    top: 120px;
                    bottom: 97px;
                }

                h2:first-child {
                    margin-bottom: 25px;
                }
            }
        }
    }
}

@media only screen and (min-width: 801px) {
    /* ACCOUNT */
    body.account, body[class^="customer-account"], body.sendfriend-product-send {
        #maincontent {
            .page-title-wrapper {
                padding-left: 22.3%;
            }

            .sidebar.sidebar-main {
                position: relative;

                &:before {
                    content: '';
                    position: fixed;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 0;
                    height: 100%;
                    background-color: #f5f5f5;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {

    .clear-float-mobile {
        content: '';
        clear: both;
        display: block;
        width: 100%;
        height: 0;
    }

    /* ACCOUNT */
    body.account, body[class^="customer-account"], body.sendfriend-product-send {
        #maincontent {
            .column.main, .sidebar.sidebar-main {
                clear: both;
                float: none;
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                padding-right: 0;
            }
        }

        .products-grid.wishlist {
            .product-items {
                margin-left: 0;
            }
        }
    }

    /* CMS */
    .cms-page-view {
        /* STORE */
        .store-service, .store-content {
            .service-box {
                .service-title {
                    left: 50%;
                    max-width: 280px;
                    margin-top: 0;
                    margin-left: -140px;

                    &:before {
                        display: none;
                    }
                }

                img {
                    max-width: 50%;

                    &:hover + .service-title {
                        top: 50%;
                        margin-top: -20px;
                    }
                }
            }
        }
    }

    /* BLOG */
    .blog-index-index {
        .post-list-item {
            width: -webkit-calc(50% - 4px);
            width: calc(50% - 4px);
            padding: 0 0 50% 0;

            &:first-child, &:nth-child(4) {
                width: -webkit-calc(50% - 4px);
                width: calc(50% - 4px);
            }

            .mp-post-info {
                padding: 10px;
            }
        }

        .post-item-wrapper {
            .post-info-wrapper {
                h2.mp-post-title {
                    line-height: 1.95;
                }

                .post-short-description {
                    padding: 15px 10px;
                }
            }
        }

        .mp-sidebar-most-view-recent {
            #mostview, #mostrecent {
                .list-post-tabs {
                    .post-left {
                        height: auto;
                        background-image: none !important;

                        img {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }

    .blog-category-view, .blog-tag-view {
        .column.main, .sidebar.sidebar-additional {
            width: 100%;
        }

        .mp-sidebar-most-view-recent {
            clear: both;
            margin-bottom: 50px;
            padding-top: 40px;

            .tab-content {
                padding-top: 40px;
                border-top: 1px solid $line_color;

                &:after {
                    @extend .clear-float-mobile;
                }
            }

            #mostview, #mostrecent {
                display: block;
                width: 50%;

                .list-post-tabs {
                    display: inline-flex;
                    padding: 20px 0;
                    border-bottom: 1px solid $line_color;
                    color: inherit;
                    width: 100%;

                    > div {
                        float: left;
                    }

                    .post-left {
                        height: auto;
                        background-image: none !important;

                        img.img-responsive {
                            visibility: visible;
                        }
                    }

                    .post-right {
                        padding: 0 15px;

                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                        }

                        span:first-child {
                            color: $main_color;
                            font-size: $font_20px;
                            white-space: normal;
                            line-height: 1;
                        }

                        span:last-child {
                            color: $text_color;
                            font-size: 12px;
                            font-weight: 700;
                            text-transform: uppercase;
                        }
                    }

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }

            #mostrecent {
                float: left;
                padding-right: 15px;
            }

            #mostview {
                float: right;
                padding-left: 15px;
            }
        }
    }

    .blog-post-view {
        .post-post_meta > div span {
            display: block;
        }
    }
}

@media only screen and (max-width: 768px) {
    .block-static-block section.web-notification {
        .col-xs-12 {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .column.main {
        max-width: 100%;
    }

    /* CMS */
    .cms-page-view {
        /* PRIORITY */
        .column.main {
            .service-form {
                .if-main-frame {
                    .container {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (width: 768px) {

    /* CMS */
    .cms-page-view {
        /* PRIORITY */
        .column.main {
            .service-form {
                .if-main-frame {
                    .container {
                        margin-left: -20px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {

    .font-size-16px-mobile {
        font-size: 14px;
    }

    /* ACCOUNT */
    body.account, body[class^="customer-account"], body.sendfriend-product-send {
        #maincontent {
            .form, [class^="form"] {
                .field {
                    .label {
                        width: auto;

                        &:after {
                            position: static;
                        }

                        span {
                            max-width: none !important;
                        }
                    }
                }
            }

            .social-login-authentication-channel {
                margin-left: 0;

                .login-optional {
                    text-align: center;

                    span {
                        padding: 16px 48px;
                    }
                }

                .actions-toolbar.social-btn {
                    width: 100%;

                    > .primary .action {
                        height: auto;
                    }
                }
            }
        }
    }

    /* CMS */
    .cms-page-view {
        /* STORE */
        .store-top {
            .store-list {
                li {
                    display: block;
                    width: 100%;

                    span.current-store:after {
                        display: none;
                    }
                }
            }
        }

        /* BRANDS */
        .brands-content {
            .content > div {
                width: 100% !important;

                dl {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }
        }

        /* GIFT CARD */
        .gift-card-content {
            .gift-title-content {
                float: none;
                width: 100%;
            }

            .gift-ex-card {
                float: none;
                width: 100%;
                max-width: 350px;
                margin: 40px auto 0;
            }

            .gift-list-block {
                .img-block {
                    float: none;
                    width: 100%;
                    max-width: 300px;
                    margin: 0 auto;
                }

                .text-block {
                    float: none;
                    width: 100%;
                    margin-top: 40px;
                }
            }
        }

        /* EVENTS */
        .events {
            ul {
                li {
                    width: -webkit-calc(50% - 20px);
                    width: calc(50% - 20px);
                    padding-bottom: -webkit-calc(50% - 20px);
                    padding-bottom: calc(50% - 20px);
                }
            }
        }


        /* CLUBS */
        .club-content {
            .club-logo-wrapper {
                margin-bottom: 40px;
            }
        }

        /* IMPLICATION */
        .implication-featured {
            > div {
                margin-bottom: 20px !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }

    /* BLOG */
    .blog-index-index, .blog-category-view, .blog-tag-view {
        .mp-sidebar-most-view-recent {
            #mostview, #mostrecent {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .blog-index-index {
        .post-list-item {
            width: 100%;
            margin: 0 0 15px;

            &:first-child, &:nth-child(4) {
                width: 100%;
            }
        }

        .post-item-wrapper {
            .post-info-wrapper {
                h2.mp-post-title {
                    line-height: 1.95;
                }

                .post-short-description {
                    padding: 15px 10px;
                }
            }
        }
    }

    .blog-category-view, .blog-tag-view {
        .post-item-wrapper {
            .post-info-wrapper {
                padding: 15px 0;
            }
        }
    }

    .blog-post-view {
        .related-post {
            li {
                display: block;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .block-static-block section.web-notification {
        height: 60px;

        .notification-content {
            padding-right: 32px;
        }
    }

    .widget.block.block-static-block ~ .page-wrapper {
        .page-header {
            margin-top: 60px;
        }
    }

    /* CMS */
    .cms-page-view {
        /* STORE */
        .store-service {
            .row.service-list {
                > div {
                    width: 50%;

                    &:nth-of-type(n+3) {
                        margin-top: 40px;
                    }
                }
            }
        }

        /* COMPANY */
        .company-ambassador {
            ul.ambassador-list {
                li.ambassador-item {
                    width: 50%;

                    &:nth-of-type(2n+1) {
                        clear: both;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {

    /* BLOG */
    .catalog-product-view, .blog-post-view {
        .related-post {
            li {
                float: none;
                width: 100%;
                margin: 0 0 20px 0;
            }
        }
    }

    .catalog-product-view {
        .related-post {
            li {
                .post-image, .post-detail {
                    float: none;
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }

                .post-image {
                    margin-bottom: 15px;
                }
            }
        }
    }

    /* CMS */
    .cms-page-view {
        /* COMPANY */
        .company-slider-section {
            padding-bottom: 45px;

            .timeline {
                padding-top: 20px;

                &:before {
                    top: 120px;
                    width: 4px;
                }

                .timeline-item {
                    padding: 20px 10px;

                    &.is-hidden {
                        visibility: visible !important;
                    }
                }

                h2:first-child {
                    strong {
                        display: block;
                        border-width: 3px;
                        margin: 5px 0;
                        padding: 7px 10px;
                    }
                }
            }
        }

        .company-nav {
            ul li {
                float: none;
                display: block;
                width: 100%;
            }
        }

        /* STORE */
        .store-service {
            .row.service-list {
                > div {
                    width: 100%;
                    margin-top: 40px;

                    &:first-of-type {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 420px) {

    /* CMS */
    .cms-page-view {
        /* EVENTS */
        .events {
            ul {
                margin: 0;

                li {
                    width: 100%;
                    margin: 10px 0;
                    padding-bottom: 100%;
                }
            }
        }

        /* CARRIER */
        .company-about {
            padding: 0;
        }
    }

    /* BLOG */
    .mp-sidebar.mp-sidebar-category {
        nav.category-top-nav {
            a {
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 360px) {

    /* CMS */
    .cms-page-view {
        /* COMPANY */
        .company-ambassador {
            ul.ambassador-list {
                li.ambassador-item {
                    width: 100%;
                }
            }
        }
    }
}

/*EDIT 02/10/2017*/
@media only screen and (max-width: 1199px) {
    .detail-position {
        .row-image {
            .service-image-first {
                margin-right: 24px;
            }
        }
    }

    .service-content {
        .list-positionimage {
            .sub-positionmain {
                margin-left: 14px;
            }
        }
    }
}

@media only screen and (max-width: 1005px) {
    .detail-position {
        .row-image {
            .service-image-first {
                margin-right: 23px;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .detail-position {
        img {
            width: auto;
        }

        .row-image {
            .service-image-first {
                margin-right: 0;
                width: 100%;
                text-align: center;
                margin-top: 0;
                margin-bottom: 25px;
            }

            .service-image-second {
                width: 100%;
                text-align: center;
            }
        }

        .service-image {
            margin-top: 25px;
            text-align: center;
            width: 100%;
        }
    }

    .service-content {
        .list-positionimage {
            .sub-positionmain {
                margin-left: 11px;
            }
        }
    }

    .service-icon {
        padding-bottom: 30px;
        text-align: center;
    }

    .for-entreposage {
        .list-entreposage {
            dd {
                span {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .service-content {
        .list-positionimage {
            .sub-positionmain {
                margin-left: 10px;
            }
        }
    }
}

@media only screen and (max-width: 845px) {
    .service-content {
        .list-positionimage {
            .positionmain {
                width: 46%;
            }

            .sub-positionmain {
                margin-left: 10px;
            }
        }
    }

    body {
        padding-top: 90px;
    }
}


@media only screen and (max-width: 780px) {
    /*SERVICE ENTREPOSAGE*/
    .detail-entreposage {
        .main-width {
            .main-topic {
                font-size: 20px;
            }
        }

        .for-price {
            font-size: 20px;
            vertical-align: middle;
        }
    }
}

@media only screen and (max-width: 730px) {
    .service-content {
        .list-positionimage {
            .sub-positionmain {
                margin-left: 8px;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    /*SERVICE ENTREPOSAGE*/
    .main-fortable {
        width: 100%;
        overflow-y: auto;
        margin: 0 0 1em;
    }
}


@media only screen and (max-width: 610px) {
    .service-content {
        .list-positionimage {
            .positionmain {
                width: 100%;
            }

            .sub-positionmain {
                width: 100%;
                margin-left: 0;
                margin-top: 20px;
                text-align: center;
            }
        }
    }
}

