
// Complete sidebar shipping information override

.opc-block-shipping-information {
    padding: 0;
    margin-top: 75px;
    margin-bottom: 40px;

    .shipping-information-title {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 8px;
        font-size: 1.8rem;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 0;
    }

    .action-edit {
        background-color: transparent;
        border: none;
        font-size: 1.4rem;
        font-weight: normal;
        text-decoration: underline;
        text-underline-offset: 0.18rem;
        color: $c_text-light;
        transition: color 0.24s ease;
        padding: 0;

        &:hover {
            color: $c_text-medium;
        }
    }

    .ship-via {
        margin-top: 40px;
    }

    [href^="tel:"] {
        color: $c_text-dark;
    }
}
