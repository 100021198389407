$color_main: #de5a01;
$color_light: #ffffff;
$color_dark: #000000;
$color_grey: #616060;
$color_inactive: #e2e1e2;

.cms-page-view.page-layout-empty, .cms-page-view .service-form {
    .text-outer {
        display: table;
        width: 100%;
        height: 100%;
    }

    .text-inner {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }

    .if-header {
        background: #00a762;

        img {
            max-width: 100%;
        }
    }

    .if-main-frame {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        padding: 50px 0;
    }

    .if-main-nav ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .if-main-frame .container {
        position: relative;
        min-height: 400px;
    }

    .if-main-nav li {
        float: left;
        display: block;
        width: 25%;
        font-size: 22px;
        color: #d8d8d8;
        -moz-transition: color 0.5s;
        -o-transition: color 0.5s;
        -webkit-transition: color 0.5s;
        transition: color 0.5s;

        &.active {
            color: $color_main;
        }
    }

    .if-main-navbar {
        width: 100%;
        height: 6px;
        background: $color_inactive;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 55px;
    }

    .if-main-navbar-block {
        width: 22%;
        height: 6px;
        background: $color_main;
        border-radius: 10px;
        -moz-transition: width 0.5s;
        -o-transition: width 0.5s;
        -webkit-transition: width 0.5s;
        transition: width 0.5s;
    }

    input[type=text],
    input[type=email],
    input[type=tel],
    select,
    option {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 42px;
        font-size: 16px;
        font-weight: 300;
        color: #000;
        line-height: 40px;
        background: #FFF;
        border: 1px solid $color_inactive;
        border-radius: 2px;
        padding-left: 15px;
    }

    input[type="checkbox"], input[type="radio"] {
        display: none;
        & + label {
            position: relative;
            display: inline-block;
            height: 24px;
            margin-right: 36px;
            padding-left: 36px;
            cursor: pointer;

            &:before, &:after {
                content: '';
                position: absolute;
                display: block;
                width: 24px;
                height: 24px;
                border-radius: 3px;
            }

            &:before {
                top: 0;
                left: 0;
                width: 24px;
                height: 24px;
                border: 1px solid $color_inactive;
                z-index: 2;
            }

            &:after {
                top: 5px;
                left: 5px;
                display: none;
                width: 14px;
                height: 14px;
                background-color: $color_main;
                z-index: 1;
            }
        }

        &:checked + label:after {
            display: block;
        }
    }

    input[type="radio"] + label, .terms-condition input[type="checkbox"] + label {
        border-radius: 50%;

        &:before, &:after {
            border-radius: 50%;
        }
    }

    input[type=text]::-webkit-input-placeholder,
    select::-webkit-input-placeholder,
    option::-webkit-input-placeholder,
    input[type=text]:-moz-placeholder,
    select:-moz-placeholder,
    option:-moz-placeholder,
    input[type=text]::-moz-placeholder,
    select::-moz-placeholder,
    option::-moz-placeholder,
    input[type=text]:-ms-input-placeholder,
    select:-ms-input-placeholder,
    option:-ms-input-placeholder {
        color: $color_grey;
        opacity: 1;
    }

    button {
        padding-top: 8px;
        padding-bottom: 9px;
    }

    select {
        display: none;
        color: $color_grey;
        line-height: 1;
    }

    .select-main-block {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        min-height: 40px;
        z-index: 99;
    }

    .select-block {
        position: relative;
        width: 100%;
        height: auto;
        font-size: 16px;
        font-weight: 300;
        color: #000;
        background: #FFF;
        border: 1px solid $color_inactive;
        border-radius: 2px;
        cursor: pointer;
        overflow: hidden;
        z-index: 5;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.active {
            z-index: 10;

            &:after {
                background-position: 0 6px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 10px;
            height: 6px;
            background-image: url("../web/images/select-arrow.png");
            background-position: 0 0;
            top: 17px;
            right: 18px;
            z-index: -1;
        }

        .select-label {
            line-height: 40px;
            padding-left: 15px;
            padding-right: 36px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        ul li.current {
            color: $color_main;
        }

        > ul {
            display: none;
            width: 100%;
            background: #FFF;
            list-style: none;
            padding: 10px 10px 20px 15px;
            margin: 0;

            > li {
                line-height: 1;
                padding: 3px 0;
                margin-bottom: 2px;

                &:hover {
                    color: $color_main;
                }

                &.category {
                    > span {
                        display: inline-block;
                        width: auto;
                        height: 19px;
                        vertical-align: bottom;
                    }

                    + div {
                        display: none;
                    }

                    .submenu-toggle {
                        position: relative;
                        width: 19px;
                        overflow: hidden;
                        color: #de5a01;

                        &:before {
                            content: '+';
                            position: absolute;
                            display: block;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                        }

                        &.active:before {
                            content: '-'
                        }

                        &.disabled:before {
                            opacity: 0;
                        }
                    }
                }

                .submenu-title:hover {
                    color: #de5a01;
                }
            }
        }
    }

    .select-radio, .contact-info-block li {
        .select-label {
            display: block;
            margin-bottom: 12px;
        }
    }

    .page-btn {
        position: relative;
        display: inline-block;
        font-size: 16px;
        color: #FFF;
        line-height: 1;
        text-transform: uppercase;
        background: $color_main;
        border: 1px solid $color_main;
        border-radius: 3px;
        padding: 12px 18px;

        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 15px;
            height: 11px;
            background-image: url("../web/images/arrow-btn.png");
            top: 50%;
            margin-top: -5px;
        }

        &.prev-page-btn {
            float: left;
            padding-left: 50px;
            &:before {
                background-position: 0 -11px;
                left: 18px;
            }
        }

        &.next-page-btn {
            float: right;
            padding-right: 50px;
            &:before {
                background-position: 0 0;
                right: 18px;
            }
        }
    }

    .if-contact-info-form-block {
        .col-block {
            margin-top: 11px;

            &:first-child {
                margin-top: 0;
            }
        }

        .col-left,
        .col-right {
            width: 49.5%;
        }

        .col-left {
            float: left;
        }

        .col-right {
            float: right;
        }

        .btn-block {
            text-align: right;
            margin-top: 55px;
        }
    }

    .if-service-dropdown-block {
        + .if-service-dropdown-block {
            display: none;
            transition: all 0.3s ease;

            .select-main-block {
                z-index: 98;
            }
        }

        &.active + .if-service-dropdown-block {
            display: block;
        }

        .service-dropdown-col {
            position: relative;
            float: left;
            width: -webkit-calc(50% - 4px);
            width: calc(50% - 4px);
            min-height: 42px;
            margin-left: 8px;
            margin-bottom: 8px;

            &:first-child {
                margin-left: 0;
            }

            &.services-list-select {
                z-index: 4;
            }

            &.shop-list-select {
                z-index: 3;
            }

            &.filter-search-text {
                z-index: 2;
            }

            &.filter-search-dropdown {
                z-index: 1;
            }

            &.filter-search-button {
                z-index: 0;
            }
        }

        .filterfrom {
            .service-dropdown-col {
                width: -webkit-calc(50% - 98px);
                width: calc(50% - 98px);
            }

            .service-dropdown-col:last-child {
                width: 180px;

                button {
                    width: 100%;
                }
            }
        }
    }

    .if-service-main-block {
        margin-top: 17px;

        .service-list-block {
            position: relative;
            margin-bottom: 35px;

            .previouspage, .nextpage {
                position: relative;
                display: inline-block;
                height: 14px;
                margin-bottom: 20px;
                line-height: 14px;
                cursor: pointer;

                &:before, &:after {
                    position: absolute;
                    top: 0;
                    display: block;
                    width: 15px;
                    height: 100%;
                    background: url(../web/images/arrow-grey-btn.png) no-repeat;
                }

                + .service-block {
                    clear: both;
                    margin-left: 0;
                }
            }

            .previouspage {
                float: left;
                padding-left: 24px;

                &:before {
                    content: '';
                    left: 0;
                    background-position: 0 50%;
                }
            }

            .nextpage {
                float: right;
                padding-right: 24px;

                &:after {
                    content: '';
                    right: 0;
                    background-position: -15px 50%;
                }
            }
        }

        .service-block {
            position: relative;
            float: left;
            width: 24.25%;
            margin-left: 1%;
            padding-bottom: 35px;
            border-left: 1px solid $color_inactive;
            border-right: 1px solid $color_inactive;
            border-bottom: 1px solid $color_inactive;
            border-radius: 0 0 3px 3px;

            &.active {
                border: 2px solid #df5d24;
            }

        }

        .service-title {
            font-size: 16px;
            text-align: center;
            line-height: 20px;
            text-transform: uppercase;

            color: white;
            height: 80px;
            background: #999;
            padding: 15px;
            font-weight: normal;

            strong {
                display: block;
                font-weight: 900;
            }
        }

        .service-desc {
            padding: 5px 10px 15px;
        }

        .service-img {
            position: relative;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 85%;

            .service-img-wrapper {
                position: absolute;
                display: table;
                width: 100%;
                height: 100%;

                .service-img-inner {
                    display: table-cell;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    vertical-align:top;

                    img {
                        width: auto;
                        max-width: 100%;
                    }
                }
            }
        }

        .service-price {
            font-size: 45px;
            font-weight: 900;
            text-align: center;
            line-height: 1;
            margin-top: 12px;
            margin-bottom: 12px;

            sup {
                font-size: 28px;
                margin-left: 1px;
            }
        }

        .service-desc-list {
            font-size: 15px;
            font-weight: 300;
            text-align: center;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                line-height: 1;
                margin-top: 8px;
                &:first-child {
                    margin-top: 0;
                }
            }

            strong {
                font-weight: normal;
            }

            .qty {
                position: relative;
                display: block;
                width: 84px;
                margin: 0 auto;

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 10px;
                    height: 6px;
                    background-image: url(../web/images/select-arrow.png);
                    background-position: 0 0;
                    top: 17px;
                    right: 18px;
                    z-index: -1;
                }

                select {
                    display: block;
                    width: 100%;
                    padding-right: 42px;
                    background: transparent;
                    text-align: center;
                    outline: none;
                }
            }
        }

        .service-btn-block {
            width: 100%;
            text-align: center;

            a {
                display: inline-block;
                font-size: 16px;
                color: $color_main;
                background-image: url("../web/images/arrow-orange-btn.png");
                background-repeat: no-repeat;
                background-position: right center;
                padding-right: 15px;
                cursor: pointer;
            }
        }

        .service-select-btn {
            display: inline-block;
            font-size: 16px;
            color: #FFF;
            line-height: 1;
            text-transform: uppercase;
            background: $color_main;
            border-radius: 3px;
            padding: 10px 15px;
            margin: 10px auto 0;
            cursor: pointer;
            -moz-transition: background 0.5s;
            -o-transition: background 0.5s;
            -webkit-transition: background 0.5s;
            transition: background 0.5s;

            &.active {
                background: #525051;
            }
        }
    }

    .if-time-slot-block {
        .time-note-block-1 {
            font-size: 16px;
            color: $color_grey;
        }

        .time-note-block-2 {
            position: relative;
            min-height: 40px;
            font-size: 16px;
            line-height: 18px;
            color: $color_main;
            padding-top: 2px;
            padding-left: 55px;
            margin-top: 10px;
            margin-bottom: 40px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 39px;
                height: 39px;
                background-image: url("../web/images/time-note-icon.png");
                top: 0;
                left: 0;
            }
        }

        .time-note-block-3 {
            position: relative;
            margin-bottom: 15px;

            &:after {
                content: '';
                clear: both;
                display: block;
                width: 100%;
                height: 0;
            }

            .time-date-reset {
                clear: both;
                display: block;
                width: 100px;
                height: 24px;

                #reset-selection {
                    display: none;
                    width: 100%;
                    text-transform: uppercase;
                    color: $color_dark;
                    cursor: pointer;
                    white-space: nowrap;

                    &:before, span {
                        display: inline-block;
                    }

                    &:before {
                        content: 'X';
                        width: 24px;
                        height: 24px;
                        margin-right: 5px;
                        font-size: 16px;
                        line-height: 24px;
                        color: $color_light;
                        background: $color_main;
                        text-align: center;
                        border-radius: 50%;
                    }
                }
            }

            .time-date-select {
                margin-bottom: 15px;
                padding-left: 0;

                .rent-label-date, .rent-select-date {
                    display: inline-block;
                    height: 30px;
                    padding: 2px 0 4px;
                    line-height: 24px;
                    vertical-align: middle;
                }

                .rent-label-date {
                    width: 26px;
                    margin-right: 2px;
                    font-weight: 700;
                    text-align: center;
                    color: $color_inactive;
                }

                .rent-select-date {
                    position: relative;
                    width: -webkit-calc(100% - 32px);
                    width: calc(100% - 32px);
                    overflow: hidden;

                    &:before, &:after {
                        content: '';
                        position: absolute;
                        left: auto;
                        bottom: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $color_main;
                        transition: all 0.3s ease;
                    }

                    &:before {
                        left: 0;
                        background-color: $color_inactive;
                    }

                    &:after {
                        z-index: 2;
                    }
                }

                span[name="rent-from-date"]:after {
                    right: -100%;
                }

                span[name="rent-to-date"]:after {
                    left: -100%;
                }

                &.select-active {
                    .rent-label-date {
                        color: $color_dark;
                    }

                    span[name="rent-from-date"]:after {
                        right: 0;
                    }

                    span[name="rent-to-date"]:after {
                        left: 0;
                    }
                }
            }
        }

        .month-title-block {
            position: relative;
            height: 40px;
            font-size: 17px;
            color: $color_grey;
            line-height: 40px;
            text-align: center;
            text-transform: uppercase;
            background: $color_inactive;
            border-radius: 3px;
        }

        .month-label {
            cursor: default;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .month-btn {
            position: absolute;
            display: block;
            width: 40px;
            height: 40px;
            top: 0;
            cursor: pointer;

            span {
                position: absolute;
                display: block;
                width: 15px;
                height: 11px;
                background-image: url("../web/images/arrow-grey-btn.png");
                margin-top: -5px;
                top: 50%;
            }

            &.prev-month-btn {
                left: 0;
                span {
                    background-position: 0 0;
                    left: 15px;
                }
            }

            &.next-month-btn {
                right: 0;
                span {
                    background-position: 15px 0;
                    right: 15px;
                }
            }
        }

        .month-calendar-block {
            position: relative;

            table {
                width: 100%;
                table-layout: fixed;
            }

            th {
                font-weight: normal;
            }
        }

        .time-calendar {
            position: relative;
        }

        .calendar-popup-block {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("../web/images/loader.svg") center center no-repeat, rgba(255, 255, 255, 0.85);
            top: 0;
            left: 0;
            z-index: 5;
        }
    }

    .drop-date,
    .pick-date {
        font-weight: bold;
    }

    .popup {
        display: none;
        position: absolute;
        width: 100%;
    /*    max-width: 764px;*/
        height: 100%;
        background: rgba(255, 255, 255, 0.95);
        padding: 20px;
        border: 3px solid $color_main;
        border-radius: 2px;
        top: 0;
        left: 0;
        z-index: 5;
        overflow: auto;

        h2 {
            margin-top: 0;
        }

        .discription li {
            margin-bottom: 0rem;
        }

        .service-select-btn {
    /*        float: right;*/
            display: block;
            margin: 0 auto 30px;
            width: 200px;
            text-align: center;
            margin-top: 30px;


        }
    }

    .if-time-slot-block {
        .calendar-popup {
            display: none;
            position: relative;
            width: 100%;
            max-width: 285px;
            font-size: 16px;
            line-height: 1.1;
            text-align: center;
            border: 1px solid $color_inactive;
            border-radius: 3px;
            background: #FFF;
            padding: 25px;
            margin: 0 auto;

            .close-btn {
                position: absolute;
                width: 8px;
                height: 7px;
                background-image: url("../web/images/close-btn.png");
                top: 8px;
                right: 8px;
                cursor: pointer;
            }

            .title-block {
                color: $color_grey;
            }

            .title-date {
                font-weight: bold;
                color: #000;
                margin-top: 10px;
            }

            ul {
                padding: 0;
                margin: 20px 0 0;
                list-style: none;
            }

            li {
                font-size: 19px;
                font-weight: bold;
                margin-top: 5px;

                &:first-child {
                    margin-top: 0;
                }

                span {
                    color: $color_grey;
                    cursor: pointer;
                    -moz-transition: color 0.5s;
                    -o-transition: color 0.5s;
                    -webkit-transition: color 0.5s;
                    transition: color 0.5s;

                    &:hover {
                        color: $color_main;
                    }
                }
            }
        }

        .week-date-block {
            padding-top: 2px;

            .date-block {
                line-height: 1;
                text-align: center;
                padding-bottom: 14px;
                vertical-align: bottom;
            }
        }

        .day-date-block {
            display: none;

            .date-block {
                background: #FFF;
                border: 1px solid $color_inactive;
                padding-top: 8px;
                padding-left: 10px;
                vertical-align: top;
                cursor: pointer;
                -moz-transition: all 0.5s;
                -o-transition: all 0.5s;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;

                &.blank-date-block {
                    border: 0;
                    cursor: default;
                }

                &.active {
                    background: $color_main;

                    .calendar-num {
                        color: #FFF;
                    }

                    .calendar-time {
                        display: block;
                        font-size: 18px;
                        color: #FFF;
                    }
                }

                &.not-available {
                    background: $color_inactive;
                    cursor: default;
                }

                &.disable {
                    cursor: default;
                    background-image: url("../web/images/cross-date-calendar.png");
                    background-position: 0 0;
                    background-size: 100% 100%;
                }

                &.rental-preserve {
                    background-color: #F0C49B!important;
                }
            }
        }

        .calendar-num {
            font-size: 13px;
            line-height: 1;
            color: $color_grey;
        }

        .calendar-time {
            display: none;
            line-height: 1;
            margin-top: 2px;
        }

        .date-block {
            width: 14.28571%;
            height: 50px;
            font-size: 16px;
            color: $color_grey;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .calendar-label-note-block {
            margin-top: 12px;
        }

        .calendar-label-note {
            position: relative;
            display: inline-block;
            line-height: 1;
            padding-left: 20px;
            margin-left: 22px;

            &:first-child {
                margin-left: 0;
            }

            &:before {
                content: '';
                position: absolute;
                display: block;
                width: 14px;
                height: 14px;
                top: 0;
                left: 0;
            }
        }

        .your-selection-label:before {
            background: $color_main;
        }

        .not-available-label:before {
            background: $color_inactive;
        }

        .closed-shop-label:before {
            background-image: url("../web/images/closed-shop-label-icon.png");
        }

        .main-summery-block {
            /*margin-top: 40px;*/
        }

        .block-outer {
            padding-left: 20px;
        }

        .services-sum-location,
        .services-sum-service,
        .services-sum-time {
            position: relative;
            padding-left: 60px;
            margin-top: 20px;

            &:before {
                content: '';
                position: absolute;
                display: block;
                background-image: url("../web/images/services-summery-icon-sprite.png");
                top: 0;
            }

            .title-block {
                font-size: 16px;
                color: $color_grey;
                line-height: 1;
            }

            .desc-block {
                font-size: 20px;
                font-weight: bold;
                color: #000;
                line-height: 1;
                margin-top: 3px;
            }

            .note-block {
                font-style: italic;
                font-size: 12px;
            }
        }

        .services-sum-location {
            margin-top: 0;

            &:before {
                width: 35px;
                height: 32px;
                top: 3px;
                left: 3px;
            }
        }

        .services-sum-service {
            .img-block {
                margin: 15px auto;
            }

            &:before {
                width: 43px;
                height: 25px;
                background-position: 0 -32px;
                top: 5px;
                left: 2px;
            }
        }

        .services-sum-time:before {
            width: 29px;
            height: 34px;
            background-position: 0 -57px;
            top: 3px;
            left: 7px;
        }

        .available-other-store-block {
            border: 1px solid $color_main;
            border-radius: 5px;
            padding: 35px 20px 35px 50px;
            margin-top: 40px;

            .main-title-block {
                font-size: 16px;
                color: $color_grey;
                line-height: 1.2em;
                margin-bottom: 22px;
            }

            .other-store-list {
                margin-top: 15px;
                &:first-child {
                    margin-top: 0;
                }
            }

            .other-store-date {
                float: left;
                width: 45px;
                color: #FFF;
                text-align: center;
                background: $color_main;
                border-radius: 2px;
                padding: 6px 0;
                margin-bottom: 12px;

                .num {
                    font-size: 23px;
                    line-height: 1;
                }

                .month {
                    font-size: 12px;
                    line-height: 1;
                    text-transform: uppercase;
                }
            }

            .other-store-desc {
                margin-left: 65px;

                .title-block {
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                    line-height: 1;
                    margin-top: 5px;
                }

                .desc-block {
                    font-size: 15px;
                    color: $color_main;
                    line-height: 1;
                    margin-top: 2px;
                }
            }
        }
    }

    .if-time-slot-btn-block {
        margin-top: 45px;
    }

    .if-confirm-page {
        position: relative;

        .main-title-block {
            font-size: 25px;
            color: $color_grey;
            margin-bottom: 25px;
        }

        .contact-info-block {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        .detail .contact-info-block li {
            font-size: 20px;
            font-weight: bold;
            color: $color_dark;
            margin-top: 5px;
        }

        .contact-info-block li {
            &:first-child {
                margin-top: 0;
            }

            span {
                font-size: 15px;
                font-weight: normal;
                color: $color_grey;
                margin-right: 5px;
            }
        }

        .edit-btn {
            font-size: 16px;
            color: $color_main;
            margin-top: 40px;

            a {
                color: $color_main;
                cursor: pointer;
            }
        }

        .services-sum-location,
        .services-sum-service {
            position: relative;
            min-height: 35px;
            padding-left: 58px;
            margin-top: 20px;
        }

        .services-sum-location:before,
        .services-sum-service:before {
            content: '';
            position: absolute;
            display: block;
            background-image: url("../web/images/services-summery-icon-sprite.png");
            top: 0;
        }

        .services-sum-location {
            margin-top: 0;

            &:before {
                width: 35px;
                height: 32px;
                top: 3px;
                left: 3px;
            }
        }

        .col-right {
            .main-title-block {
                margin-bottom: 30px;
            }

            .title-block {
                font-size: 15px;
                color: $color_grey;
                line-height: 1;
            }

            .desc-block {
                font-size: 20px;
                font-weight: bold;
                color: #000;
                line-height: 1;
                margin-top: 1px;
            }
        }

        .services-sum-service {
            &:before {
                width: 43px;
                height: 25px;
                background-position: 0 -32px;
                top: 5px;
                left: 2px;
            }

            ul {
                padding: 0;
                margin: 8px 0 0;
                list-style: none;
            }

            li {
                font-size: 18px;
                font-weight: bold;
                color: #000;
                line-height: 22px;
            }
        }

        .services-arrival-date,
        .services-bike-recovery,
        .services-bike-duration,
        .services-bike-fitting-hour {
            position: relative;
            padding-left: 52px;
        }

        .services-arrival-date:before,
        .services-bike-recovery:before {
            content: '';
            position: absolute;
            display: block;
            width: 29px;
            height: 29px;
            background-image: url("../web/images/arrival-date-icon.png");
            top: 2px;
            left: 0;
        }

        .services-arrival-date,
        .services-bike-recovery,
        .services-bike-fitting-hour {
            margin-bottom: 20px;
        }

        .services-arrival-date:before {
            background-position: 0 0;
        }

        .services-bike-recovery:before {
            background-position: 0 29px;
        }
    }

    .if-paging-main-block {
        padding-top: 20px;
    }

    .if-frame-block .service-dropdown-block .service-dropdown-col.reset {
        border-radius: 3px;
        cursor: pointer;
        width: 18%;
    }

    .if-confirm-page .frominfor {
        display: none;
        .saveinfor {
            float: right;

            a {
                color: $color_main;
            }
        }
        .cancelinfor {
            float: left;

            a {
                color: $color_inactive;
            }
        }
    }

    .page-loading {
        display: none;
        &.info {
            width: 100%;
            height: 100%;
            background: url("../web/images/loader.svg") center center no-repeat, rgba(255, 255, 255, 0.85);
            top: 0;
            left: 0;
            margin: 0;
            img {
                display: none;
            }
        }
    }

    .editdetail a {
        color: $color_main;
        cursor: pointer;
    }

    .invalid-label,
    .notice-message {
        color: #e02b27;
        margin: 7px 0 0;
    }

    .page-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -32px 0 0 -32px;
        z-index: 2000;
    }

    .service-loading,
    .calendar-loading {
        text-align: center;
        margin: 100px 0;
    }

    .closepopup {
        position: absolute;
        display: block;
        width: 8px;
        height: 7px;
        background-image: url("../web/images/close-btn.png");
        top: 28px;
        right: 28px;
        cursor: pointer;
        text-indent: -9999em;
    }

    .if-terms-block {
        position: relative;
        margin-top: 65px;

        .title-block {
            font-size: 25px;
            color: $color_grey;
        }

        .terms-block {
            font-size: 16px;
            font-weight: 300;
            color: #525051;
            margin-top: 20px;
        }

        .terms-condition {
            margin-top: 25px;

            input[type="checkbox"] {
                display: none;

                &:checked + label:before {
                    background-position: 0 -24px;
                }
            }

            label {
                position: relative;
                display: inline-block;
                min-height: 24px;
                font-size: 16px;
                font-weight: 300;
                color: $color_grey;
                padding-left: 40px;
                cursor: pointer;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }
    }

    .disabled-button{
        opacity: 0.5;
        cursor: default;

        &:hover,
        &:active,
        &:focus {
            color: #FFF;
            background: $color_main;
            border: 1px solid $color_main;
        }
    }

    .select-suggested {
        cursor: pointer;
    }

    .termes-label {
        color: $color_main;
    }

    .terms-block-popup {
        display: none;
        position: absolute;
        width: 100%;
        min-height: 450px;
        background: rgba(255,255,255,0.95);
        padding: 20px;
        top: 0;
        left: 0;
        z-index: 5;

        .terms-block-popup-content {
            position: relative;
            max-width: 800px;
            padding: 30px;
            margin: 0 auto;
            border: 3px solid $color_main;
            border-radius: 2px;
        }

        .closepopup {
            top: 15px;
            right: 15px;
        }
    }

    .step-complete-block {
        h1 {
            font-size: 1.5em;
            letter-spacing: -0.5px;
        }

        .if-main-frame {
            padding: 25px 0;
        }

        .return-home-block {
            text-align: center;
            margin-top: 0;

            .service-select-btn {
                width: 100%;
                max-width: 400px;
            }
        }
    }

    .noti-block {
        .info-message{
            margin-top: 10px;

            .red {
                color: #de5a01;
            }
        }
    }

    @media only screen and (max-width: 1199px) {
        .if-service-main-block .service-title {
            padding: 10px;
        }

        .popup, .terms-block-popup {
            position: fixed;
            top: 30px;
            left: 30px;
            width: calc(100% - 60px);
            width: -webkit-calc(100% - 60px);
            height: auto;
            max-height: calc(100% - 60px);
            max-height: -webkit-calc(100% - 60px);
            margin: 0;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .terms-block-popup {
            padding: 0;

            .terms-block-popup-content {
                padding: 20px;
            }
        }

        div[style*="display: block;"] + .popup-overflow {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            display: block;
            width: 100vw;
            height: 100vh;
            background-color: rgba(255, 255, 255, 0.8);
            z-index: 1;
            box-sizing: border-box;
        }

        .closepopup {
            top: 0 !important;
            right: 0 !important;
            padding: 28px;
            background-position: center;
            background-repeat: no-repeat;
        }

        .summary-info-in > div:after {
            content: '';
            clear: both;
            display: block;
            width: 100%;
            height: 0;
        }
    }

    @media only screen and (max-width: 991px) {
        .if-main-nav li {
            font-size: 20px;
        }
        .if-time-slot-block .block-outer {
            padding-left: 0;
        }
        .if-confirm-page {
            .edit-btn {
                margin-top: 20px;
            }
            .col-right {
                margin-top: 40px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .if-service-main-block .service-block {
            width: 49.5%;
            margin-left: 0;
            margin-bottom: 20px;

            &:nth-child(2n) {
                margin-left: 1%;
            }

            &:nth-child(2n+1) {
                clear: both;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .if-main-nav li {
            font-size: 16px;
        }
        .if-contact-info-form-block {
            .col-left, .col-right {
                float: none;
                width: 100%;
            }
            .col-left {
                margin-bottom: 11px;
            }
        }
        .if-confirm-page {
            .services-col-right {
                margin-top: 20px;
            }
            .services-arrival-date,
            .services-bike-recovery {
                padding-left: 58px;
            }
        }
        .if-time-slot-block .available-other-store-block {
            padding: 20px;
        }
    }

    @media only screen and (max-width: 640px) {
        .if-service-dropdown-block .service-dropdown-col {
            float: none;
            width: 100%;
            margin-left: 0;
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }
        }

        .if-service-main-block .service-list-block {
            margin-bottom: 20px;
        }

        .if-service-dropdown-block {
            .filterfrom .service-dropdown-col {
                float: none;
                width: 100%;
                margin-left: 0;
                margin-top: 10px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 550px) {
        .if-main-nav li {
            font-size: 16px;
        }
        .if-main-navbar {
            margin-bottom: 30px;
        }
        .if-contact-info-form-block .btn-block {
            margin-top: 30px;
        }
        .if-time-slot-block {
            .calendar-label-note {
                display: block;
                margin-top: 15px;
                margin-left: 0;
            }
            .day-date-block .date-block.active .calendar-time {
                font-size: 16px;
            }
            .time-note-block-2 br {
                display: none;
            }
        }
        .if-confirm-page {
            .main-title-block {
                font-size: 22px;
                margin-bottom: 10px;
            }
            .contact-info-block li {
                font-size: 16px;
            }
            .col-right {
                margin-top: 20px;
                .main-title-block {
                    margin-bottom: 10px;
                }
                .desc-block {
                    font-size: 16px;
                }
            }
            .services-sum-service li {
                font-size: 15px;
                line-height: 16px;
            }
        }
        .if-terms-block {
            margin-top: 40px;
        }

        .if-service-main-block .service-block {
            float: none;
            width: 100%;
        }

        .if-service-main-block .service-desc {
            height: auto !important;
        }
    }

    @media only screen and (max-width: 420px) {
        .if-service-main-block {
            .service-title {
                font-size: 16px;
                padding: 0 5px;
            }
            .service-select-btn {
                width: 80%;
                font-size: 12px;
            }
        }
        .if-time-slot-block .day-date-block .date-block {
            padding-left: 5px;
            &.active .calendar-time {
                font-size: 16px;
            }
        }
    }

    @media only screen and (max-width: 380px) {
        .if-main-nav li {
            float: none;
            width: 100%;
            font-size: 16px;
        }

        .page-btn {
            font-size: 12px;
        }

        .if-time-slot-block {
            > div, .service-calendar {
                padding-left: 0;
                padding-right: 0;
            }

            .main-summery-block {
                margin-top: 30px;
            }
        }
    }

    @media only screen and (max-width: 360px) {
        .page-btn {
            display: block;
            min-width: 160px;
            margin: 20px auto 0;

            &:first-child {
                margin-top: 0;
            }

            &.prev-page-btn,
            &.next-page-btn {
                float: none;
            }
        }
    }

    @media all and (-webkit-min-device-pixel-ratio: 1.5),
    all and (-o-min-device-pixel-ratio: 3 / 2),
    all and (min--moz-device-pixel-ratio: 1.5),
    all and (min-device-pixel-ratio: 1.5) {
        .select-block:after {
            background-image: url("../web/images/select-arrow@2x.png");
            background-size: 10px 12px;
        }

        .page-btn:before {
            background-image: url("../web/images/arrow-btn@2x.png");
            background-size: 15px 22px;
        }

        .if-service-main-block .service-btn-block a {
            background-image: url("../web/images/arrow-orange-btn@2x.png");
            background-size: 6px 10px;
        }

        .if-time-slot-block .time-note-block-2:before {
            background-image: url("../web/images/time-note-icon@2x.png");
            background-size: 39px 39px;
        }

        .if-time-slot-block .month-btn span {
            background-image: url("../web/images/arrow-grey-btn@2x.png");
            background-size: 30px 11px;
        }

        .if-time-slot-block .calendar-popup .close-btn {
            background-image: url("../web/images/close-btn@2x.png");
            background-size: 8px 7px;
        }

        .if-time-slot-block .day-date-block .date-block.disable {
            background-image: url("../web/images/cross-date-calendar@2x.png");
            background-size: 100% 100%;
        }

        .if-time-slot-block .closed-shop-label:before {
            background-image: url("../web/images/closed-shop-label-icon@2x.png");
            background-size: 14px 14px;
        }

        .if-time-slot-block {
            .services-sum-location:before, .services-sum-service:before, .services-sum-time:before {
                background-image: url("../web/images/services-summery-icon-sprite@2x.png");
                background-size: 43px 91px;
            }
        }

        .if-confirm-page {
            .services-sum-location:before, .services-sum-service:before {
                background-image: url("../web/images/services-summery-icon-sprite@2x.png");
                background-size: 43px 91px;
            }
        }

        .if-confirm-page {
            .services-arrival-date:before, .services-bike-recovery:before {
                background-image: url("../web/images/arrival-date-icon@2x.png");
                background-size: 29px 58px;
            }
        }

        .if-terms-block .terms-condition label:before {
            background-image: url("../web/images/checkbox-icon@2x.png");
            background-size: 24px 48px;
        }
    }
}