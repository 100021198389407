.payment-method-stripe_payments {
    .payment-method-content {
        .stripe-payments-billing-address-warning {
            margin: 10px 0;
        }

        .ccard {
            margin-bottom: 0 !important;

            .stripe-payments-elements {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                &.columns {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: nowrap;

                    &:after {
                        display: none;
                    }
                }

                label {
                    color: #1C2028;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 16px;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                }

                .card-number-wrapper {
                    position: relative;

                    .stripe-payments-brand {
                        position: absolute;
                        right: 15px;
                        top: 47%;
                        transform: translateY(-50%);
                        width: auto;
                        height: auto;
                    }
                }

                #stripe-payments-card-number,
                #stripe-payments-card-expiry,
                #stripe-payments-card-cvc {
                    width: 100%;
                    color: #1C2028;
                }

                .stripe-elements-field {
                    margin: 0;
                    box-shadow: none;
                    border: 1px solid #E9E9EB;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    line-height: 50px;
                    padding: 14px;
                }

                .stripe-payments-element {
                    display: flex;
                    flex-direction: column;
                    flex: 0 0 48%;
                }
            }

            #stripe-payments-card-errors.populated {
                width: 100%;
                border: none;
                padding: 0;
            }

            .pci-dss-info-block {
                width: 100%;
                display: flex;
                align-items: center;
                margin: 10px 0;

                #pci-svg {
                    position: unset;
                }

                .pci-dss-info-text {
                    position: unset;
                    margin-left: 5px;
                }
            }
        }

        .payment-method-billing-address {
            margin-top: 0;
        }
    }
}

.payment-content {

    .opc-payment-additional {

        &.comment {
            margin-top: 30px;

            .payment-option-title {
                border: none;
                padding: 0;
                margin-bottom: 20px;
                color: #1C2028;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-transform: uppercase;
            }

            .payment-option-content {
                padding: 0;
            }
        }
    }
}



.checkout-payment-method {
    pointer-events: auto;
    &.loading {
        pointer-events: none;
        opacity: 0.5;
    }
}
