
.opc-payment {

    // Payments square trigger
    .payment-trigger {

        .step-title {
            display: none;
        }

        .payment-method-content {
            display: none !important;
        }

        .payment-trigger-list {
            display: flex;
            justify-content: center;
            margin: 20px -5px 0;

            .payment-method {
                flex-basis: 100%;
                align-self: stretch;

                input[type="radio"] {
                    display: none;
                }

                .payment-method-title {
                    padding: 0;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin: 0 5px;
                    border: solid 2px $c_border-grey;
                    border-radius: 4px;
                    height: 100%;
                    max-height: 80px;

                    .label {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        padding: 30px 15px;
                        box-sizing: border-box;

                        &[for="stripe_payments"] {
                            position: relative;

                            &:before, &:after {
                                content: "";
                                height: 24px;
                                width: 34px;
                            }

                            &:before {
                                background: url(../images/logos/mastercard_checkout.svg) no-repeat center center;
                                margin-right: 2px;
                            }

                            &:after {
                                background: url(../images/logos/visa_checkout.svg) no-repeat center center;
                            }

                            span {
                                display: none;
                            }
                        }

                        img, svg {
                            max-width: 100px;
                        }
                    }
                }

                &._active {

                    .payment-method-title {
                        border-color: $c_secondary;
                    }
                }
            }
        }
    }

    // Payment method content layout
    .payment-content {
        margin-top: 40px;

        .legend {
            margin-top: 15px;
        }

        .payment-method-title {
            display: none;
        }

        .payment-method-content {
            padding: 0;
        }

        .actions-toolbar {
            margin-top: 10px;
            margin-left: 0 !important;

            > .primary {
                float: none;
            }

            .primary .primary {
                background-color: $c_secondary;
                border-color: $c_secondary;
            }
        }

        .payment-method-billing-address {
            margin-top: 20px;
        }

        .billing-address-form {
            max-width: none;
        }
    }
}

.checkout-payment-method {

    .form.payments {

        .legend {
            font-size: 1.8rem;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }

    .checkout-agreements {
        text-align: left;
        margin-top: 40px;

        button.action-show {
            color: $c_text-light;
            transition: color 0.24s ease;

            &:hover {
                color: $c_text-medium;
            }
        }

        .checkout-agreement:before {
            content: none !important;
        }
    }

    .redirection {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $c_bg-grey;
        padding: 54px 30px;
        border-radius: 4px;
        margin-bottom: 30px;

        p {
            max-width: 300px;
            margin-bottom: 0;
            margin-top: 30px;
            text-align: center;
            color: $c_text-light;
        }
    }

    .checkout-billing-address {

        .actions-toolbar {

            .primary {
                display: flex;
                flex-direction: row-reverse;
            }

            .a_underline {
                text-align: left;
                padding-left: 0;
            }
        }

        .billing-address-details {
            line-height: 1.5;
            padding: 0 0 0 30px;

            p {
                margin-bottom: 0;
            }

            .a_underline {
                margin-top: 15px;
            }

            [href^="tel:"] {
                color: $c_text-dark;
            }
        }
    }

    .field-select-billing {
        max-width: none;
        margin: 30px 0 20px 0 !important;
    }

    .payment-method-content > .actions-toolbar > .primary .action.primary {
        @extend .q_b-secondary;
    }
}

.payment-method-braintree {

    .payment-method-title {

        .label {
            display: flex !important;
            justify-content: center;
        }
    }

    .field.cc-number {
        position: relative;

        .hosted-control,
        input[type="text"] {
            padding-right: 54px;
        }

        .credit-card-types {
            position: absolute;
            right: 5px;
            top: 36px;

            .item {
                display: none;
                margin-right: 0;

                &._active {
                    display: block;
                }
            }
        }
    }

    .field.date,
    .field.cvv {
        float: left;
    }

    .field.date {
        width: 40%;
    }

    .field.cvv {
        width: 60%;
    }

    .ccard > .field.cvv {
        padding-left: 15px;

        > .control {
            padding-right: 0;
        }
    }
}

.billing-address-form {

    .fieldset > .field.choice:before, .fieldset > .field.no-label:before {
        content: none;
    }

    .field.street {

        .control .field > .label {
            display: none;
        }
    }

    [name="billingAddressbraintree.firstname"] {
        width: 50%;
        padding-right: 15px;
        float: left;
    }

    [name="billingAddressbraintree.lastname"] {
        width: 50%;
        float: right;
    }

    [name="billingAddressbraintree.company"] {
        clear: both;
    }

    [name="billingAddressbraintree.region_id"] {
        width: 33%;
        float: left;
        padding-right: 15px;
    }

    [name="billingAddressbraintree.country_id"] {
        width: 33%;
        float: left;
        padding-right: 15px;
    }

    [name="billingAddressbraintree.postcode"] {
        width: 33%;
        float: right;
    }

    [name="billingAddressbraintree.telephone"] {
        clear: both;
    }
}

// Giftcard
.checkout-payment-method .aw-giftcard-code-payment-option {
    position: relative;
    padding-top: 30px;

    .payment-option-title.choice {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-100%);
        padding: 0;
        line-height: 1;
        border-top: 0;

        .action-toggle {
            color: $c_text-medium;
            text-decoration: underline;

            &:after {
                font-size: 2.4rem;
                color: $c_text-medium;
            }
        }
    }

    .payment-option-content {
        padding-left: 0;
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid $c_border-grey;

        .label {
            margin-bottom: 8px;
            font-size: 1.4rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        .control {
            display: flex;

            input {
                width: 100%;
            }

            button {
                white-space: nowrap;
            }
        }

        .actions-toolbar {
            margin-left: 20px;
            margin-top: 0;
        }
    }

    .payment-option-inner {
        margin-bottom: 0;
    }

    .field-header {
        display: flex;
        justify-content: space-between;
    }

    .cancel {
        display: block;
        text-decoration: underline;
        color: $c_text-medium;
        cursor: pointer;
    }
}

// Coupon code
.checkout-payment-method .payment-option.discount-code {

    .payment-option-title {
        padding: 30px 0 0 0;
        margin: 40px 0 8px 0;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        border-top-color: $c_border-grey;
    }

    .payment-option-content {
        padding: 0;
    }

    .form-discount {
        display: flex;
        max-width: none;

        .payment-option-inner {
            flex-basis: 100%;
        }

        .actions-toolbar {
            margin-top: 0;
            flex-shrink: 0;

            > .primary {
                padding-left: 20px;
            }
        }

        .action {
            white-space: nowrap;
        }
    }
}

@media screen and (max-width: $screen__m) {

    .checkout-payment-method .payment-option,
    .checkout-payment-method .payment-methods {
        margin: 0;
    }

    .opc-payment {

        .payment-content {

            .actions-toolbar {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: $screen__s) {

    .opc-payment {

        .payment-trigger {

            .payment-trigger-list {
                flex-direction: column;

                .payment-method {
                    margin-bottom: 15px;

                    &._active {

                        .payment-method-content {
                            display: block !important;
                        }
                    }
                }
            }

            .payment-method-content {
                margin-top: 30px;
                margin-bottom: 15px;
            }
        }
    }

    .checkout-payment-method {

        .aw-giftcard-code-payment-option {

            .payment-option-title.choice {
                position: static;
            }

            .payment-option-content {
                margin-top: 15px;
            }
        }

        .checkout-billing-address {

            .save-address {
                margin-bottom: 20px;
            }
        }
    }

    .billing-address-form {

        [name="billingAddressbraintree.firstname"] {
            width: 100%;
            padding-right: 0;
            float: none;
        }

        [name="billingAddressbraintree.lastname"] {
            width: 100%;
            float: none;
        }

        [name="billingAddressbraintree.region_id"] {
            width: 100%;
            float: none;
            padding-right: 0;
        }

        [name="billingAddressbraintree.country_id"] {
            width: 100%;
            float: none;
            padding-right: 0;
        }

        [name="billingAddressbraintree.postcode"] {
            width: 100%;
            float: none;
        }
    }
}

@media screen and (max-width: $screen__xs) {

    .payment-method-braintree {

        .field.cvv,
        .field.date {
            width: 100%;
            float: none;
        }

        .ccard > .field.cvv {
            padding-left: 0;
        }
    }
}

.checkout-payment-method .payment-method-paypal_express {

    .payment-option-title .action-toggle {
        color: #1979c3;
        display: inline-block;
        text-decoration: none;
        font-size: 14px;
        text-transform: initial;
        font-weight: 400;
    }

    .actions-toolbar {
        .primary {
            width: 100%;

            .action.primary {
                background-color: #F8BF38 !important;
                border-color: unset !important;

                &:hover {
                    background-color: #F2BA37 !important;
                    border-color: unset !important;
                }
            }
        }
    }  
}

.checkout-payment-method .payment-option-title .action-toggle {
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
}
