/* CONTACT */
.contact-index-index {
    .page-title-wrapper {
        display: none;
    }

    .column.main {
        margin-bottom: 0;

        .section-title {
            background: no-repeat center;
            background-size: cover;

            > .row > div {
                margin-bottom: 0;

                h1 {
                    margin-bottom: 0 !important;
                    color: #fff;
                }
            }

            h3 {
                margin-top: 0;
                margin-bottom: 0;
                color: $c-black;
                font-size: 20px;
                font-weight: 700;
            }

            p, a.store-mail {
                color: #666;
            }

            a.store-phone {
                color: $c-black;
            }

            a:hover {
                color: $c-black;
            }
        }

        .section-title {
            margin-left: -webkit-calc(50% - 50vw);
            margin-left: calc(50% - 50vw);
            margin-right: -webkit-calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
        }

        .section-content {
            text-align: center;

            .contact-form {
                margin-top: 40px;

                .messages, fieldset legend, label {
                    display: none;
                }

                .field {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 10px;
                    padding: 0 5px;
                    vertical-align: top;

                    &.halfwidth {
                        width: 50%;
                    }

                    .control {
                        width: 100%;
                    }

                    input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"], select, textarea {
                        width: 100%;
                        height: 48px;
                        padding: 12px;
                        border-color: #f4f4f4;
                        background-color: #f4f4f4;
                    }

                    textarea {
                        height: 128px;
                    }
                }

                .webforms-fields-contact-file {
                    float: left;
                    display: inline-block;
                    width: auto;

                    input {
                        display: none;
                    }

                    .webforms-fields-comment {
                        text-align: left;
                        @extend .font-size-16px;

                        label {
                            display: block;
                            margin-top: 10px;
                            color: $c-black;
                            font-weight: 700;
                            cursor: pointer;
                        }
                    }

                    .file-preview {
                        position: absolute;

                        li {
                            padding: 0;

                            * {
                                margin-top: 0;
                                margin-bottom: 0;
                                @extend .font-size-16px;
                            }

                            h3 {
                                display: inline-block;
                                margin-right: 15px;
                                text-transform: none;

                                &:after {
                                    content: ' a bien été attaché à votre message';
                                }
                            }

                            p, img {
                                display: none;
                            }
                        }
                    }
                }

                .actions-toolbar {
                    float: right;
                    display: inline-block;
                    margin: -90px 0 0 0;

                    .primary {
                        button.action.submit.primary {
                            display: inline-block;
                            padding: 12px 20px;
                            font-size: 13px;
                            color: #fff;
                            background-color: #df5d24;
                            border: 1.5px solid #df5d24;
                            border-radius: 4px;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            transition: background-color 0.24s ease, color 0.24s ease, border-color 0.24s ease;
                            border-width: 2px;

                            &:hover {
                                text-decoration: none;
                                background-color: #b54a1b;
                                color: #fff;
                                border-color: #b54a1b;
                                border-width: 2px;
                            }
                        }
                    }

                    .secondary {
                        .please-wait span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $screen--m) {
    .contact-index-index {
        .column.main {
            .section-title {
                margin-left: 0;
                margin-right: 0;
            }

            .section-content {
                .contact-form {
                    margin-top: 40px;

                    .field {
                        display: block;
                        width: 100% !important;
                        padding: 0;
                    }

                    .webforms-fields-contact-file {
                        float: none;
                        display: block;

                        .webforms-fields-comment {
                            text-align: left;
                            font-size: 16px;
                        }

                        .file-preview {
                            position: static;
                            text-align: left;
                        }
                    }

                    .actions-toolbar {
                        float: none;
                        display: block;
                        margin-top: 0;

                        .primary {
                            button.action.submit.primary {
                                display: block;
                                width: 100%;
                                padding: 7px 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}