
// Shipping step
.opc-wrapper {

    .fieldset > .field .note:before,
    .fieldset > .fields > .field .note:before {
        content: none;
    }

    .form-login .fieldset .note,
    .form-shipping-address .fieldset .note {
        color: $c_text-light;
    }

    .form-login {
        margin-bottom: 60px;

        .actions-toolbar {
            margin-left: 0 !important;
        }

        .action.remind {
            color: $c_text-light;
            text-decoration: underline;
            text-underline-offset: 0.25rem;

            &:hover {
                color: $c_text-medium;
            }
        }

        input[name="recaptcha-validate-"] {
            visibility: hidden;
        }
    }

    // logged in address
    .shipping-address-items {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .shipping-address-item {
            position: relative;
            width: 100%;
            padding: 20px 20px 20px 60px;
            margin-bottom: 10px;
            margin-right: 5px;
            border-radius: 6px;
            border: 0;
            background-color: $c_bg-grey;
            color: $c_text-light;
            cursor: pointer;

            a {
                color: $c_text-light;
            }

            p {
                margin-bottom: 0;
                line-height: 1.5;
            }

            .name {
                font-size: 1.6rem;
                font-weight: bold;
                margin-bottom: 15px;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &:before {
                content: "";
                display: block !important;
                height: 20px;
                width: 20px;
                position: absolute;
                left: 25px;
                top: 22px;
                border: none;
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 18px 18px;
                background-image: url("../images/icons/radio.svg");
            }

            &.selected-item {
                border: none;
                color: $c_text-dark;
                pointer-events: none;
                cursor: default;

                a {
                    color: $c_text-dark;
                }

                &:after {
                    content: none;
                }

                &:before {
                    background-image: url("../images/icons/radio-checked.svg");
                }

                button {
                    display: none;
                }
            }
        }
    }

    .action-show-popup {
        display: block;
        padding: 0 0 2px 0;
        margin-bottom: 0;
        margin-top: 20px;
        border: none;
        background-color: transparent;
        color: $c_text-dark;
        text-decoration: underline;
        text-underline-offset: 0.18rem;

        > span:before {
            content: none;
        }
    }
}

#shipping-new-address-form {

    .field.street {

        .control .field > .label {
            display: none;
        }
    }

    [name="shippingAddress.firstname"] {
        width: 50%;
        padding-right: 15px;
        float: left;
    }

    [name="shippingAddress.lastname"] {
        width: 50%;
        float: right;
    }

    [name="shippingAddress.company"] {
        display: none;
    }

    [name="shippingAddress.region_id"] {
        width: 33%;
        float: left;
        padding-right: 15px;
    }

    [name="shippingAddress.country_id"] {
        width: 33%;
        float: left;
        padding-right: 15px;
    }

    [name="shippingAddress.postcode"] {
        width: 33%;
        float: right;
    }

    [name="shippingAddress.telephone"] {
        clear: both;
    }
}

@media screen and (max-width: $screen__m) {

    .opc-wrapper {

        .form-login {

            .actions-toolbar {
                margin-top: 30px;
            }

            .action.remind {
                margin-top: 20px;
            }

            .checkout-shipping-address {

                .action.action-show-popup {
                    margin-bottom: 20px;
                }
            }
        }
    }


}

@media screen and (max-width: $screen__s) {

    .opc-wrapper {

        .form-login {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    #shipping-new-address-form {

        [name="shippingAddress.firstname"] {
            width: 100%;
            padding-right: 0;
            float: none;
        }

        [name="shippingAddress.lastname"] {
            width: 100%;
            float: none;
        }

        [name="shippingAddress.region_id"] {
            width: 100%;
            float: none;
            padding-right: 0;
        }

        [name="shippingAddress.country_id"] {
            width: 100%;
            float: none;
            padding-right: 0;
        }

        [name="shippingAddress.postcode"] {
            width: 100%;
            float: none;
        }
    }
}
