
.checkout-onepage-success {
    background: $c_bg-grey;

    .page-main {
        max-width: 700px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 0 auto;
    }

    // Temporary adjustment
    .navigation.ms-megamenu .ms-topmenu .ms-label {
        font-size: 1.8rem;
    }

    h1 {
        color: $c_primary;
        font-weight: bold;
        font-size: 2.8rem;
        letter-spacing: 1px;
    }

    h2 {
        margin-top: 0;
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: 1px;
    }

    h3 {
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 1px;
    }
}

.checkout-success-title {
    margin-top: 80px;

    p {
        font-size: 1.6rem;
        letter-spacing: 1px;
    }
}

#registration {
    width: 100%;
    min-height: 200px;
    padding: 40px 20px;
    margin: 40px 0;
    border: 1px solid $c_text-dark;

    .tracking {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .info {
            text-align: center;
            margin: 0 0 30px 0;

            p {
                margin-bottom: 0;
                margin-top: 0;
                font-size: 1.6rem;
            }

            .email {
                color: $c_primary;
            }
        }
    }
}

.checkout-success {
    margin-bottom: 80px;

    .order-information {
        margin-top: 50px;

        h2 {
            margin-bottom: 0;
        }

        h3 {
            margin-top: 0;
        }

        p {
            margin-bottom: 0;
            line-height: 1.5;
        }

        &--row {
            display: flex;
            border-bottom: 1px solid $c_border-grey;
            padding: 50px 0;

            > div {
                flex-basis: 100%;
                flex-grow: 1;
            }
        }

        address {

            [href^="tel:"] {
                color: $c_text-dark;
            }
        }
    }

    .minicart-items {
        width: 100%;
    }

    .product {
        display: flex;
        justify-content: space-between;

        &:before,
        &:after {
            content: none !important;
        }

        &-item {
            padding-left: 0 !important;
            border-top: 0 !important;
            margin-bottom: 0 !important;

            &:last-of-type {
                padding-bottom: 0 !important;
            }
        }

        .product-image-container {
            flex-grow: 0;
            max-width: 100px;
        }

        .product-item-name-container {
            padding: 0 15px;

            .product-item-name {
                font-weight: bold;
                color: $c_text-medium;
            }
        }

        .product-item-options {
            color: $c_text-light;

            .option {
                margin-bottom: 7px;
            }
        }

        .product-item-price {
            text-align: right;

            .price {
                color: $c_text-medium;
                font-weight: bold;
                font-size: 1.4rem;
            }

            .details-qty {
                color: $c_text-light;
                margin-top: 8px;
                display: block;
            }
        }
    }

    .actions-toolbar {
        margin-top: 50px;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: $screen__m) {

    .checkout-onepage-success {
        .page-main {
            .column.main {
                margin: 0 20px;
            }
        }
    }

    .checkout-success-title {
        margin-top: 50px;
        text-align: center;
    }

    #registration {
        text-align: center;
    }

    .order-information {

        &--row {
            flex-direction: column;

            > div:not(:first-of-type) {
                margin-top: 40px;
            }
        }
    }

    .checkout-success {
        margin-bottom: 40px;

        .product {
            flex-direction: column;
            position: relative;
            padding-left: 120px;

            .product-image-container {
                position: absolute;
                left: 0;
            }

            .product-item-name-container {
                padding-left: 0;
            }

            .product-item-price {
                text-align: left;
                margin-top: 25px;
            }
        }
    }
}