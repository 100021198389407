//
//  Navigation
//  _____________________________________________

@mixin lib-main-navigation(
    $_nav__indent-side                        : 15px,
    $_nav-background-color                    : $navigation__background,
    $_nav-border                              : $navigation__border,
    $_nav-level0-font-size                    : $navigation-level0-item__font-size,
    $_nav-level0-font-weight                  : $navigation-level0-item__font-weight,
    $_nav-level0-item-line-height             : $navigation-level0-item__line-height,
    $_nav-level0-item-margin                  : $navigation-level0-item__margin,
    $_nav-level0-item-padding                 : $navigation-level0-item__padding,
    $_nav-level0-text-transform               : $navigation-level0-item__text-transform,

    $_nav-level0-item-background-color        : $navigation-level0-item__background,
    $_nav-level0-item-border                  : $navigation-level0-item__border,
    $_nav-level0-item-color                   : $navigation-level0-item__color,
    $_nav-level0-item-text-decoration         : $navigation-level0-item__text-decoration,

    $_nav-level0-item-background-color-active : $navigation-level0-item__active__background,
    $_nav-level0-item__active__border-color   : $navigation-level0-item__active__border-color,
    $_nav-level0-item__active__border-style   : $navigation-level0-item__active__border-style,
    $_nav-level0-item__active__border-width   : $navigation-level0-item__active__border-width,
    $_nav-level0-item-color-active            : $navigation-level0-item__active__color,
    $_nav-level0-item-text-decoration-active  : $navigation-level0-item__active__text-decoration,

    $_submenu-background-color                : $submenu__background,
    $_submenu-border                          : $submenu__border,
    $_submenu-font-size                       : $submenu__font-size,
    $_submenu-font-weight                     : $submenu__font-weight,
    $_submenu-line-height                     : $submenu-item__line-height,
    $_submenu-item__padding-top               : $submenu__padding-top,
    $_submenu-item__padding-right             : $submenu__padding-right,
    $_submenu-item__padding-bottom            : $submenu__padding-bottom,
    $_submenu-item__padding-left              : $submenu__padding-left,

    $_submenu-item-background-color           : $submenu-item__background,
    $_submenu-item-border                     : $submenu-item__border,
    $_submenu-item-color                      : $submenu-item__color,
    $_submenu-item-text-decoration            : $submenu-item__text-decoration,

    $_submenu-item-background-color-active    : $submenu-item__active__background,
    $_submenu-item__active__border            : $submenu-item__active__border,
    $_submenu-item__active__border-color      : $submenu-item__active__border-color,
    $_submenu-item__active__border-style      : $submenu-item__active__border-style,
    $_submenu-item__active__border-width      : $submenu-item__active__border-width,
    $_submenu-item-color-active               : $submenu-item__active__color,
    $_submenu-item-text-decoration-active     : $submenu-item__active__text-decoration
) {
    .navigation {
        @include lib-css(background, $_nav-background-color);
        @include lib-css(border, $_nav-border);
        box-sizing: border-box;

        ul {
            margin: 0;
            padding: 0;
        }

        li {
            margin: 0;
        }

        a {
            display: block;
            @include lib-css(padding, $_submenu-item__padding-top $_submenu-item__padding-right $_submenu-item__padding-bottom $_submenu-item__padding-left);
        }

        a,
        a:hover {
            @include lib-css(color, $_nav-level0-item-color);
            @include lib-css(text-decoration, $_nav-level0-item-text-decoration);
        }

        .level0 {
            @include lib-css(border-top, $_nav-level0-item-border);
            @include lib-font-size($_nav-level0-font-size);

            > .level-top {
                @include lib-css(background, $_nav-level0-item-background-color);
                @include lib-css(font-weight, $_nav-level0-font-weight);
                @include lib-css(line-height, $_nav-level0-item-line-height);
                @include lib-css(padding, $_nav-level0-item-padding);
                @include lib-css(text-transform, $_nav-level0-text-transform);
                word-wrap: break-word;
            }

            &.active {
                .all-category {
                    .ui-state-focus {
                        @include lib-css(background, $_nav-level0-item-background-color-active);
                        @include lib-css(border-color, $_nav-level0-item__active__border-color);
                        @include lib-css(border-style, $_nav-level0-item__active__border-style);
                        @include lib-css(border-width, $_nav-level0-item__active__border-width);
                        @include lib-css(color, $_nav-level0-item-color-active);
                        @include lib-css(padding-left, $_nav__indent-side - $_submenu-item__active__border);
                        @include lib-css(text-decoration, $_nav-level0-item-text-decoration-active);
                        display: inline-block;
                    }
                }
            }

            > .level1 {
                @include lib-css(font-weight, $font-weight__semibold);
            }

            &.active,
            &.has-active { // ToDo UI: remove "has_active" here, when mobile navigation default open state is implemented
                > a:not(.ui-state-active) {
                    @include lib-css(background, $_nav-level0-item-background-color-active);
                    @include lib-css(border-color, $_nav-level0-item__active__border-color);
                    @include lib-css(border-style, $_nav-level0-item__active__border-style);
                    @include lib-css(border-width, $_nav-level0-item__active__border-width);
                    @include lib-css(color, $_nav-level0-item-color-active);
                    @include lib-css(text-decoration, $_nav-level0-item-text-decoration-active);

                    span:not(.ui-menu-icon) {
                        @include lib-css(margin-left, -$_submenu-item__active__border);
                    }
                }
            }
        }

        li.level0 {
            &:last-child {
                @include lib-css(border-bottom, $_nav-level0-item-border);
            }
        }

        .submenu {
            > li {
                word-wrap: break-word;
            }

            &:not(:first-child) {
                @include lib-css(background, $_submenu-background-color);
                @include lib-css(border, $_submenu-border);
                @include lib-css(font-size, $_submenu-font-size);
                @include lib-css(font-weight, $_submenu-font-weight);
                @include lib-css(line-height, $_submenu-line-height);
                left: auto !important;
                overflow-x: hidden;
                padding: 0;
                position: relative;
                top: auto !important;
                transition: left 0.3s ease-out;

                > li {
                    > a {
                        @include lib-css(padding-left, $_nav__indent-side);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                ul {
                    display: block;
                    @include lib-css(padding-left, $_submenu-item__padding-left);

                    > li {
                        margin: 0;

                        a {
                            @include lib-css(background, $_submenu-item-background-color);
                            @include lib-css(border, $_submenu-item-border);
                            @include lib-css(color, $_submenu-item-color);
                            @include lib-css(text-decoration, $_submenu-item-text-decoration);
                            display: block;
                            line-height: normal;
                        }
                    }
                }

                &.expanded {
                    display: block !important;
                    padding-right: 0;
                    top: 0 !important;
                }

                .active {
                    > a {
                        @include lib-css(background, $_submenu-item-background-color-active);
                        @include lib-css(border-color, $_submenu-item__active__border-color);
                        @include lib-css(border-style, $_submenu-item__active__border-style);
                        @include lib-css(border-width, $_submenu-item__active__border-width);
                        @include lib-css(color, $_submenu-item-color-active);
                        @include lib-css(padding-left, $_nav__indent-side - $_submenu-item__active__border);
                        @include lib-css(text-decoration, $_submenu-item-text-decoration-active);
                    }
                }

                .level1 {
                    &.active {
                        > a {
                            @include lib-css(padding-left, $_nav__indent-side - $_submenu-item__active__border);
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@mixin lib-main-navigation-desktop(
    $_nav-background-color                    : $navigation-desktop__background,
    $_nav-border                              : $navigation-desktop__border,
    $_nav-font-size                           : $navigation-desktop__font-size,
    $_nav-font-weight                         : $navigation-desktop__font-weight,

    $_nav-level0-item-line-height             : $navigation-desktop-level0-item__line-height,
    $_nav-level0-item-margin                  : $navigation-desktop-level0-item__margin,
    $_nav-level0-item-padding                 : $navigation-desktop-level0-item__padding,

    $_nav-level0-item-background-color        : $navigation-desktop-level0-item__background,
    $_nav-level0-item-border                  : $navigation-desktop-level0-item__border,
    $_nav-level0-item-color                   : $navigation-desktop-level0-item__color,
    $_nav-level0-item-text-decoration         : $navigation-desktop-level0-item__text-decoration,

    $_nav-level0-item-background-color-hover  : $navigation-desktop-level0-item__hover__background,
    $_nav-level0-item-border-hover            : $navigation-desktop-level0-item__hover__border,
    $_nav-level0-item-color-hover             : $navigation-desktop-level0-item__hover__color,
    $_nav-level0-item-text-decoration-hover   : $navigation-desktop-level0-item__hover__text-decoration,

    $_nav-level0-item-background-color-active : $navigation-desktop-level0-item__active__background,
    $_nav-level0-item__active__border-color   : $navigation-desktop-level0-item__active__border-color,
    $_nav-level0-item__active__border-style   : $navigation-desktop-level0-item__active__border-style,
    $_nav-level0-item__active__border-width   : $navigation-desktop-level0-item__active__border-width,
    $_nav-level0-item-color-active            : $navigation-desktop-level0-item__active__color,
    $_nav-level0-item-text-decoration-active  : $navigation-desktop-level0-item__active__text-decoration,

    $_submenu-background-color                : $submenu-desktop__background,
    $_submenu-border-width                    : $submenu-desktop__border-width,
    $_submenu-border-style                    : $submenu-desktop__border-style,
    $_submenu-border-color                    : $submenu-desktop__border-color,
    $_submenu-box-shadow                      : $submenu-desktop__box-shadow,
    $_submenu-font-size                       : $submenu-desktop__font-size,
    $_submenu-font-weight                     : $submenu-desktop__font-weight,
    $_submenu-min-width                       : $submenu-desktop__min-width,
    $_submenu-padding                         : $submenu-desktop__padding,

    $_submenu-arrow                           : $submenu-desktop-arrow,
    $_submenu-arrow-size                      : $submenu-desktop-arrow__size,
    $_submenu-arrow-left                      : $submenu-desktop-arrow__left,

    $_submenu-item-padding                    : $submenu-desktop-item__padding,
    $_submenu-item-background-color           : $submenu-desktop-item__background,
    $_submenu-item-border                     : $submenu-desktop-item__border,
    $_submenu-item-color                      : $submenu-desktop-item__color,
    $_submenu-item-text-decoration            : $submenu-desktop-item__text-decoration,

    $_submenu-item__hover__background-color   : $submenu-desktop-item__hover__background,
    $_submenu-item-border-hover               : $submenu-desktop-item__hover__border,
    $_submenu-item-color-hover                : $submenu-desktop-item__hover__color,
    $_submenu-item-text-decoration-hover      : $submenu-desktop-item__hover__text-decoration,

    $_submenu-item-background-color-active    : $submenu-desktop-item__active__background,
    $_submenu-item__active__border-color      : $submenu-desktop-item__active__border-color,
    $_submenu-item__active__border-style      : $submenu-desktop-item__active__border-style,
    $_submenu-item__active__border-width      : $submenu-desktop-item__active__border-width,
    $_submenu-item-color-active               : $submenu-desktop-item__active__color,
    $_submenu-item-text-decoration-active     : $submenu-desktop-item__active__text-decoration
) {

    .navigation {
        @include lib-css(background, $_nav-background-color);
        @include lib-css(border, $_nav-border);
        @include lib-css(font-size, $_nav-font-size);
        @include lib-css(font-weight, $_nav-font-weight);
        height: inherit;
        left: auto;
        overflow: inherit;
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 3;

        &:empty {
            display: none;
        }

        ul {
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;
            position: relative;
        }

        li.level0 {
            @include lib-css(border-top, none);
        }

        .level0 {
            @include lib-css(margin, $_nav-level0-item-margin);
            display: inline-block;
            position: relative;

            &:last-child {
                margin-right: 0;
                padding-right: 0;
            }

            > .level-top {
                @include lib-css(background, $_nav-level0-item-background-color);
                @include lib-css(border, $_nav-level0-item-border);
                @include lib-css(color, $_nav-level0-item-color);
                @include lib-css(line-height, $_nav-level0-item-line-height);
                @include lib-css(padding, $_nav-level0-item-padding);
                @include lib-css(text-decoration, $_nav-level0-item-text-decoration);
                box-sizing: border-box;
                position: relative;

                &:hover,
                &.ui-state-focus {
                    @include lib-css(background, $_nav-level0-item-background-color-hover);
                    @include lib-css(border, $_nav-level0-item-border-hover);
                    @include lib-css(color, $_nav-level0-item-color-hover);
                    @include lib-css(text-decoration, $_nav-level0-item-text-decoration-hover);
                }
            }

            &.active,
            &.has-active {
                > .level-top {
                    @include lib-css(background, $_nav-level0-item-background-color-active);
                    @include lib-css(border-color, $_nav-level0-item__active__border-color);
                    @include lib-css(border-style, $_nav-level0-item__active__border-style);
                    @include lib-css(border-width, $_nav-level0-item__active__border-width);
                    @include lib-css(color, $_nav-level0-item-color-active);
                    @include lib-css(text-decoration, $_nav-level0-item-text-decoration-active);
                    display: inline-block;
                }
            }

            &.parent:hover > .submenu {
                overflow: visible !important;
            }

            .submenu {
                @include lib-css(background, $_submenu-background-color);
                @include lib-css(border, $_submenu-border-width $_submenu-border-style $_submenu-border-color);
                @include lib-css(box-shadow, $_submenu-box-shadow);
                @include lib-css(font-size, $_submenu-font-size);
                @include lib-css(font-weight, $_submenu-font-weight);
                @include lib-css(min-width, $_submenu-min-width);
                @include lib-css(padding, $_submenu-padding);
                display: none;
                left: 0;
                margin: 0 !important;
                padding: 0;
                position: absolute;
                z-index: 1;

                @include _lib-submenu-arrow(
                    $_submenu-arrow,
                    $_bg     : $_submenu-background-color,
                    $_border : $_submenu-border-color,
                    $_size   : $_submenu-arrow-size,
                    $_left   : $_submenu-arrow-left
                );

                a {
                    display: block;
                    line-height: inherit;
                    @include lib-css(background, $_submenu-item-background-color);
                    @include lib-css(border, $_submenu-item-border);
                    @include lib-css(color, $_submenu-item-color);
                    @include lib-css(padding, $_submenu-item-padding);
                    @include lib-css(text-decoration, $_submenu-item-text-decoration);

                    &:hover,
                    &.ui-state-focus {
                        @include lib-css(background, $_submenu-item__hover__background-color);
                        @include lib-css(border, $_submenu-item-border-hover);
                        @include lib-css(color, $_submenu-item-color-hover);
                        @include lib-css(text-decoration, $_submenu-item-text-decoration-hover);
                    }
                }

                .active > a {
                    @include lib-css(background, $_submenu-item-background-color-active);
                    @include lib-css(border-color, $_submenu-item__active__border-color);
                    @include lib-css(border-style, $_submenu-item__active__border-style);
                    @include lib-css(border-width, $_submenu-item__active__border-width);
                    @include lib-css(color, $_submenu-item-color-active);
                    @include lib-css(text-decoration, $_submenu-item-text-decoration-active);
                }

                .submenu {
                    top: 0 !important;
                    left: 100% !important;
                }

                .submenu-reverse {
                    left: auto !important;
                    right: 100%;
                }
            }

            &.more {
                position: relative;
                @include lib-icon-font(
                    $icon-pointer-down,
                    $_icon-font-size     : 26px,
                    $_icon-font-position : after
                );

                &:before {
                    display: none;
                }

                &:after {
                    cursor: pointer;
                    padding: 8px 12px;
                    position: relative;
                    z-index: 1;
                }

                &:hover > .submenu {
                    overflow: visible !important;
                }

                li {
                    display: block;
                }
            }
        }
    }
}

//  Submenu arrow
@mixin _lib-submenu-arrow(
    $_submenu-arrow,
    $_bg,
    $_border,
    $_size,
    $_left
){
    @if $_submenu-arrow == true {
        @if iscolor($_bg) and iscolor($_border) {
            $_outer-size: $_size + 1;
            $_outer-left: $_left - 1;
            @include lib-css(margin-top, $_outer-size);

            > ul {
                @include lib-css(margin-top, $_outer-size);

                &:before,
                &:after {
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                }

                &:before {
                    @include lib-css(color, $_bg);
                    @include lib-css(left, $_left);
                    @include lib-css(top, -$_size*2);
                    @include lib-arrow(up, $_size, $_bg);
                    z-index: 4;
                }

                &:after {
                    @include lib-arrow(up, $_outer-size, $_border);
                    @include lib-css(color, $_border);
                    @include lib-css(left, $_outer-left);
                    @include lib-css(top, -$_outer-size*2);
                    z-index: 3;
                }
            }
        }

        @if iscolor($_bg) and iscolor($_border) == false {
            @include lib-css(margin-top, -$_size);

            > ul {
                @include lib-css(margin-top, $_size);

                &:before {
                    @include lib-arrow(up, $_size, $_bg);
                    @include lib-css(color, $_bg);
                    @include lib-css(left, $_left);
                    @include lib-css(top, -$_size*2);
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    z-index: 4;
                }
            }
        }

        @if iscolor($_border) and iscolor($_bg) == false {
            @include lib-css(margin-top, -$_size);

            > ul {
                @include lib-css(margin-top, $_size);

                &:before {
                    @include lib-arrow(up, $_size, $_border);
                    @include lib-css(color, $_border);
                    @include lib-css(left, $_left);
                    @include lib-css(top, -$_size*2);
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    z-index: 4;
                }
            }
        }
    }
}
