.mgz-block {
    .mgz-block-heading.mgz-block-heading-line:before {
        display: none;
    }
    .block-title {
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 25px;
        color: $c_grey;
        text-align: center;
    }

    &-content {
        .product-items {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;

            .item.product,
            .product-item {
                position: relative;
                padding: 8px;
                list-style-type: none;
                box-sizing: border-box;

                .action.towishlist {
                    display: none;
                }

                .product-label-tag {
                    position: absolute;
                    right: 4px;
                    top: 30px;
                    background-color: $c_black;
                    padding: 4px 6px;
                    z-index: 10;
                    min-width: 46px;
                    text-align: center;
                    border-radius: 2px;
                    font-weight: 600;

                    &--discount {
                        background-color: $c_primary;
                    }

                    &__best-seller {
                        background-color: $c_black;
                    }

                    &__new {
                        background-color: $c_secondary;
                    }

                    span {
                        color: $c_white;
                        font-size: 14px;
                    }
                }

                .product-label-tag + .product-label-tag {
                    top: 64px;
                }

                .product-item-photo {
                    display: block;
                    width: 100%;
                    padding-top: 100%;
                    position: relative;
                    background-color: $c_text-lighter;

                    &:focus {
                        outline: none;
                    }

                    .product-image-container {
                        width: 100% !important;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }

                    .product-image-wrapper {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .product-image-photo {
                            width: 100%;
                        }
                    }
                }

                .product-item-name {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .product-item-brand {
                        text-transform: uppercase;
                        color: $c_black;
                        min-height: 20px;
                        font-weight: 600;
                    }
                }

                .product-item-details {
                    display: flex;
                    flex-direction: column;

                    .product-item-brand {
                        text-transform: uppercase;
                        color: $c_black;
                        min-height: 20px;
                        font-weight: 600;
                    }

                    .product-item-link {
                        text-transform: lowercase;

                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }
                }

                .product-item-details {
                    padding: 12px 20px 20px;
                    background-color: $c_white;
                    min-height: 108px;

                    .name-price-wrapper {
                        display: flex;
                        justify-content: space-between;
                        min-height: 40px;
                    }

                    .details-widget {
                        flex-wrap: nowrap;
                    }

                    &__swatches {
                        width: 100%;
                        min-height: 36px;

                        .swatch-attribute-options {
                            display: flex;
                            justify-content: center;
                            margin-top: 0;
                            margin-bottom: 20px;

                            .swatch-option {
                                &.selected {
                                    outline: none;
                                }

                                &.color,
                                &.image {
                                    position: relative;
                                    margin: 0 13px 0 0;
                                    min-width: 16px;
                                    border-radius: 50%;
                                    width: 16px !important;
                                    height: 16px !important;

                                    &:hover,
                                    &.selected {
                                        outline: none;
                                        border: 1px solid $c_white;

                                        &:after {
                                            content: '';
                                            display: block;
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate3d(-50%, -50%, 1px);
                                            width: 12px;
                                            height: 12px;
                                            border: 2px solid $c_white;
                                            border-radius: 50%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .product-item-price {
                    text-align: right;

                    .price-box {
                        display: flex;
                        flex-direction: column;
                        font-weight: 600;
                    }

                    .special-price {
                        color: $c_primary;
                    }

                    .old-price {
                        text-decoration: line-through;
                    }

                    .price-label {
                        display: none;
                    }
                }

                .product-item-link {
                    color: $c_text-medium;
                }
            }
        }
    }
}

.fotorama__fullscreen {
    .fotorama--fullscreen {
        background: #fff;
    }
}