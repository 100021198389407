body {

    &.navigation-mobile-open {
        overflow-y: hidden;
        height: 100vh;
        position: relative;

        .navigation-main-wrapper {
            .navigation {
                left: 0;
                width: 100%;
            }
        }
    }

    &.navigation-mobile-loaded {

        .page-header {
            visibility: visible;
        }
    }

    &.minicart-sidebar-open {
        overflow-y: hidden;
        height: 100vh;
        position: relative;

        .modals-wrapper {
            position: fixed;
            z-index: 10000;
            background: rgba(28, 32, 40, 0.8);
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            transition: opacity 0.48s ease-in-out;
            overflow-y: auto;
        }
    }

    .navigation-mobile-menu {

        @media screen and (max-width: $screen--l) {
            display: flex;
        }

        .navigation-mobile-open & {
            display: none;
        }
    }

    .navigation-mobile-close {

        .navigation-mobile-open & {
            display: inline-block;
        }
    }
}

.page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999;
    transform: none !important;
    border-bottom: none;

    body.navigation-hover &,
    body.search-opened &{

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 200vh;
            background-color: rgba(28, 32, 40, 0.6);
            z-index: 5;
        }
    }
}

.top-header-message {
    background-color: $c_primary;
    display: none;

    &.opened {
        display: block;
    }

    .wrapper {
        max-width: 1280px;
        padding: 3px 20px;
        margin: 0 auto;
        text-align: center;
        color: $c_white;
        font-weight: 600;
        display: flex;
        align-items: center;

        a {
            color: $c-white;
            text-decoration: underline;
            text-underline-offset: 0.18rem;
        }

        .top-header-message__message {
            flex: 1;
        }
    }

    &__close {
        float: right;
        cursor: pointer;
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.panel.wrapper {
    background-color: $c-darkblue-bg;
}

.panel.header {
    display: flex;
    align-items: center;
    padding-top: 8px;

    .action {
        display: none;
    }

    .top-nav {
        list-style-type: none;
        display: flex;
        padding: 0;
        margin-bottom: 0;

        li {
            margin-bottom: 0;
        }

        li + li {
            margin-left: 40px;
        }

        a {
            color: $c-white;
            font-size: 12px;

            &:hover {
                @media screen and (min-width: $screen--s) {
                    text-decoration: underline;
                    text-underline-offset: 0.18rem;
                }
            }
        }

        &--left {
            flex-grow: 1;
        }

        &__link {
            display: flex;
            align-items: center;

            &--contact,
            &--our-stores,
            &--rdv,
            &--question {

                &:before {
                    content: '';
                    width: 12px;
                    height: 12px;
                    display: block;
                    background-size: 12px;
                    background-repeat: no-repeat;
                    margin-right: 6px;
                }
            }

            &--contact {

                &:before {
                    width: 20px;
                    height: 15px;
                    background-size: 20px;
                    background-image: url('../images/icons/mail.svg');
                }
            }

            &--our-stores {

                &:before {
                    background-image: url('../images/icons/locate.svg');
                }
            }

            &--rdv {

                &:before {
                    width: 17px;
                    height: 17px;
                    background-size: 20px;
                    background-image: url('../images/icons/calender.svg');
                }
            }

            &--question {

                &:before {
                    background-image: url('../images/icons/question.svg');
                    width: 16px;
                    height: 14px;
                    background-size: 18px;
                }
            }

            @media screen and (max-width: $screen--l) {
                padding: 0;
            }
        }
    }

    .switcher-language {

        .switcher-label {
            display: none;
        }

        .switcher-options {

            .switcher-trigger {
                display: none;
            }

            .switcher-dropdown {
                display: block;
                position: static;
                margin-top: 0;
                margin-left: 40px;
                padding: 0;
                min-width: 0;
                background-color: transparent;
                border: none;
                box-shadow: none;
                list-style-type: none;
                margin-bottom: 0;

                @media screen and (max-width: $screen--m) {
                    padding-bottom: 50px;
                }

                &:after,
                &:before {
                    content: initial;
                }

                .switcher-option {
                    margin-bottom: 0;

                    a {
                        font-size: 12px;
                    }
                }

                li {

                    &:hover {
                        background-color: transparent;
                    }
                }

                a {
                    padding: 0;
                    color: $c-white;

                    &:hover {
                        color: $c-white;
                        background-color: transparent;
                        text-decoration: underline;
                    }
                }
            }
        }

        @media screen and (max-width: $screen--l) {
            display: block;

            .switcher-options .switcher-dropdown .switcher-option a {
                font-size: 14px;
            }
        }
    }

    @media screen and (max-width: $screen--l) {
        display: none;
    }
}

.header.content {
    position: initial;
    display: flex;
    align-items: center;
    padding-top: 0;
    height: 80px;

    .logo {
        margin: 0;

        a {
            display: block;
        }
    }

    .amsearch-wrapper-block {
        order: 4;
    }

    .block-search {
        position: initial;
        width: auto;
        order: 9;
        margin-right: 20px;
        margin-top: 0;
        z-index: 6;

        .control {
            padding: 0;
            border-top: 0;
            position: unset;
            min-height: auto;
        }

        .amsearch-form-container.-opened .input-text::-webkit-input-placeholder {
            color: #cecece;
            font-style: normal
        }

        .amsearch-form-container.-opened .input-text::-moz-placeholder {
            color: #cecece;
            font-style: normal
        }

        .amsearch-form-container.-opened .input-text:-ms-input-placeholder {
            color: #cecece;
            font-style: normal
        }

        .control-field {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(100% - 2px);
            padding: 28px 60px;
            background-color: $c_bg-grey;
            align-items: center;
            display: none;

            .label {
                float: none;
                width: auto;
                height: auto;
                clip: auto;

                &:before {
                    display: none;
                    content: '';
                    background-image: url('../images/icons/search.svg');
                    background-size: 24px;
                    background-repeat: no-repeat;
                    width: 24px;
                    height: 24px;
                }
            }

            input {
                padding-left: 36px;
                border: none;
                font-size: 18px;
                background-color: $c_bg-grey;
                text-align: center;

                ::placeholder {
                    color: $c-text-light;
                }
            }

            &.active {
                display: flex;
            }
        }

        .action.search {
            position: initial;

        }

        .action.search-box-toggle {
            padding: 0;
        }

        .minisearch {

            button {
                display: none;

                &:before {
                    content: none;
                }
            }

            .open-icon,
            .close-icon {
                width: 24px;
                height: 24px;
            }

            .close-icon {
                display: none;
            }

            .search-opened & {

                .actions:not(.product-item-actions) {
                    position: relative;

                    &:after {
                        position: absolute;
                        content: '';
                        display: block;
                        height: 3px;
                        width: 34px;
                        background-color: $c-primary;
                        top: 49px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                .open-icon {
                    display: none;
                }

                .close-icon {
                    display: block;
                }

                .control-field {
                    display: flex;
                }
            }
        }

        .search-autocomplete {
            display: none !important;

            @media screen and (max-width: $screen--l) {
                max-width: 100vw;
            }

            .amsearch-products.-waste {
                text-align: center;
                font-size: 16px;
                padding: 20px;
                margin: 0 auto;
                border: none;
            }

            .amasty-xsearch-block-header {
                padding-bottom: 15px;
                font-size: 16px;
                margin-top: 10px;
            }

            .amsearch-leftside {
                .recent_searches,
                .page,
                .category {
                    .amsearch-item {
                        .item-name {
                            font-size: 14px;
                            text-decoration: none;
                            display: inline-block;

                            .amsearch-highlight {
                                font-size: 14px;
                                font-weight: bold;
                                color: #000;
                            }
                        }

                        .item-description {
                            color: #434c5e;
                            font-size: 14px;
                            padding: 5px 0;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .products-grid {
                padding: 15px;
            }

            .amsearch-more-results {
                margin: 20px auto 30px auto;

                .amsearch-link {
                    @extend .q_b-primary--border;
                    text-decoration: none;

                    @media screen and (max-width: $screen--l) {
                        padding-left: 20px !important;
                    }

                    &:active,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }

        .amsearch-results {
            padding: 0;
            max-width: 1280px !important;
            margin: auto;

            .amsearch-leftside {
                width: 46% !important;
                background: #f9f9fb;
            }

            #amasty-shopby-product-list {
                max-width: 83%;
                background: #f9f9fb;

                @media (max-width: 1024px) {
                    max-width: 100%;
                }

                .products {
                    width: 100% !important;
                    background: #f9f9fb;

                    .products.list .item.product,
                    .products.list .product-item {
                        max-width: 333px;

                        .product-item-photo {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        .amsearch-form-container.-opened .amsearch-wrapper-input,
        .search-autocomplete,
        .amsearch-results {
            @media (min-width: 1024px) {
                max-width: 100%;
            }
        }

        .search-autocomplete.-bottom-position {
            top: 100%;
        }

        .search-autocomplete.-bottom-position {
            top: 100%;
        }

        .amsearch-clone-position {
            box-shadow: none;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            background: #f9f9fb;
            z-index: 9999;
            height: calc(87vh - 220px);
            border-top: solid 1px #e9e9eb;

            .category {
                .amsearch-item {
                    padding: 5px 15px 5px 0;
                }
            }

            .page {
                .item-name {
                    font-weight: normal;
                }
            }

            .page,
            .popular_searches {
                .amasty-xsearch-block-header {
                    padding-left: 0;
                }

                .amsearch-item {
                    padding: 5px 15px 5px 0;

                    .item-name, .item-name span {
                        font-size: 14px;
                        color: #000;
                        text-decoration: none;
                    }

                    &:hover {
                        background: transparent;

                        .item-name, .item-name span {
                            color: #000;
                        }
                    }
                }
            }
        }


        @media screen and (max-width: $screen--l) {
            display: none;

            .control {
                margin: 0;
            }
        }
    }

    .header.misc {
        order: 10;

        @media screen and (max-width: $screen--l) {
            display: none;
        }
    }

    .estimation_and_promo {
        display: none;
    }

    .minicart-wrapper {
        order: 11;
        margin-left: 20px;
        max-height: 25px;

        .modal-header {
            .modal-title {
                display: none;
            }

            .action-close {
                z-index: 999;
            }
        }

        .action.showcart {
            display: flex;

            .text {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            .qty {
                display: flex;
                background-color: transparent;
                height: 24px;
                line-height: 24px;
                border-radius: 2px;
                margin: 3px 0 0;
                min-width: 18px;
                overflow: hidden;
                padding: 0 3px;
                text-align: center;
                white-space: normal;

                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }

                &.empty {
                    .qty-wrapper {
                        display: none;
                    }

                    &:before {
                        display: none;
                    }

                    &:after {
                        display: none;
                    }
                }

                .qty-wrapper {
                    .counter-label {
                        border: 0;
                        clip: rect(0, 0, 0, 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }
                }
            }

            &:before {
                content: '';
                background-image: url('../images/icons/cart.svg');
                width: 22px;
                height: 22px;
                background-size: 22px;
                font-size: 35px;
                line-height: 33px;
                color: #8f8f8f;
                font-family: "icons-blank-theme";
                margin: 0;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
        }

        .minicart-title {
            display: none;
        }

        .modal-inner-wrap {
            overflow-x: hidden;

            .block-minicart {
                border: none;
                top: 52px;
                right: 0;
                height: auto;
                box-shadow: unset;

                @media screen and (max-width: $screen--s) {
                    width: 100%;
                    position: fixed;
                    top: 0;
                    right: 0;
                    margin-top: 0;
                }

                &:before,
                &:after {
                    content: none;
                }

                &:after {
                    position: absolute;
                    content: '';
                    display: none;
                    height: 3px;
                    width: 34px;
                    background-color: $c-primary;
                    top: -3px;
                    left: auto;
                    right: 8px;
                    border: none;
                    transform: translateX(-50%);
                }

                #minicart-content-wrapper {

                    .block-title {
                        display: block;

                        @media screen and (max-width: $screen--s) {
                            margin-top: 20px;
                        }
                    }
                }

                .estimation_and_promo {
                    text-align: center;
                    border-top: 1px solid #ccc;
                    padding-top: 20px;
                    display: block;

                    #block-discount-heading, #block-shipping-heading {
                        font-size: 14px;
                        color: #434c5e;
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    .block.shipping {
                        margin-bottom: 50px;

                        #shipping-zip-form {
                            margin-top: 20px;

                            .fieldset > .legend {
                                display: none;
                            }

                            .field.question {
                                margin-bottom: 10px;

                                p {
                                    font-size: 16px;
                                }
                            }

                            .field[name="shippingAddress.region_id"], .field[name="shippingAddress.postcode"] {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                margin: 0;

                                .label {
                                    width: 100%;
                                    padding: 0;
                                    text-align: left;
                                    margin: 10px 0;
                                }

                                .control {
                                    width: 100%;

                                    .input-text, .select {
                                        height: 46px;
                                    }
                                }
                            }

                        }

                        #co-shipping-method-form {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin: 15px 0;
                        }
                    }

                    .block.discount {
                        margin-bottom: 20px;

                        .content {
                            margin: 25px 0;
                        }

                        .fieldset.coupon {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .field, .control {
                                width: 100%;
                            }

                            .field {
                                margin: 0 0 10px;

                                input {
                                    height: 46px;
                                }
                            }

                            .actions-toolbar {
                                .primary {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .block-title {
            display: flex;
            justify-content: center;
            padding: 0;

            .text {
                font-weight: 300;
                line-height: 1.1;
                font-size: 1.8rem;
                margin-top: 0;
                margin-bottom: 1rem;
            }

            .qty {
                font-weight: 300;
                line-height: 1.1;
                font-size: 1.4rem;
                margin-top: 1.5rem;
                margin-bottom: 1rem;

                &:before {
                    content: "(";
                }

                &:after {
                    content: ")";
                }
            }
        }

        .block-content {
            padding: 0;
            height: 100%;
            display: flex;
            flex-direction: column;

            .action.close {
                display: none;
            }

            .items-total {
                display: none;
            }

            .subtotal, .tax, .grand-total {
                margin: 3px 0;
                order: 2;
                display: flex;
                justify-content: space-between;
                padding-left: 20px;
                padding-right: 20px;

                .label {
                    display: block;
                }

                .price, .value {
                    font-size: 14px;
                }
            }

            .actions {
                padding-left: 20px;
                padding-right: 20px;
                order: 3;

                > .primary {
                    margin: 0 0 30px;
                }

                > .secondary {
                    width: 100%;
                    margin: 30px 0;

                    .viewcart {
                        display: block;
                        color: $c-text-light;
                        text-align: center;
                        text-decoration: underline;

                        &:hover {
                            color: $c-text-dark
                        }
                    }
                }

                .action.primary {
                    line-height: 1.2;
                    font-size: 13px;
                    text-transform: uppercase;
                    color: $c-white;
                    background-color: $c-primary;
                    border: none;


                    &:hover {
                        background-color: $c-primary-darker;
                    }
                }
            }
        }

        @media screen and (max-width: $screen--l) {
            margin-top: 0;
            margin-left: 0;
        }
    }

    .navigation-mobile {

        &-menu,
        &-close {
            display: none;
        }

        @media screen and (max-width: $screen--l) {

            &-menu {
                display: block;
                order: -1;
            }
        }
    }

    .navigation-main-wrapper {
        height: 100%;
        flex-grow: 1;

        @media screen and (max-width: $screen--l) {
            position: absolute;
            top: 0;
            left: 0;
            width: 1000vw;
            height: 100vh;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-grow: initial;
            overflow-y: scroll;
            overflow-x: hidden;
            z-index: -9999;
            display: block;
            background-color: transparent;
            transition: transform .24s ease-in;
            transition-delay: .24s;
            pointer-events: none;

            .navigation-mobile-open & {
                z-index: 9999;
                background-color: rgba(28, 32, 40, 0.8);
            }

            .navigation-mobile-close {
                display: none;

                svg {
                    width: 25px;
                    height: 25px;

                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }

    .navigation {
        position: static;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: stretch;
        background-color: transparent;
        transition: background-color .24s ease-in;
        transition-delay: .24s;

        > ul {
            position: static;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            > li.level0,
            > li.navigation-static-link {
                position: static;
                margin-bottom: 0;
                height: 100%;
                display: flex;
                align-items: stretch;
                padding-right: 8px;
                padding-left: 8px;
                margin: 0;
                border-top: 0;


                > a {
                    display: flex;
                    align-items: center;
                    color: $c_black;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: .88px;
                    line-height: 1.14;
                    padding: 0 12px;
                    @media screen and (max-width: 1175px) {
                        padding: 0 0.45vw;
                    }

                    > span {
                        display: block;
                        margin-left: 0 !important;
                    }

                    &:hover {
                        color: $c_black;
                    }

                    &.level-top {
                        border: none !important;
                    }
                }

                &--highlight {

                    > a {
                        color: $c-primary;

                        &:hover {
                            color: $c_primary;
                        }
                    }
                }

                &--secondcycle {
                    > a {
                        color: #9ec55c;

                        &:hover {
                            color: #9ec55c;
                        }
                    }

                    .category-secondcycle {
                        display: none;

                        ul {
                            li {
                                text-align: center;
                                margin-bottom: 10px;

                                @media screen and (max-width: $screen--l) {
                                    text-align: left;
                                }

                                a {
                                    color: $c-black;
                                    padding: 0;
                                    font-weight: 400;
                                    white-space: nowrap;

                                    &:hover {
                                        @media screen and (min-width: $screen--s) {
                                            text-decoration: underline;
                                            text-underline-offset: 0.18rem;
                                        }
                                    }
                                }
                            }
                        }

                        img {
                            width: 200px;
                            height: auto;

                            @media screen and (max-width: $screen--l) {
                                display: none;
                            }
                        }
                    }

                    &:hover {
                        position: relative;

                        > a:after {
                            background-color: #9ec55c !important;
                        }

                        .category-secondcycle {
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            background-color: $c-white;
                            transform: translateX(-50%);
                            justify-content: center;
                            z-index: 10;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            gap: 40px;
                            width: 450px;
                            padding: 40px 45px;

                            @media screen and (max-width: $screen--l) {
                                height: 0;
                                overflow: hidden;
                                position: static;
                                transform: none;
                                padding: 0 0 0 40px;
                                align-items: flex-start;

                                li {

                                    &.navigation-static-link--topspace {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                > ul {
                    display: none;
                }

                ul {
                    box-shadow: none;
                    border: none;
                }

                &.has-active,
                &.active,
                &:hover {

                    > a {
                        height: 100%;
                        position: relative;
                        border: none !important;

                        &:after {
                            position: absolute;
                            display: block;
                            content: '';
                            height: 4px;
                            bottom: 0;
                            left: 8px;
                            right: 8px;
                            background-color: $c-primary;
                        }
                    }
                }

                &:hover,
                &.navigation-mobile-touch {

                    > ul {
                        background-color: $c-white;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        width: 100%;
                        transform: translateX(-50%);
                        display: flex;
                        justify-content: center;
                        padding-top: 40px;
                        padding-bottom: 40px;
                        z-index: 10;

                        > li {
                            margin-bottom: 0;
                        }

                        li {

                            a {
                                color: $c-black;
                                padding: 0;
                                font-weight: 400;

                                &:hover {
                                    background-color: transparent;
                                    color: $c-text-dark;

                                    @media screen and (min-width: $screen--s) {
                                        text-decoration: underline;
                                        text-underline-offset: 0.18rem;
                                    }
                                }
                            }
                        }

                        &:before,
                        &:after {
                            display: block;
                            content: '';
                            top: 0;
                            position: absolute;
                            width: 100vw;
                            height: 100%;
                            background-color: $c-white;
                        }

                        &:before {
                            left: 100%;
                        }

                        &:after {
                            right: 100%;
                        }
                    }

                    li.level1 {
                        min-width: 172px;

                        > a {
                            margin-bottom: 18px;
                            text-transform: uppercase;
                            font-weight: 700;
                            font-size: 13px;
                        }
                    }

                    ul.level1 {
                        position: static;
                        display: block;
                        min-width: 0;
                    }

                    li.level2 {
                        margin-bottom: 10px;

                        > a {
                            margin-bottom: 12px;
                            font-size: 14px;
                        }
                    }

                    ul.level2 {
                        display: none;
                    }

                    ul.level0.category-velo {

                    }

                    ul.level0.category-accessoires {
                        max-width: 1100px;

                        .category-accessoires__list {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;

                            > li {
                                min-width: 166px;
                                margin-bottom: 0;

                                li {
                                    margin-bottom: 6px;

                                    > a {
                                        margin-bottom: 10px;
                                        text-transform: none;
                                        font-weight: 400;
                                        font-size: 14px;
                                    }

                                    &.view-all {
                                        margin-top: 30px;

                                        a {
                                            color: $c-primary;
                                            text-decoration: underline;

                                            &:hover {
                                                color: $c-primary-darker;
                                            }
                                        }
                                    }
                                }
                            }

                            @media screen and (max-width: $screen--l) {
                                margin-top: 0;
                                flex-direction: column;
                                justify-content: flex-start;
                                padding-left: 0;

                                > li {

                                    > p {
                                        display: none;
                                    }

                                    > ul {
                                        padding-left: 0;
                                    }

                                    li {
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }
                    }

                    ul.level0.category-composantes {
                        max-width: 1100px;

                        .category-composantes__list {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;

                            > li {
                                min-width: 166px;
                                margin-bottom: 0;

                                > p {
                                    display: none;
                                }

                                li {
                                    margin-bottom: 6px;

                                    > a {
                                        margin-bottom: 10px;
                                        text-transform: none;
                                        font-weight: 400;
                                        font-size: 14px;
                                    }

                                    &.view-all {
                                        margin-top: 30px;

                                        a {
                                            color: $c-primary;
                                            text-decoration: underline;

                                            &:hover {
                                                color: $c-primary-darker;
                                            }
                                        }
                                    }
                                }
                            }

                            @media screen and (max-width: $screen--l) {
                                flex-direction: column;
                                justify-content: flex-start;
                                padding-left: 0;
                                margin-top: 0;

                                > li {

                                    > ul {
                                        padding-left: 0;
                                    }

                                    li {
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }
                    }

                    ul.level0.category-marques {
                        display: flex;
                        flex-direction: column;
                        max-width: 980px;

                        .title-mobile {
                            display: none;

                            @media screen and (max-width: $screen--l) {
                                display: block;
                                text-transform: uppercase;

                            }
                        }

                        > li {

                            > p {
                                display: none;
                            }
                        }

                        .category-marques__logo {
                            display: flex;
                            justify-content: space-between;

                            li {

                                > a {
                                    display: block;

                                    svg,
                                    img {
                                        max-width: 112px;
                                    }
                                }
                            }

                            span {
                                display: none;
                            }

                            @media screen and (max-width: $screen--l) {
                                flex-direction: column;
                                justify-content: flex-start;
                                padding-left: 20px;

                                li {
                                    margin-bottom: 10px;

                                    > a {
                                        text-transform: none;
                                        font-weight: 400;
                                        font-size: 14px;
                                    }
                                }

                                svg,
                                img {
                                    display: none;
                                }

                                span {
                                    display: block;
                                }
                            }
                        }

                        .category-marques__list {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;

                            > li {
                                min-width: 132px;
                                margin-bottom: 0;

                                li {
                                    margin-bottom: 6px;

                                    > a {
                                        font-size: 14px;
                                    }

                                    &.view-all {
                                        margin-top: 30px;

                                        a {
                                            color: $c-primary;
                                            text-decoration: underline;

                                            &:hover {
                                                color: $c-primary-darker;
                                            }
                                        }
                                    }
                                }
                            }

                            @media screen and (max-width: $screen--l) {
                                flex-direction: column;
                                justify-content: flex-start;
                                padding-left: 0;

                                > li {

                                    > ul {
                                        padding-left: 20px;
                                    }

                                    li {
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }

                        @media screen and (max-width: $screen--l) {
                            padding-left: 20px;

                            > li {
                                padding-left: 20px;
                                margin-bottom: 10px;

                                > p {
                                    display: block;
                                    text-transform: uppercase;
                                    font-weight: 700;
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    ul.level0.category-services {
                        display: flex;
                        flex-wrap: wrap;
                        padding-top: 66px;
                        max-width: 980px;

                        > li {
                            width: 25%;
                            margin-bottom: 56px;

                            a {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .category-services__img {
                                    width: 60px;
                                    height: 60px;
                                    background-repeat: no-repeat;
                                    background-size: 60px 120px;
                                    background-position: bottom;
                                    margin-bottom: 14px;

                                    &:hover {
                                        background-position: top;
                                    }
                                }

                                span {
                                    max-width: 140px;
                                    height: 32px;
                                    text-transform: uppercase;
                                    font-size: 13px;
                                    font-weight: 700;
                                    letter-spacing: .93px;
                                    text-align: center;
                                }
                            }
                        }

                        @media screen and (max-width: $screen--l) {
                            padding-top: 0;
                            padding-left: 40px;

                            > li {
                                width: 100%;
                                margin-bottom: 10px;

                                a {
                                    align-items: flex-start;

                                    .category-services__img {
                                        display: none;
                                    }

                                    span {
                                        text-transform: none;
                                        font-weight: 400;
                                        height: auto;
                                        max-width: none;
                                    }
                                }
                            }
                        }
                    }

                    &.navigation-static-link--soldes {
                        position: relative;
                    }

                    ul.category-soldes {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: auto;
                        padding: 40px 45px;

                        &:before,
                        &:after {
                            content: none;
                        }

                        li {
                            text-align: center;
                            margin-bottom: 10px;

                            a {
                                white-space: nowrap;

                                &:hover {
                                    color: $c-text-dark;
                                }
                            }

                            &.navigation-static-link--topspace {
                                margin-top: 10px;
                            }

                            &.navigation-static-link--highlight {

                                a {
                                    color: $c-primary;

                                    &:hover {
                                        color: $c-primary-darker;
                                    }
                                }
                            }
                        }

                        @media screen and (max-width: $screen--l) {
                            height: 0;
                            overflow: hidden;
                            position: static;
                            transform: none;
                            padding: 0 0 0 40px;
                            align-items: flex-start;

                            li {

                                &.navigation-static-link--topspace {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }

                &.navigation-mobile-touch {

                    > ul {
                        padding-top: 8px !important;
                        height: auto !important;
                    }

                    .category-secondcycle {
                        display: block;
                        height: auto;
                        padding: 0 0 0 40px;
                    }
                }
            }
        }

        .level0.level-top:not(.parent) {
            display: none;
        }

        .level0 .submenu .active > a {
            padding-left: 5px;
        }

    @media screen and (max-width: $screen--l) {
        z-index: 10;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #fff;
        width: 320px;
        height: 100vh;
        margin: 0;
        //overflow-y: scroll;
        padding-bottom: 80px;
        left: -320px;
        transition: left .24s ease-in;
        pointer-events: all;
        overflow: scroll;

        .block-search,
        .navigation-main,
        .header.links,
        .panel.header {
            width: 100%;
        }

        .block-search {
            display: block;
            order: initial;
            background-color: #fff;
            padding-left: 0;
            margin-right: 0;
            z-index: 9999;

            .minisearch {
                position: relative;

                .actions {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 20px;
                    height: 18px;
                    width: 18px;
                    pointer-events: none;

                    .open-icon {
                        width: 18px;
                        height: 18px;

                        path {
                            fill: rgba(140, 150, 169, .6);
                        }
                    }
                }
            }

            .control-field {
                position: static;
                display: flex !important;
                align-items: center;
                padding: 0 40px;
                height: 60px;

                input {
                    position: static;
                    text-align: left;
                    padding: 0;
                    font-size: 14px;
                    font-size: max(14px, 16px); // fix to prevent zooming in mobile safari
                    line-height: 32px;
                    height: 32px;
                    left: auto;
                    margin: 0 0 0 10px;
                }

                input::-webkit-input-placeholder {
                    font-size: max(14px, 16px);
                    line-height: 32px;
                    height: 32px;
                }
            }
        }

        > ul {
            display: inline-block;
            width: 100%;
            height: auto;
            background-color: #fff;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 15px;

            li.level0,
            li.navigation-static-link {
                width: 100%;
                margin-right: 0;
                flex-direction: column;
                align-items: flex-start;
                height: auto;

                &.has-active,
                &:hover {

                    > .level-top {
                        border: none !important;
                        height: auto;
                    }

                    > ul {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }

                > a {
                    padding: 17px 60px 17px 13px;
                    display: block;
                    width: 100%;
                    position: relative;

                    &:before {
                        position: absolute;
                        display: block;
                        content: '';
                        top: 50%;
                        transform: translateY(-50%);
                        right: 10px;
                        background-image: url('../images/icons/link-open.svg');
                        width: 20px;
                        height: 20px;
                    }

                    &:after {
                        content: none !important;
                    }
                }
                &.no-child {
                    > a {
                        &:before {
                            display: none;
                        }
                    }
                }

                ul.level0 {
                    height: 0;
                    position: static;
                    display: flex !important;
                    flex-direction: column;
                    transform: none;
                    overflow: hidden !important;
                    padding-left: 40px;

                    li.level1 {
                        width: 100%;

                        a {
                            margin-bottom: 14px;
                        }
                    }

                    &:before,
                    &:after {
                        content: none;
                    }

                    ul.level1 {
                        padding-top: 6px;
                        padding-left: 20px;
                        padding-bottom: 10px;
                    }
                }

                &.navigation-mobile-touch {

                    > a {

                        &:before {
                            background-image: url('../images/icons/link-close.svg');
                        }
                    }

                    > ul {
                        height: auto;
                        padding-top: 6px;
                    }
                }
            }
        }

        .header.misc {
            display: block;
        }

        > .header.links > .authorization-link {
            display: none;


            @media screen and (max-width: $screen--l) {
                display: block;
                position: relative;

                &.logged-in {
                    display: none;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 1px;
                    width: 100px;
                    background-color: #e9e9eb;
                }
            }
        }

        .header.links {
            padding-left: 20px;
            display: flex;
            flex-direction: column;

            .authorization-link {
                a {
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 25px 0;
                    color: $c-black;
                }

                svg {
                    display: none;
                }
            }

            .customer-menu {

                .header.links {
                    padding-left: 0;
                    padding-top: 0;
                    padding-bottom: 0;

                    li {

                        a {
                            color: #000;
                            text-transform: none;
                            font-weight: 400;
                            padding-left: 10px;
                        }

                        &:not(.greet) {
                            padding-left: 10px;
                            margin-bottom: 8px;
                        }

                        &.authorization-link {
                            padding-left: 10px;
                            margin-bottom: 30px;

                            a {
                                padding: 0 0 0 10px;
                            }
                        }
                    }

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 1px;
                        width: 100px;
                        background-color: #e9e9eb;
                    }

                    .wishlist {
                        display: none;
                    }
                }


                .greet.welcome {
                    order: -1;
                    text-transform: uppercase;
                    margin-bottom: 18px;
                    padding-top: 30px;
                    position: relative;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0.88px;
                    line-height: 16px;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 1px;
                        width: 100px;
                        background-color: $c_text-lighter;
                    }
                }

                .action.close {
                    display: none;
                }
            }
        }

        .panel.header {
            position: relative;
            display: inline-block;
            background-color: #fff;
            align-items: flex-start;
            margin: 0;
            padding-top: 30px;
            padding-left: 20px;

            ul {
                display: flex;
                flex-direction: column;
                margin-left: 0 !important;
                margin-bottom: 20px;

                li {

                    a {
                        padding: 0;
                        font-weight: 400;
                        color: #000;
                        font-size: 14px;

                        &:before {
                            display: none;
                        }
                    }
                }

                li + li {
                    margin-left: 0;
                    margin-top: 20px;
                }
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 20px;
                height: 1px;
                width: 100px;
                background-color: $c_text-lighter;
            }
        }
    }
}


.header.misc {

    .header.links {
        position: relative;
        list-style-type: none;
        padding: 0;
        margin: 0;

        > .authorization-link {
            margin-bottom: 0;

            > a {

                span {
                    display: none;
                }

                &[href*="logout"] {
                    pointer-events: none;
                }
            }
        }

        .customer-welcome {
            position: absolute;
            top: 54px;
            left: 50%;
            z-index: 10;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
            background-color: $c-white;
            opacity: 0;
            transform: scaleY(0);

            &:after {
                position: absolute;
                content: '';
                display: block;
                height: 3px;
                width: 34px;
                background-color: $c-primary;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }

            .customer-menu {
                width: 100%;
            }

            .greet.welcome {
                display: none;
            }

            .header.links {
                margin-left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 38px;
                min-width: 220px;
                position: relative;

                li {
                    margin-bottom: 8px;
                }

                a {
                    color: $c-text-dark;
                    font-size: 14px;

                    &:hover {
                        color: $c-text-light;
                    }
                }

                .authorization-link {

                    > a {

                        span {
                            display: flex;
                        }

                        svg {
                            display: none;
                        }

                        &[href*="logout"] {
                            pointer-events: all;
                        }
                    }
                }

                .action.close {
                    display: none;
                }
            }
        }

        &.active {

            .customer-welcome {
                opacity: 1;
                transform: translateX(-50%);
            }
        }

        body.customer-opened & {

            .customer-welcome {
                display: flex;
            }
        }
    }
}

.minicart-items-wrapper {
    background-color: $c-bg-grey;
    margin-bottom: 20px;
    padding: 20px;
    border: none;
    max-height: 394px;
    height: 100% !important;
    overflow-y: scroll;
    order: 1;

    .minicart-items {
        background-color: $c-bg-grey;
    }

    .item.product {
        border: none;

        .product-item-details {

            .product-item-name {
                margin-bottom: 6px;

                a {
                    font-size: 14px;
                    text-decoration: underline;
                    text-transform: uppercase;
                    color: $c-black;

                    &:hover {
                        color: $c-black;
                    }
                }
            }

            .product.options {

                span.toggle {
                    display: none;
                }

                div.content {
                    display: block !important;
                }
            }

            .product.options.list {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 8px;

                .label,
                .values {
                    color: $c-text-light;
                    font-size: 14px;
                    line-height: 1.14;
                    letter-spacing: .5px;
                    margin-bottom: 0;
                    font-weight: 400;
                }

                .label {

                    &:after {
                        content: ': ';
                    }
                }

                .values {
                    flex-grow: 1;
                    width: 70%;
                    text-transform: lowercase;
                }
            }

            .product-item-pricing {

                .price-excluding-tax {
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 12px;
                }

                .details-qty {

                    .label {
                        display: none;
                    }

                    .update-cart-item {
                        padding: 0;
                        margin-top: 4px;
                        font-size: 12px;
                        text-decoration: underline;
                        color: $c-text-light;
                        border: none;
                        background-color: transparent;
                    }

                    input {
                        border-width: 1px;
                        border-radius: 2px;
                        font-size: 14px;
                    }
                }
            }

            .product.actions {
                margin-top: -32px;
                padding-right: 0;

                .primary {
                    display: none;
                }

                .secondary {

                    a {

                        &:before {
                            content: '';
                            background-image: url('../images/icons/close.svg');
                            width: 16px;
                            height: 16px;
                            background-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $screen--l) {
    justify-content: space-between;
    height: 60px;
}
}

.amsearchClose {
    display: none;
}

@media screen and (max-width: $screen--l) {

    body.search-active {
        .header.content .navigation-main-wrapper .navigation-mobile-close {
            display: none;
        }

        .header.content .navigation {
            width: 100%;
            overflow: hidden;
            z-index: -1;
            overflow: unset;
        }
        .header.content .navigation .block-search .control-field {
            position: relative;
            width: 100vw;
            padding: 0;

            .input-text {
                box-sizing: border-box;
                background: #fff;
                border-radius: 5px 0 0 5px;
                padding-left: 40px;
                margin-right: 30px;
            }

            .amsearchClose {
                font-weight: bold;
                font-size: 14px;
                line-height: 17px;
                text-align: right;
                color: #DF5D24;
                padding: 0 15px;
                display: block;
                border-radius: 0 5px 5px 0;
            }
        }

        .amsearch-close {
            left: calc(100vw - 110px);
            right: auto;
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23BCC0CB'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7511 11.751C12.0229 11.4791 12.0229 11.0384 11.7511 10.7666L8.96192 7.97741L11.751 5.18836C12.0228 4.91652 12.0228 4.47579 11.751 4.20395C11.4791 3.93212 11.0384 3.93212 10.7666 4.20395L7.97752 6.99301L5.18839 4.20388C4.91655 3.93204 4.47582 3.93204 4.20398 4.20388C3.93215 4.47571 3.93215 4.91645 4.20398 5.18828L6.99311 7.97741L4.20388 10.7666C3.93204 11.0385 3.93204 11.4792 4.20388 11.7511C4.47571 12.0229 4.91645 12.0229 5.18828 11.7511L7.97752 8.96182L10.7667 11.751C11.0385 12.0228 11.4792 12.0228 11.7511 11.751Z' fill='white'/%3E%3C/svg%3E%0A");
            width: 16px;
            height: 16px;
            display: block !important;
            filter: unset;
        }

        .header.content .navigation .block-search .minisearch .actions {
            left: 20px;
        }

        .amsearch-form-container {
            position: fixed !important;
        }

        .header.content .block-search .amsearch-clone-position {
            left: 0;
            right: 0;
            transform: unset;
            width: 100vw !important;
            margin: 0;
            max-height: 100vh;
            height: calc(100vh - 60px);
            display: block !important;

            .amsearch-leftside {
                width: 100% !important;
                min-width: 30%;
            }
            #amasty-shopby-product-list {
                .amasty-xsearch-block-header {
                    margin-top: 0;
                }

                .amsearch-link {
                    padding-left: 0;
                }

                .product-items {
                    .product-item {
                        width: calc(50% - 8px);
                        margin: 8px 8px 8px 0;
                        padding: 5px;

                        &:nth-child(2n) {
                            margin-right: 0;
                        }

                        .product-item-details {
                            padding: 8px;
                            min-height: 80px;

                            .name-price-wrapper {
                                flex-direction: column-reverse;

                                .product-item-link {
                                    padding: 0;
                                }

                                .product-item-price {
                                    text-align: left;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        &-photo {
                            padding-bottom: 0;
                        }

                        &-description {
                            display: none;
                        }
                    }
                }
            }
        }

        .header.content .block-search {
            .search-autocomplete .amasty-xsearch-block-header {
                letter-spacing: 0.075em;
                margin: 31px 0 23px 0;
                padding: 0;
            }

            .popular_searches .amsearch-item {
                display: block;
                padding: 0;
            }

            .amsearch-clone-position .category .item-name,
            .amsearch-clone-position .popular_searches .amsearch-item .item-name {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.015em;
                color: #1D2027;
                padding: 8px 0;
                font-weight: normal;
                display: block;
            }

            .amsearch-clone-position .category .item-name span,
            .amsearch-clone-position .popular_searches .amsearch-item .item-name span {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.015em;
                padding: 8px 0;
            }

            .amsearch-clone-position .category {
                .amasty-xsearch-block-header {
                    margin-top: 0;
                }
            }
        }
    }
}

.navigation-mobile-open .header.content .navigation-main-wrapper .navigation-mobile-close {
    position: fixed;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    z-index: 9999;
    pointer-events: all;
    background: #df5d24;
    display: flex;
    justify-content: center;
    align-items: center;
}

.promo-container {
    [name="shippingAddress.postcode"] {
        &.hide-error {
            [name="postcode"] {
                border-color: #e8e8e8;
            }
            .field-error {
                display: none;
            }
        }
    }
}
