// Mixins
@import "mixins/media-queries";

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI

// Theme variables
@import 'variables';

// Reset default styles with magento-reset
@import 'blocks/reset';

// Theme blocks
@import 'blocks/actions-toolbar';
@import 'blocks/breadcrumbs';
@import 'blocks/buttons';
@import 'blocks/extends';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/layout';
@import 'blocks/loaders';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/pages'; // Theme pager
@import 'blocks/popups';
@import 'blocks/price';
@import 'blocks/sections';
@import 'blocks/tables';
@import 'blocks/tooltips';
@import 'blocks/typography';

// Components styles (modal/sliding panel)
@import 'blocks/components/modals'; // from lib
@import 'blocks/components/modals_extend'; // local

// CUSTOM PORTED FILES
@import "ported/css/bootstrap.min";
@import "ported/style";
@import "../Wicrew_Booking/styles/service";

// Twodev styles
@import '../Twodev_CustomStyles/styles/var';
@import '../Twodev_CustomStyles/styles/globals';
@import '../Twodev_CustomStyles/styles/forms';

// Libraries
@import 'vendor/chocolat'; // Owl carousel
@import 'vendor/owl.carousel.min'; // Owl carousel
@import 'vendor/owl.theme.default.min'; // Owl theme default
@import 'vendor/owl.theme.green.min'; // Owl theme green

// Modules
@import '../Magento_AdvancedCheckout/styles/module';
@import '../Magento_Braintree/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_CatalogEvent/styles/module';
@import '../Magento_CatalogSearch/styles/module';
@import '../Magento_Checkout/styles/module';
@import '../Magento_Contact/styles/module';
@import '../Magento_Customer/styles/module';
@import '../Magento_Customer/styles/extend';
@import '../Magento_Downloadable/styles/module';
@import '../Magento_GiftCard/styles/module';
@import '../Magento_GiftCardAccount/styles/module';
@import '../Magento_GiftMessage/styles/module';
@import '../Magento_GiftRegistry/styles/module';
@import '../Magento_GiftWrapping/styles/module';
@import '../Magento_GroupedProduct/styles/module';
@import '../Magento_Invitation/styles/module';
@import '../../quilicot/Magento_LayeredNavigation/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../Magento_MultipleWishlist/styles/module';
@import '../Magento_Multishipping/styles/module';
@import '../Magento_Newsletter/styles/module';
@import '../Magento_Paypal/styles/module';
@import '../Magento_ProductVideo/styles/module';
@import '../Magento_Review/styles/module';
@import '../Magento_Reward/styles/module';
@import '../Magento_Rma/styles/module';
@import '../Magento_Sales/styles/module';
@import '../StripeIntegration_Payments/styles/module';
@import '../Magento_SalesRule/styles/module';
@import '../Magento_SendFriend/styles/module';
@import '../Magento_Swatches/styles/swatches';
@import '../Magento_Theme/styles/module';
@import '../Magento_Theme/styles/header';
@import '../Magento_Theme/styles/gallery';
@import '../Magento_Theme/styles/footer';
@import '../Magento_Vault/styles/module';
@import '../Magento_Weee/styles/module';
@import '../Magento_Wishlist/styles/module';
@import '../Magento_Catalog/styles/paybright-popup';
@import '../Mageplaza_SocialLogin/styles/module';
@import '../Magento_ProductAlert/styles/module';
@import '../Magezon_PageBuilder/styles/module';
@import '../Amasty_Shopby/styles/style';

// Widgets
@import '../Magento_AdvancedCheckout/styles/widgets';
@import '../Magento_Banner/styles/widgets';
@import '../Magento_Catalog/styles/widgets';
@import '../Magento_CatalogEvent/styles/widgets';
@import '../Magento_Cms/styles/widgets';
@import '../Magento_MultipleWishlist/styles/widgets';
@import '../Magento_VersionsCms/styles/widgets';

// TWODEV Styles
@import '../Twodev_CustomStyles/styles/forms';
@import '../Twodev_CustomStyles/styles/globals';
@import '../Magento_Cms/styles/home';
@import '../Magento_Cms/styles/brands';
@import '../Magento_Cms/styles/pages';
@import '../Magefan_Blog/styles/recent';
@import '../Aheadworks_Giftcard/styles/style';
@import '../Twodev_MagefanBlogCustomization/web/styles/_module';

@import '../Quilicot_AmastyShobByMod/styles/main';

.modal-custom-overlay {
    background-color: rgba(0, 0, 0, 0.7);
}
