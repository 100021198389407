//
//  Shopping cart
//  ---------------------------------------------

//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    @include lib-css(background, $sidebar__background-color);
    margin-bottom: $indent__m;
    padding: 1px 15px $indent__m;

    > .title {
        display: block;
        @include lib-heading(h3);
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 $indent__m $indent__xs;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }    
    }

    .field[name="shippingAddress.region"] {
        display: none !important;
    }
    
    .actions-toolbar {
        > .primary {
            button {
                @extend .abs-revert-secondary-color;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    border-top: 1px solid $border-color__base;
    padding-top: $indent__s;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

//  Products table
.cart.table-wrapper {
    .items {
        thead + .item {
            border-top: $border-width__base solid $border-color__base;
        }

        > .item {
            border-bottom: $border-width__base solid $border-color__base;
            position: relative;
        }
    }

    .col {
        padding-top: $indent__base;

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        > .action {
            @extend button;
            @include lib-link-as-button();
            margin-bottom: $indent__s;
            margin-right: $indent__s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__regular;
            margin: 0;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 15px;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-cart-index {
        .page-main .column.main {
            padding: 0 20px;
        }
    }

    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &:before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }

                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }
        }
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-cart-index {
        .page-main .column.main {
            padding: 0 20px;
        }
    }

    .cart-container {
        @extend .abs-add-clearfix-desktop;
        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 23%;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                .col {
                    &.item {
                        padding: 27px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }

            .item-actions {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                    text-align: left;
                }
            }
        }

        .action {
            margin-bottom: $indent__s;
        }
    }

    //  Products pager
    .cart-products-toolbar {
        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart.table-wrapper {
        .cart-products-toolbar {
            + .cart {
                thead {
                    tr {
                        th.col {
                            padding-bottom: 7px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .cart {
            + .cart-products-toolbar {
                margin-top: $indent__m;
            }
        }
    }
}

// ---------------------------------------------
// QUILICOT START
// ---------------------------------------------

.cart-empty {
    min-height: 400px;
    padding-top: 80px;

    &-content {
        margin-top: 30px;
        padding: 30px 0;
        border-top: 1px solid $c_border-grey;
        border-bottom: 1px solid $c_border-grey;

        p {
            margin-bottom: 40px;
            font-size: 1.6rem;
        }

        a.q_b-primary,
        a.q_b-primary--border {
            padding-top: 17px !important;
            padding-bottom: 17px !important;
            width: 280px;
            text-align: center;
            margin-right: 20px;
        }
    }
}

.checkout-cart-index {
    background-color: $c_bg-grey;
    color: $c_text-dark;

    .page-main {
        padding: 0 30px;
    }

    #block-discount .content {
        display: block !important;
    }

    // Temporary adjustment
    .navigation.ms-megamenu .ms-topmenu .ms-label {
        font-size: 1.8rem;
    }
}

.cart-container {
    padding-top: 80px;
    padding-bottom: 200px;

    .form-cart {
        width: 70%;
        padding-right: 80px;
    }

    .message.success,
    .message.warning,
    .message.error {
        background: transparent;
        color: $c_text-light;
        border-top: 1px solid $c_border-grey;
        padding-top: 20px;
        padding-left: 0;

        > *:first-child:before {
            content: none !important;
        }
    }
}

.cart-title {
    margin-bottom: 30px;

    h1 {
        color: $c_text-dark;
        text-transform: uppercase;
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 5px;
        letter-spacing: 1px;
    }

    p {
        color: $c_text-light;
    }
}

.cart.table-wrapper {

    .table-caption {
        display: none;
    }

    .item.cart {
        border-color: $c_border-grey;

        &:first-of-type {
            border-top: 1px solid $c_border-grey;
        }

        .product-item-details {
            max-width: 250px;

            .product-item-name {

                a {
                    color: $c_text-medium;
                    font-weight: bold;

                    &:hover {
                        color: $c_text-dark;
                    }
                }
            }

            .price {
                color: $c-text-medium;
                font-size: 1.4rem;
            }
        }

        .item-actions {
            margin-top: 20px;

            a {
                display: block;
                color: $c_text-light;
                text-decoration: underline;
                text-underline-offset: 0.18rem;
                margin-bottom: 5px;
            }
        }

        .item-special-mention {
            margin: 15px 0;

            p {
                font-weight: bold;
                color: $c-primary;
            }
        }

        .col {
            padding: 30px 10px;

            &.item {
                padding-top: 30px;
            }

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }

        .item-options {
            color: $c_text-light;
            font-size: 1.4rem;

            &--mobile {
                display: none;
            }

            p {
                margin-bottom: 0;
            }
        }

        .col.qty {

            .action.update {
                opacity: 0;
                padding: 0;
                margin: 5px 0 0 0;
                text-decoration: underline;
                width: 100%;
                transition: opacity 0.24s ease;

                &:before {
                    content: none;
                }
            }
        }

        .control.qty {

            input {
                display: block;
                padding-top: 8px;
                padding-bottom: 8px;
                margin: 0 auto;
            }
        }

        .col.subtotal {

            .price {
                display: block;
                padding: 10px 0;
                font-size: 1.4rem;
                color: $c_text-medium;
            }
        }

        .product-cart-price {
            color: $c-text-medium;
            font-size: 1.4rem;
            padding: 11px 0;
            display: flex;
            align-items: baseline;

            .mobile-qty {
                display: none;
                color: $c-text-medium;
                font-weight: bold;
            }
        }
    }
}

.column.main .cart-summary {
    background-color: transparent;
    width: 30%;
    padding: 0;

    .summary.title {
        color: $c_text-dark;
        font-size: 2.4rem;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .cart-totals {
        border-top: 0;

        .shipping.totals {

            .value {
                display: block;
                color: $c_text-light;
            }
        }

        .coupon.totals {

            .amount {
                font-weight: bold;
                color: $c_primary;
            }

            .coupon {
                display: block;
                color: $c_text-light;
            }
        }

        .grand.totals {
            font-size: 1.6rem;

            .mark strong {
                font-weight: bold;
            }
        }
    }

    button.checkout {
        width: 100%;
    }

    .block .fieldset {
        margin-left: 0;
    }

    .block.shipping {
        margin-top: 50px;
        padding-top: 20px;
        border-top: 1px solid $c_border-grey;

        > .title {
            padding: 0;
            border-top: 0;

            &:after {
                content: none;
            }

            strong {
                font-size: 1.4rem;
                color: $c_text-medium;
                text-decoration: underline;
            }
        }
    }
}

.cart-bottom {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .block.discount {
        width: 100%;
        max-width: 415px;
        margin-bottom: 0;

        .title strong {
            font-size: 1.2rem !important;
            text-transform: uppercase;
        }

        .fieldset.coupon {
            display: flex;

            .field {
                margin-bottom: 0;
                width: 100%;
            }

            .control {
                width: 100%;
                height: 100%;
                float: none;

                input {
                    height: 100%;
                }
            }

            .actions-toolbar {
                margin-left: 15px;
                margin-top: 0;
                white-space: nowrap;
            }
        }

    }
}

#shipping-zip-form {
    margin-top: 30px;
    @extend .abs-add-clearfix;

    .fieldset.estimate {
        margin-bottom: 0;
    }

    .single-country {
        @extend .abs-visually-hidden;
    }

    .field.question {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 30px ;
    }

    [name="shippingAddress.region_id"] {
        width: 50%;
        float: left;
    }

    [name="shippingAddress.postcode"] {
        width: calc(50% - 10px);
        margin-left: 10px;
        float: left;
    }
}

#co-shipping-method-form {

    .price {
        font-weight: bold;
    }
}

@media screen and (max-width: $screen__l) {

    .cart-container {
        display: flex;
        flex-direction: column;

        .form-cart {
            width: 100%;
            padding-right: 0;
            float: none;
            order: 1;
        }
    }

    .column.main .cart-summary {
        margin-top: 50px;
        width: 100%;
        float: none;
        order: 2;
        padding: 0;
        max-width: 410px;
    }
}

@media screen and (max-width: $screen__m) {

    .cart-empty {
        min-height: 0;
        padding-top: 50px;

        &-content {

            .actions {
                display: flex;
                flex-direction: column;
            }

            a.q_b-primary,
            a.q_b-primary--border {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }

    .cart-container {
        padding-top: 50px;
    }

    .column.main .cart-summary {
        max-width: none;
        justify-content: space-between;
    }

    .cart-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            margin-top: 0;
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }
    }

    .cart.table-wrapper {

        .product-item-photo {
            position: static;
            max-width: none;
        }

        .item.cart {

            .col {

                &.qty,
                &.options,
                &.subtotal {
                    display: none !important;
                }

                &.item {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .item-options {
                margin: 20px 0;

                &--mobile {
                    display: block;
                }
            }

            .product-cart-price {

                .mobile-qty {
                    display: block;
                }
            }
        }
    }
}

@media screen and (max-width: $screen__s) {

    .cart-bottom {
        flex-direction: column-reverse;
        align-items: center;

        .continue {
            color: $c_text-medium;
            text-decoration: underline;
            background-color: transparent;
            border: none;
            padding: 0;
            text-transform: none;
        }

        .block.discount {
            max-width: none;
            margin-top: 50px;
        }
    }
}
