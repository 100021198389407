
$c_white: #fff;
$c_black: #1c2028;

$c_bg-grey: #f9f9fb;
$c_border-grey: #e2e5ec;

$c_text-dark: #1c2028;
$c_text-medium: #434c5e;
$c_text-light: #8c96a9;
$c_text-lighter: #e9e9eb;
$c_Text-grey: #545e6f;

$c-darkblue-bg : $c_text-dark;

$c_primary: #df5d24;
$c_primary-darker: #d15f27;
$c_secondary: #23abff;
$c_secondary-dark: $c_text-medium;

$c_grey: #8c96a9;
$c_grey--60: mix($c_grey, $c_bg-grey, 60%);
$c_grey--40: mix($c_grey, $c_bg-grey, 40%);

$screen--s: 640px;
$screen--m: 768px;
$screen--l: 1024px;
$screen--xl: 1200px;
