body.catalog-product-view {
    display: inherit;

    .paybright-modal-container {
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .6);
        transition: opacity .24s ease-in-out;
        z-index: 9999;

        .paybright-modal {
            position: relative;
            top: 15%;
            width: 100%;
            max-width: 680px;
            background-color: #fff;
            margin: auto;
            padding-bottom: 40px;
            transition: transform .48s ease-in-out;

            @media screen and (max-width: $screen__m) {
                top: 0;
                max-width: 100%;
            }

            .title-content {
                color: #1C2028;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 20px;
                text-align: center;
                text-transform: uppercase;
                margin: 60px 0 30px 0;
            }

            &__header {
                position: relative;
                text-align: center;
                padding: 44px 60px 0 60px;

                @media screen and (max-width: $screen__m) {
                    padding: 44px 30px 0 30px;
                }

                img {
                    max-height: 30px;
                    vertical-align: middle;
                    margin: 0 20px;
                }

                .close {
                    background: #DF5D24;
                    font-size: 22px;
                    width: 42px;
                    height: 42px;
                    line-height: 29px;
                    border-radius: 100%;
                    top: -21px;
                    right: -21px;
                    opacity: 1;
                    cursor: pointer;
                    position: absolute;

                    @media screen and (max-width: $screen__m) {
                        position: fixed;
                        top: 0;
                        right: 0;
                        background: #F9F9FB;
                        border-radius: 0;
                    }

                    svg {
                        width: 42px;
                        height: 42px;
                        fill: $c_white;

                        @media screen and (max-width: $screen__m) {
                            fill: $c_black;
                        }
                    }
                }

                p {
                    padding: 12px 0;

                    span {
                        font-size: 20px;
                        font-weight: bold;
                        display: inline-block;
                        line-height: 30px;
                        vertical-align: bottom;

                        @media screen and (max-width: 420px) {
                            display: block;
                        }
                    }
                }
            }

            &__banner {
                margin-top: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;

                .title {
                    color: $c_black;
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 28px;
                    text-align: center;
                    margin-bottom: 11px;
                }

                .subtitle {
                    color: #545E6F;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 20px;
                    text-align: center;
                }
            }

            &__banner {
                padding: 0 60px;

                @media screen and (max-width: $screen__m) {
                    padding: 0 30px;
                }
            }

            &__features {
                display: flex;
                margin-top: 40px;
                padding: 0 60px;

                @media screen and (max-width: 600px) {
                    padding: 0 30px;
                    flex-direction: column;
                }

                .feature {
                    text-align: center;
                    flex: 1 1 auto;
                    margin: 0 30px 0 0;

                    @media screen and (max-width: 600px) {
                        margin: 0 0 30px 0;
                    }

                    &:last-child {
                        margin: 0 0 0 30px;

                        @media screen and (max-width: $screen__m) {
                            margin: 0;
                        }
                    }

                    img {
                        max-height: 24px;
                    }

                    .title {
                        color: $c_black;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 20px;
                        text-align: center;
                        margin: 16px 0 10px 0;
                    }

                    .description {
                        color: #545E6F;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 20px;
                        text-align: center;
                        margin-bottom: 0;
                        max-width: 96%;
                    }
                }
            }

            &__hows {
                background-color: #F9F9FB;
                padding-bottom: 50px;

                .feature,
                .how {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .title {
                    font-size: 24px;
                }

                .description {
                    color: #909090;
                    text-align: center;
                }
            }


            &__hows {
                .how {
                    padding: 0 60px;

                    @media screen and (max-width: $screen__m) {
                        padding: 0 30px;
                    }
                }

                .number {
                    background-color: $c_primary;
                    display: flex;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    color: #FFFFFF;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0;
                    text-align: center;
                    border-radius: 100%;
                    margin-top: 42px;
                    margin-bottom: 20px;
                }

                .title {
                    color: $c_black;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                    text-align: center;
                    margin-bottom: 10px;
                }

                .description {
                    color: #545E6F;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 20px;
                    text-align: center;
                    margin-bottom: 0;
                }
            }

            &__faqs {
                padding: 0 60px;

                @media screen and (max-width: $screen__m) {
                    padding: 0 30px;
                }

                .faq {
                    border-radius: 3px;
                    background-color: #fff;
                    border: 1px solid rgba(140,150,169,0.6);
                    margin-bottom: 13px;

                    .question {
                        position: relative;
                        padding: 20px 63px 20px 20px;
                        cursor: pointer;
                        box-sizing: border-box;
                        border-radius: 4px;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 20px;
                        text-align: left;
                        margin-bottom: 0;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 16px;
                            top: 50%;
                            transition: transform .24s ease-in-out;
                            transform: translateY(-50%);
                            background-image: url('../Magento_Catalog/images/16_arrow-down_filter.jpg');
                            -webkit-background-size: 16px 16px;
                            background-size: 16px 16px;
                            background-repeat: no-repeat;
                            width: 16px;
                            height: 16px;
                        }

                        &.opened {

                            &:after {
                                transform: translateY(-50%) rotate(180deg);
                            }
                        }
                    }

                    .answer {
                        height: 0;
                        transition: height .24s ease-in-out;
                        overflow: hidden;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 23px;

                        p,
                        ol,
                        ul,
                        li {
                            color: #545E6F;
                        }

                        p {
                            margin: 0;
                            padding: 0 20px 20px 20px;
                        }

                        ul,
                        ol {
                            margin: 0;
                            padding: 8px 24px 12px 42px;
                        }
                    }
                }
            }

            &__cta {
                min-height: 135px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background-size: cover;
                margin: 30px 60px 0 60px;
                color: $c_white;
                background-color: $c_primary;

                @media screen and (max-width: $screen__m) {
                    margin: 30px 30px 0 30px;
                }

                .title {
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                    text-align: center;
                }

                .subtitle {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 22px;
                    text-align: center;
                }

                a {
                    color: $c_white;
                    text-decoration: none;
                    font-weight: bold;
                    display: block;
                }
            }

            &__misc {
                margin: 20px 60px 0 60px;

                @media screen and (max-width: $screen__m) {
                    margin: 20px 30px 0 30px;
                }

                p {
                    color: #8C96A9;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }
        }
    }
}

body.paybright-modal-opened {
    overflow: hidden;

    .paybright-modal-container {
        opacity: 1;
        pointer-events: all;
        overflow-y: scroll;
    }
}
