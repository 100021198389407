.account {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    select,
    textarea {
        border-radius: 4px;
        border-color: $c_border-grey;
        border-width: 1px;
        font-size: 14px;
        padding: 14px;
        height: auto;
        font-family: "Lato", sans-serif;

        &:focus {
            border-color: $c_primary;
        }
    }

    select {
        padding: 16px 40px 15px 14px;
        background-image: url('../images/icons/sort-down.png');
        background-size: 16px 16px;
    }

    input[disabled] + label {
        color: $c-text-light !important;
    }

    input[type="checkbox"],
    input[type="radio"] {
        position: relative;
        margin-right: 5px;
        vertical-align: top;
        width: 20px;
        height: 20px;
        appearance: none;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:before {
            content: "";
            display: block;
            height: 20px;
            width: 20px;
            position: absolute;
            left: 0;
            top: 0;
            border: none;
            background-color: $c-white;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 18px 18px;
        }
    }

    input[type="checkbox"] {

        &:before {
            background-image: url("../images/icons/checkbox.svg");
        }

        &:checked:before,
        &.active:before {
            background-image: url("../images/icons/checkbox-checked.svg");
        }
    }

    input[type="radio"] {
        font-size: 1.2rem;

        &:before {
            left: -1px;
            border-radius: 100%;
            background-image: url("../images/icons/radio.svg");
        }

        &:checked:before,
        &.active:before {
            background-image: url("../images/icons/radio-checked.svg");
            border-color: $c_text-dark;
        }
    }

    .fieldset {
        .field:not(.choice) {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            .label {
                width: auto;
                text-align: left;
                text-transform: uppercase;
                font-size: 12px;
                padding-bottom: 7px !important;

                span {
                    font-weight: bold !important;
                    display: inline-block;
                }
            }

            &.required {
                > label {
                    &:after {
                        padding-left: 5px;
                        font-size: 16px;
                        top: 2px;
                    }
                }
            }

            &.additional {
                .label {
                    padding: 0 !important;
                }
            }
        }
    }

    .field.choice {
        position: relative;
        margin-bottom: 15px;

        &:before {
            content: none;
        }

        > input {
            position: absolute;
            left: 0;
        }

        > label {
            display: inline-block;
            padding-left: 30px !important;
            font-size: 14px;
        }
    }

    .actions-toolbar {
        .secondary {
            display: none;
        }
    }

    .table-order-items {
        .col.actions {
            .action.order {
                display: none;
            }
        }
    }

    .order-products-toolbar {
        .toolbar-number {
            display: none;
        }

        .limiter {
            .limiter-options {
                margin: 0 10px;
            }
        }
    }

    .block-dashboard-info,
    .block-dashboard-addresses,
    .block-dashboard-orders,
    .block-addresses-default,
    .block-addresses-list {

        .block-title {
            > strong {
                font-weight: 500 !important;
            }

            a.action {
                text-transform: none !important;
            }
        }

        .block-content > .box {
            .box-title {
                font-size: 18px !important;
            }
        }
    }

    .order-details-items.ordered {
        .order-items {
            .col.subtotal,
            .col.price {
                .price-including-tax, .price-excluding-tax {
                    font-size: 16px;
                }
            }
        }
    }
}

body.account #maincontent .column.main .actions-toolbar .action.primary {
    display: inline-block;
    padding: 12px 20px;
    font-size: 13px;
    border: 2x solid $c_primary;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.24s ease, color 0.24s ease, border-color 0.24s ease;
    background-color: transparent;
    color: $c_primary;

    &:focus,
    &:hover {
        text-decoration: none;
        background-color: darken($c_primary, 10%);
        color: $c_white;
        border-color: darken($c_primary, 10%);
        border-width: 2px;
    }

    &:visited {
        background-color: transparent;
        border-color: $c_primary;
        color: $c_primary;
    }
}
