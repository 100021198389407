
// Layout
.checkout-index-index {
    padding-top: 0;
    background-color: $c_bg-grey;
    color: $c_text-dark;

    .page-main {
        max-width: none;
        padding: 0 30px 0 !important;
    }
}

.checkout-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: $checkout-container;
    margin: 0 auto;

    .messages {
        display: block !important;
        width: calc(66% - 120px);
    }

    .loading-mask {
        background-color: rgba($c_white, 0.5);
    }
}

.opc-estimated-wrapper {
    width: 100%;
}

.opc-wrapper {
    width: calc(66% - 120px);
    padding-right: 0;
    float: none;

    .opc > li {
        background-color: $c_white;
        border: 1px solid $c_border-grey;
        border-radius: 4px;
        padding: 40px;
        margin-bottom: 40px;

        &.checkout-shipping-method {
            margin-top: -44px;
            border-top: 0;
        }

        &.checkout-shipping-address {
            margin-bottom: 0;
        }
    }

    .step-content {
        margin-bottom: 0;
    }
}

.opc-sidebar {
    width: 100%;
    flex-basis: 33%;
    float: none;
}

// Header
.checkout-header {
    padding: 13px;
    margin-bottom: 50px;
    background-color: $c_white;

    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: $checkout-container;
        margin: 0 auto;
    }

    .logo {
        margin: 0;
    }

    .secure {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: $c_text-medium;

        svg {
            margin-right: 15px;
        }
    }
}

// Footer
.checkout-footer {
    padding: 0 30px;

    &-container {
        max-width: $checkout-container;
        margin: 0 auto;
    }

    ul {
        display: flex;
        justify-content: space-around;
        padding: 13px 0;
        margin-bottom: 0;
        list-style-type: none;
        color: $c_text-light;
        border-top: 1px solid $c_border-grey;

        a {
            color: $c_text-light;

            &:hover {
                color: $c_text-medium;
            }
        }
    }
}

@media screen and (max-width: $screen__l) {

    .opc-wrapper {
        width: calc(66% - 60px);
    }

    .checkout-container {

        .messages {
            width: calc(66% - 60px);
        }
    }
}

@media screen and (max-width: $screen__m) {

    .checkout-header {
        margin-bottom: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .opc-wrapper .opc > li {
        padding: 30px 20px 40px 20px;
    }

    .opc-estimated-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        padding: 0;
        border-bottom: 0;

        &:before,
        &:after {
            content: none;
        }

        .estimated-block {
            float: none;
        }

        .estimated-label {
            margin-bottom: 0;
            font-size: 1.4rem;
            font-weight: normal;
            color: $c_text-light;

            &:after {
                content: url('../images/icons/caret.svg');
                margin-left: 5px;
            }
        }

        .minicart-wrapper {
            float: none;

            .action.showcart {
                display: flex;
                align-items: center;
                background-color: $c_white;
                border: 1px solid $c_border-grey;

                &:before {
                    color: $c_primary;
                    margin-right: 10px;
                }
            }
        }
    }
}